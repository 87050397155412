import {listLastPaymentsForAgent} from "../graphql/queries";
import {
    fetchItemsNextTokenGraphQL,
} from "@/utils/query_all_utils";
import {PAYMENT_RELEASE_DATE} from "@/constants"

const moment = require('moment-timezone')

export default {
    state: {
        last_payments: [],
        last_debts: [],
        payroll_date: moment().add(-2, 'days').format('YYYY-MM-DD')
    },
    mutations: {
        SET_PAYROLL_DATE: (state, payroll_date) => state.payroll_date = payroll_date,
        SET_LAST_PAYMENTS_FOR_AGENT: (state, items) => state.last_payments = Object.freeze(items),
        SET_LAST_DEBT_FOR_AGENT: (state, items) => state.last_debts = Object.freeze(items),
    },
    actions: {
        setPayrollDate: ({commit, dispatch, getters}, payroll_date) => {
            if (getters.getPayrollDate !== payroll_date) {
            commit('SET_PAYROLL_DATE', payroll_date)
                dispatch('initializeProductivityForPayrolls')
            }

        },
        setLastPaymentsForAgent: ({commit}, items) => {
            commit('SET_LAST_PAYMENTS_FOR_AGENT', items)
        },
        setLastDebtForAgent: ({commit}, items) => {
            commit('SET_LAST_DEBT_FOR_AGENT', items)
        },
        initializeLastPaymentsForAgent: ({dispatch, getters}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listLastPaymentsForAgent).then((last_payments) => {
                    var agents_reduced = getters.getAllActiveAgents.reduce((accum, agent) => {
                        var last_payment = last_payments.find((payment) => agent.email === payment.agent)
                        accum[agent.email.toLowerCase()] = last_payment ? last_payment.payment_date : PAYMENT_RELEASE_DATE
                        return accum
                    }, {})
                    var agents_reduced_debt = getters.getAllActiveAgents.reduce((accum, agent) => {
                        var last_payment = last_payments.find((payment) => agent.email === payment.agent)
                        accum[agent.email.toLowerCase()] = last_payment ? last_payment.remaining_debt : 0
                        return accum
                    }, {})

                    dispatch('setLastPaymentsForAgent', agents_reduced)
                    dispatch('setLastDebtForAgent', agents_reduced_debt)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
    },
    getters: {
        getPayrollDate: state => state.payroll_date,
        getLastPayments: state => state.last_payments,
        getLastDebt: state => state.last_debts,
        getLastPaymentsEarliest: state =>  {
            return Object.values(state.last_payments).reduce((accum, item) => {
                if (accum > moment(item)){
                    accum = moment(item)
                }
                return accum
            }, moment('3000-01-01'))
        }
    },
};
