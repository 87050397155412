/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPersonsSale = /* GraphQL */ `
  mutation CreatePersonsSale($createPersonsSaleInput: CreatePersonsSaleInput!) {
    createPersonsSale(createPersonsSaleInput: $createPersonsSaleInput)
  }
`;
export const updatePersonsSale = /* GraphQL */ `
  mutation UpdatePersonsSale($updatePersonsSaleInput: UpdatePersonsSaleInput!) {
    updatePersonsSale(updatePersonsSaleInput: $updatePersonsSaleInput)
  }
`;
export const updatePersonsSaleAPI = /* GraphQL */ `
  mutation UpdatePersonsSaleAPI($updatePersonsSaleAPIInput: UpdatePersonsSaleAPIInput!) {
    updatePersonsSaleAPI(updatePersonsSaleAPIInput: $updatePersonsSaleAPIInput)
  }
`;
export const updateNumberDisplayTime = /* GraphQL */ `
  mutation UpdateNumberDisplayTime($updatePersonsSaleNumberDisplayTimeInput: UpdatePersonsSaleNumberDisplayTimeInput!) {
    updateNumberDisplayTime(updatePersonsSaleNumberDisplayTimeInput: $updatePersonsSaleNumberDisplayTimeInput)
  }
`;
export const createKeysToCustomer = /* GraphQL */ `
  mutation CreateKeysToCustomer($createKeysToCustomerInput: CreateKeysToCustomerInput!) {
    createKeysToCustomer(createKeysToCustomerInput: $createKeysToCustomerInput)
  }
`;
export const deleteKeysToCustomer = /* GraphQL */ `
  mutation DeleteKeysToCustomer($KeyField: String!) {
    deleteKeysToCustomer(KeyField: $KeyField) {
      KeyField
    }
  }
`;
export const updateKeysToCustomer = /* GraphQL */ `
  mutation UpdateKeysToCustomer($updateKeysToCustomerInput: UpdateKeysToCustomerInput!) {
    updateKeysToCustomer(updateKeysToCustomerInput: $updateKeysToCustomerInput)
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent($id: String!) {
    deleteAgent(id: $id) {
      id
      email
      firstName
      secondName
      agent_group
      payrate
      is_rate_hourly
      tier
      type
      vacation
      archived
      CRMID
      Alias
      agent_type
      createdAt
      updatedAt
      CommissionPercentage
      AccessiableOutgoingCustomers
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent($createAgentInput: CreateAgentInput!) {
    createAgent(createAgentInput: $createAgentInput) {
      id
      email
      firstName
      secondName
      agent_group
      payrate
      is_rate_hourly
      tier
      type
      vacation
      archived
      CRMID
      Alias
      agent_type
      createdAt
      updatedAt
      CommissionPercentage
      AccessiableOutgoingCustomers
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent($updateAgentInput: UpdateAgentInput!) {
    updateAgent(updateAgentInput: $updateAgentInput) {
      id
      email
      firstName
      secondName
      agent_group
      payrate
      is_rate_hourly
      tier
      type
      vacation
      archived
      CRMID
      Alias
      agent_type
      createdAt
      updatedAt
      CommissionPercentage
      AccessiableOutgoingCustomers
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($id: String!) {
    deletePayment(id: $id) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($createPaymentInput: CreatePaymentInput!) {
    createPayment(createPaymentInput: $createPaymentInput) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($updatePaymentInput: UpdatePaymentInput!) {
    updatePayment(updatePaymentInput: $updatePaymentInput) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommission = /* GraphQL */ `
  mutation DeleteCommission($id: String!) {
    deleteCommission(id: $id) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const createCommission = /* GraphQL */ `
  mutation CreateCommission($createCommissionInput: CreateCommissionInput!) {
    createCommission(createCommissionInput: $createCommissionInput) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const updateCommission = /* GraphQL */ `
  mutation UpdateCommission($updateCommissionInput: UpdateCommissionInput!) {
    updateCommission(updateCommissionInput: $updateCommissionInput) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientCRM = /* GraphQL */ `
  mutation DeleteClientCRM($id: String!, $name: String!) {
    deleteClientCRM(id: $id, name: $name)
  }
`;
export const createClientCRM = /* GraphQL */ `
  mutation CreateClientCRM($createClientCRMInput: CreateClientCRMInput!) {
    createClientCRM(createClientCRMInput: $createClientCRMInput) {
      id
      name
      login
      filter
      purchases_filter
      password
      createdAt
      updatedAt
    }
  }
`;
export const updateClientCRM = /* GraphQL */ `
  mutation UpdateClientCRM($updateClientCRMInput: UpdateClientCRMInput!) {
    updateClientCRM(updateClientCRMInput: $updateClientCRMInput) {
      id
      name
      login
      filter
      purchases_filter
      password
      createdAt
      updatedAt
    }
  }
`;
export const deleteHoliday = /* GraphQL */ `
  mutation DeleteHoliday($id: String!) {
    deleteHoliday(id: $id) {
      id
      name
      Holiday_date
      createdAt
      updatedAt
    }
  }
`;
export const createHoliday = /* GraphQL */ `
  mutation CreateHoliday($createHolidayInput: CreateHolidayInput!) {
    createHoliday(createHolidayInput: $createHolidayInput) {
      id
      name
      Holiday_date
      createdAt
      updatedAt
    }
  }
`;
export const updateHoliday = /* GraphQL */ `
  mutation UpdateHoliday($updateHolidayInput: UpdateHolidayInput!) {
    updateHoliday(updateHolidayInput: $updateHolidayInput) {
      id
      name
      Holiday_date
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentDashboardRecord = /* GraphQL */ `
  mutation DeleteAgentDashboardRecord($id: String!) {
    deleteAgentDashboardRecord(id: $id) {
      id
      dp_index
      agents
      createdAt
      updatedAt
    }
  }
`;
export const createAgentDashboardRecord = /* GraphQL */ `
  mutation CreateAgentDashboardRecord(
    $createAgentDashboardRecordInput: CreateAgentDashboardRecordInput!
  ) {
    createAgentDashboardRecord(
      createAgentDashboardRecordInput: $createAgentDashboardRecordInput
    ) {
      id
      dp_index
      agents
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentDashboardRecord = /* GraphQL */ `
  mutation UpdateAgentDashboardRecord(
    $updateAgentDashboardRecordInput: UpdateAgentDashboardRecordInput!
  ) {
    updateAgentDashboardRecord(
      updateAgentDashboardRecordInput: $updateAgentDashboardRecordInput
    ) {
      id
      dp_index
      agents
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentRefundDashboardRecord = /* GraphQL */ `
  mutation DeleteAgentRefundDashboardRecord($id: String!) {
    deleteAgentRefundDashboardRecord(id: $id) {
      id
      dp_index
      agent_email
      calls_taken
      refund_count
      refund_dollars
      cancel_count
      sales
      global_sales
      returns
      chargeback
      other
      sales_count
      subscription_sales
      subscription_count
      prebill_cancels
      postbill_cancels
      createdAt
      updatedAt
    }
  }
`;
export const createAgentRefundDashboardRecord = /* GraphQL */ `
  mutation CreateAgentRefundDashboardRecord(
    $createAgentRefundDashboardRecordInput: CreateAgentRefundDashboardRecordInput!
  ) {
    createAgentRefundDashboardRecord(
      createAgentRefundDashboardRecordInput: $createAgentRefundDashboardRecordInput
    ) {
      id
      dp_index
      agent_email
      calls_taken
      refund_count
      refund_dollars
      cancel_count
      sales
      global_sales
      returns
      chargeback
      other
      sales_count
      subscription_sales
      subscription_count
      prebill_cancels
      postbill_cancels
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentRefundDashboardRecord = /* GraphQL */ `
  mutation UpdateAgentRefundDashboardRecord(
    $updateAgentRefundDashboardRecordInput: UpdateAgentRefundDashboardRecordInput!
  ) {
    updateAgentRefundDashboardRecord(
      updateAgentRefundDashboardRecordInput: $updateAgentRefundDashboardRecordInput
    ) {
      id
      dp_index
      agent_email
      calls_taken
      refund_count
      refund_dollars
      cancel_count
      sales
      global_sales
      returns
      chargeback
      other
      sales_count
      subscription_sales
      subscription_count
      prebill_cancels
      postbill_cancels
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentRefundRecord = /* GraphQL */ `
  mutation DeleteAgentRefundRecord($id: String!) {
    deleteAgentRefundRecord(id: $id) {
      id
      dp_index
      client_name
      grossrev
      refunds
      rebill_cancels
      initial_cancels
      agent_refunds
      createdAt
      updatedAt
    }
  }
`;
export const createAgentRefundRecord = /* GraphQL */ `
  mutation CreateAgentRefundRecord(
    $createAgentRefundRecordInput: CreateAgentRefundRecordInput!
  ) {
    createAgentRefundRecord(
      createAgentRefundRecordInput: $createAgentRefundRecordInput
    ) {
      id
      dp_index
      client_name
      grossrev
      refunds
      rebill_cancels
      initial_cancels
      agent_refunds
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentRefundRecord = /* GraphQL */ `
  mutation UpdateAgentRefundRecord(
    $updateAgentRefundRecordInput: UpdateAgentRefundRecordInput!
  ) {
    updateAgentRefundRecord(
      updateAgentRefundRecordInput: $updateAgentRefundRecordInput
    ) {
      id
      dp_index
      client_name
      grossrev
      refunds
      rebill_cancels
      initial_cancels
      agent_refunds
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentTimeRecord = /* GraphQL */ `
  mutation DeleteAgentTimeRecord($id: String!) {
    deleteAgentTimeRecord(id: $id) {
      id
      AGENT_EMAIL
      AGENT_STATE_TIME
      PROCESSED_STATUS
      STATE
      REASON_CODE
      TIMESTAMP
      TIMESTAMP_REAL
      dp_index
      createdAt
      updatedAt
    }
  }
`;
export const createAgentTimeRecord = /* GraphQL */ `
  mutation CreateAgentTimeRecord(
    $createAgentTimeRecordInput: CreateAgentTimeRecordInput!
  ) {
    createAgentTimeRecord(
      createAgentTimeRecordInput: $createAgentTimeRecordInput
    ) {
      id
      AGENT_EMAIL
      AGENT_STATE_TIME
      PROCESSED_STATUS
      STATE
      REASON_CODE
      TIMESTAMP
      TIMESTAMP_REAL
      dp_index
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentTimeRecord = /* GraphQL */ `
  mutation UpdateAgentTimeRecord(
    $updateAgentTimeRecordInput: UpdateAgentTimeRecordInput!
  ) {
    updateAgentTimeRecord(
      updateAgentTimeRecordInput: $updateAgentTimeRecordInput
    ) {
      id
      AGENT_EMAIL
      AGENT_STATE_TIME
      PROCESSED_STATUS
      STATE
      REASON_CODE
      TIMESTAMP
      TIMESTAMP_REAL
      dp_index
      createdAt
      updatedAt
    }
  }
`;
export const deleteCallRecord = /* GraphQL */ `
  mutation DeleteCallRecord($id: String!) {
    deleteCallRecord(id: $id) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const createCallRecord = /* GraphQL */ `
  mutation CreateCallRecord($createCallRecordInput: CreateCallRecordInput!) {
    createCallRecord(createCallRecordInput: $createCallRecordInput) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const updateCallRecord = /* GraphQL */ `
  mutation UpdateCallRecord($updateCallRecordInput: UpdateCallRecordInput!) {
    updateCallRecord(updateCallRecordInput: $updateCallRecordInput) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id) {
      id
      name
      display_name
      tier
      client_name
      is_rate_hourly
      rate
      distribution
      capped_to_5_min
      archived
      CRMFilter
      createdAt
      updatedAt
      join_campaign
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($createCampaignInput: CreateCampaignInput!) {
    createCampaign(createCampaignInput: $createCampaignInput) {
      id
      name
      display_name
      tier
      client_name
      is_rate_hourly
      rate
      distribution
      capped_to_5_min
      archived
      CRMFilter
      createdAt
      updatedAt
      join_campaign
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($updateCampaignInput: UpdateCampaignInput!) {
    updateCampaign(updateCampaignInput: $updateCampaignInput) {
      id
      name
      display_name
      tier
      client_name
      is_rate_hourly
      rate
      distribution
      capped_to_5_min
      archived
      CRMFilter
      createdAt
      updatedAt
      join_campaign
    }
  }
`;
export const deleteCampaignList = /* GraphQL */ `
  mutation DeleteCampaignList($id: String!) {
    deleteCampaignList(id: $id) {
      id
      name
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignList = /* GraphQL */ `
  mutation CreateCampaignList(
    $createCampaignListInput: CreateCampaignListInput!
  ) {
    createCampaignList(createCampaignListInput: $createCampaignListInput) {
      id
      name
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignList = /* GraphQL */ `
  mutation UpdateCampaignList(
    $updateCampaignListInput: UpdateCampaignListInput!
  ) {
    updateCampaignList(updateCampaignListInput: $updateCampaignListInput) {
      id
      name
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardRecord = /* GraphQL */ `
  mutation DeleteDashboardRecord($id: String!) {
    deleteDashboardRecord(id: $id) {
      id
      dp_index
      dp_type
      campaign
      DISPOSITION
      ABANDONED
      COUNT
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      QUEUE_WAIT_TIME_5_MIN
      QUEUE_WAIT_TIME_5_MIN_1
      QUEUE_WAIT_TIME_5_MIN_2
      QUEUE_WAIT_TIME_5_MIN_3
      QUEUE_WAIT_TIME_5_MIN_4
      agent
      agent_time
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardRecord = /* GraphQL */ `
  mutation CreateDashboardRecord(
    $createDashboardRecordInput: CreateDashboardRecordInput!
  ) {
    createDashboardRecord(
      createDashboardRecordInput: $createDashboardRecordInput
    ) {
      id
      dp_index
      dp_type
      campaign
      DISPOSITION
      ABANDONED
      COUNT
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      QUEUE_WAIT_TIME_5_MIN
      QUEUE_WAIT_TIME_5_MIN_1
      QUEUE_WAIT_TIME_5_MIN_2
      QUEUE_WAIT_TIME_5_MIN_3
      QUEUE_WAIT_TIME_5_MIN_4
      agent
      agent_time
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardRecord = /* GraphQL */ `
  mutation UpdateDashboardRecord(
    $updateDashboardRecordInput: UpdateDashboardRecordInput!
  ) {
    updateDashboardRecord(
      updateDashboardRecordInput: $updateDashboardRecordInput
    ) {
      id
      dp_index
      dp_type
      campaign
      DISPOSITION
      ABANDONED
      COUNT
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      QUEUE_WAIT_TIME_5_MIN
      QUEUE_WAIT_TIME_5_MIN_1
      QUEUE_WAIT_TIME_5_MIN_2
      QUEUE_WAIT_TIME_5_MIN_3
      QUEUE_WAIT_TIME_5_MIN_4
      agent
      agent_time
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisposition = /* GraphQL */ `
  mutation DeleteDisposition($id: String!) {
    deleteDisposition(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createDisposition = /* GraphQL */ `
  mutation CreateDisposition($createDispositionInput: CreateDispositionInput!) {
    createDisposition(createDispositionInput: $createDispositionInput) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateDisposition = /* GraphQL */ `
  mutation UpdateDisposition($updateDispositionInput: UpdateDispositionInput!) {
    updateDisposition(updateDispositionInput: $updateDispositionInput) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteDispositionBucket = /* GraphQL */ `
  mutation DeleteDispositionBucket($id: String!) {
    deleteDispositionBucket(id: $id) {
      id
      name
      dispositions
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const createDispositionBucket = /* GraphQL */ `
  mutation CreateDispositionBucket(
    $createDispositionBucketInput: CreateDispositionBucketInput!
  ) {
    createDispositionBucket(
      createDispositionBucketInput: $createDispositionBucketInput
    ) {
      id
      name
      dispositions
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const updateDispositionBucket = /* GraphQL */ `
  mutation UpdateDispositionBucket(
    $updateDispositionBucketInput: UpdateDispositionBucketInput!
  ) {
    updateDispositionBucket(
      updateDispositionBucketInput: $updateDispositionBucketInput
    ) {
      id
      name
      dispositions
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const deleteOutgoingCallsToMake = /* GraphQL */ `
  mutation DeleteOutgoingCallsToMake($id: String!) {
    deleteOutgoingCallsToMake(id: $id) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const createOutgoingCallsToMake = /* GraphQL */ `
  mutation CreateOutgoingCallsToMake(
    $createOutgoingCallsToMakeInput: CreateOutgoingCallsToMakeInput!
  ) {
    createOutgoingCallsToMake(
      createOutgoingCallsToMakeInput: $createOutgoingCallsToMakeInput
    ) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const updateOutgoingCallsToMake = /* GraphQL */ `
  mutation UpdateOutgoingCallsToMake(
    $updateOutgoingCallsToMakeInput: UpdateOutgoingCallsToMakeInput!
  ) {
    updateOutgoingCallsToMake(
      updateOutgoingCallsToMakeInput: $updateOutgoingCallsToMakeInput
    ) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const deleteAmUser = /* GraphQL */ `
  mutation DeleteAmUser($id: String!) {
    deleteAmUser(id: $id) {
      id
      cognito_id
      campaigns
      timezone
      agents
      display_from
      show_name
      createdAt
      updatedAt
    }
  }
`;
export const createAmUser = /* GraphQL */ `
  mutation CreateAmUser($createAmUserInput: CreateAmUserInput!) {
    createAmUser(createAmUserInput: $createAmUserInput) {
      id
      cognito_id
      campaigns
      timezone
      agents
      display_from
      show_name
      createdAt
      updatedAt
    }
  }
`;
export const updateAmUser = /* GraphQL */ `
  mutation UpdateAmUser($updateAmUserInput: UpdateAmUserInput!) {
    updateAmUser(updateAmUserInput: $updateAmUserInput) {
      id
      cognito_id
      campaigns
      timezone
      agents
      display_from
      show_name
      createdAt
      updatedAt
    }
  }
`;
export const updateCallsToProcess = /* GraphQL */ `
  mutation UpdateCallsToProcess($campaign_name: String, $from: Int) {
    updateCallsToProcess(campaign_name: $campaign_name, from: $from)
  }
`;
export const updateCallsToProcessFor0 = /* GraphQL */ `
  mutation UpdateCallsToProcessFor0($campaign_name: String, $from: Int) {
    updateCallsToProcessFor0(campaign_name: $campaign_name, from: $from)
  }
`;
