export function hideAllExcept(seriesName) {
    const w = this.w
    w.globals.resized = true // we don't want initial animations again

    // yes, make it null. 1 series will rise at a time
    w.globals.risingSeries = []


    let series = JSON.parse(JSON.stringify(w.config.series))
    let shouldReturn = true;
    if (w.globals.collapsedSeries.length > 0) {
        for (let c = 0; c < w.globals.collapsedSeries.length; c++) {
            let index = w.globals.collapsedSeries[c].index
            series[index].data = w.globals.collapsedSeries[c].data.slice()
            if (series[index].name == seriesName)
            {
                shouldReturn =  false;
            }
        }
        w.globals.collapsedSeries = []
        w.globals.collapsedSeriesIndices = []
        this.lgCtx.ctx.updateHelpers._updateSeries(
            series,
            false
        )
        if (shouldReturn) {
            return
        }
    }


    this.riseCollapsedSeries(w.globals.collapsedSeries, w.globals.collapsedSeriesIndices, 0)
    for (let i = 0; i < series.length; i++) {
        if (series[i].name != seriesName) {
            w.globals.collapsedSeries.push({
                index: i,
                data: series[i].data.slice(),
                type: 'bar'
            })
            w.globals.collapsedSeriesIndices.push(i)
        }
    }

    w.globals.allSeriesCollapsed = false;

    series = this._getSeriesBasedOnCollapsedState(series)
    this.lgCtx.ctx.updateHelpers._updateSeries(
        series,
        false
    )
}