<template>
    <div :class="['fill-height','loader-background', nontransparent? 'no-opacity' :'']">
        <div class="cube">
            <div class="sides">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
                <div class="front"></div>
                <div class="back"></div>
            </div>
        </div>
        <div class="text">BUNDLING DASHBOARDS</div>
    </div>

</template>

<script>
    export default {
        name: "preloader",
        props: {
            nontransparent:Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .loader-background {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 0 auto;
        width: 100vw;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        opacity: 0.3;
        $size: 150px;
        background-color: black;
        &.no-opacity
        {
            opacity: 1;
            background-color: white;
        }


        @keyframes rotate {
            0% {
                transform: rotateX(-37.5deg) rotateY(45deg);
            }
            50% {
                transform: rotateX(-37.5deg) rotateY(405deg);
            }
            100% {
                transform: rotateX(-37.5deg) rotateY(405deg);
            }
        }

        .cube, .cube * {
            position: absolute;
            width: $size + 1;
            height: $size + 1;
        }

        .sides {
            animation: rotate 3s ease infinite;
            animation-delay: .8s;
            transform-style: preserve-3d;
            transform: rotateX(-37.5deg) rotateY(45deg);
        }

        .cube .sides * {
            box-sizing: border-box;
            background-color: rgba(57,139,247, .5);
            border: $size / 12 solid white;
        }

        @mixin side($name, $rotate, $i) {
            .#{$name} {
                animation: #{$name}-animation 3s ease infinite;
                animation-delay: $i * 100ms;
                transform: #{$rotate} translateZ($size);
                animation-fill-mode: forwards;
                transform-origin: 50% 50%;
            }


            @keyframes #{$name}-animation {
                0% {
                    opacity: 1;
                    transform: #{$rotate} translateZ($size)
                }
                20% {
                    opacity: 1;
                    transform: #{$rotate} translateZ($size / 2)
                }
                70% {
                    opacity: 1;
                    transform: #{$rotate} translateZ($size / 2)
                }
                90% {
                    opacity: 1;
                    transform: #{$rotate} translateZ($size)
                }
                100% {
                    opacity: 1;
                    transform: #{$rotate} translateZ($size)
                }
            }
        }

        .cube .sides {
            @include side("top", rotateX(90deg), 0);
            @include side("bottom", rotateX(-90deg), 0);
            @include side("front", rotateY(0deg), 1);
            @include side("back", rotateY(-180deg), 1);
            @include side("left", rotateY(-90deg), 1);
            @include side("right", rotateY(90deg), 1);
        }

        .text {
            margin-top: $size * 3;
            color: rgba(57,139,247, 1);
            font-size: 1.5rem;
            width: 100%;
            font-weight: 600;
            text-align: center;
        }
    }

</style>

