// import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";
// import {API, graphqlOperation} from "aws-amplify";

import {
    listCancelRefundsRecords,
    listPurchasesRecords,
    listTransactionsRecords,
    listNotesRecords,
    listDashboardRecordsForReports
} from "../graphql/queries";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";
import {toDPIndex} from "../utils/query_all_utils";
import {CONST_OFFSET_FOR_KONNEKTIVE} from "../constants"

const moment = require('moment-timezone')

moment.tz.setDefault('America/Vancouver')

const alertAgentNames = ['CBHAPINutra', 'Xprotect_API_GreenApe', 'CB911_agent_01', 'CB911_agent_02', 'CB911_agent_03', 'CB911_agent_04', 'CB911_api2020', 'kzogob@xcaliber-solutions.com', 'apikk10087', 'kountapifastforwardmedia']
const emailAgentNames = ['LC-359','LC-355','LC328','LC-353','LC-358']

export default {
    state: {
        crm_records_first: [],
        crm_records_second: [],
        crm_purchase_records_first: [],
        crm_purchase_records_second: [],
        crm_transactions_records_first: [],
        crm_transactions_records_second: [],
        crm_notes_records_first: [],
        crm_notes_records_second: [],
        crm_agent_dashboard_records: [],
        crm_data_loaded: 0,
        crm_dashboard_records_loading: 0,
        excludeVoids: false,
    },
    mutations: {
        SET_CRM_RECORDS_DATA_FIRST: (state, crm_records) => state.crm_records_first = crm_records,
        SET_CRM_RECORDS_DATA_SECOND: (state, crm_records) => state.crm_records_second = crm_records,
        SET_CRM_PURCHASES_RECORDS_DATA_FIRST: (state, crm_records) => state.crm_purchase_records_first = crm_records,
        SET_CRM_PURCHASES_RECORDS_DATA_SECOND: (state, crm_records) => state.crm_purchase_records_second = crm_records,
        SET_CRM_TRANSACTIONS_RECORDS_DATA_FIRST: (state, crm_records) => state.crm_transactions_records_first = crm_records,
        SET_CRM_TRANSACTIONS_RECORDS_DATA_SECOND: (state, crm_records) => state.crm_transactions_records_second = crm_records,
        SET_CRM_NOTES_RECORDS_DATA_FIRST: (state, crm_records) => state.crm_notes_records_first = crm_records,
        SET_CRM_NOTES_RECORDS_DATA_SECOND: (state, crm_records) => state.crm_notes_records_second = crm_records,
        SET_CRM_DASHBOARD_RECORDS: (state, records) => state.crm_agent_dashboard_records = records,
        SET_CRM_DATA_LOADED: (state) => state.crm_data_loaded -= 1,
        RESET_CRM_DATA_LOADED: (state) => state.crm_data_loaded += 1,
        SET_CRM_DASHBOARD_RECORDS_LOADED: (state) => state.crm_agent_dashboard_records_loading -= 1,
        RESET_CRM_DASHBOARD_RECORDS_LOADED: (state) => state.crm_agent_dashboard_records_loading += 1,
        SET_EXCLUDE_VOIDS: (state, value) => state.excludeVoids = value
    },
    actions: {
        setExcludeVoids: ({commit}, value) => {
            commit('SET_EXCLUDE_VOIDS', value)
        },
        loadRefundsCancelsFirst: ({commit, getters}) => {
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            commit("SET_CRM_DASHBOARD_RECORDS_LOADED")
            return Promise.all([
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listCancelRefundsRecords, {
                        start: moment.utc(getters.getDashboardMainDates[0]).startOf('day').unix(),
                        end: moment.utc(getters.getDashboardMainDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_RECORDS_DATA_FIRST", items.map((item) => {
                                if (item.currencySymbol != '$' && item.currencySymbol != 'CA$') {
                                    item.currencySymbol = '$'
                                }
                                return item
                            }))
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })

                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listPurchasesRecords, {
                        start: moment.utc(getters.getDashboardMainDates[0]).startOf('day').format("YYYY-MM-DD"),
                        end: moment.utc(getters.getDashboardMainDates[1]).startOf('day').format("YYYY-MM-DD")
                    }, 2000).then((items) => {
                            commit("SET_CRM_PURCHASES_RECORDS_DATA_FIRST", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listTransactionsRecords, {
                        start: moment.utc(getters.getDashboardMainDates[0]).startOf('day').unix(),
                        end: moment.utc(getters.getDashboardMainDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_TRANSACTIONS_RECORDS_DATA_FIRST", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listNotesRecords, {
                        start: moment.utc(getters.getDashboardMainDates[0]).startOf('day').unix(),
                        end: moment.utc(getters.getDashboardMainDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_NOTES_RECORDS_DATA_FIRST", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listDashboardRecordsForReports, {
                        dp_start: Math.floor(toDPIndex(moment(getters.getDashboardMainDates[0]))) + CONST_OFFSET_FOR_KONNEKTIVE,
                        dp_end: Math.ceil(toDPIndex(moment(getters.getDashboardMainDates[1]).endOf('day'))) + CONST_OFFSET_FOR_KONNEKTIVE
                    }, 2000).then((items) => {
                            commit("SET_CRM_DASHBOARD_RECORDS", items)
                            commit("RESET_CRM_DASHBOARD_RECORDS_LOADED")
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                    })
                }),
            ])
        },
        loadRefundsCancelsSecond: ({commit, getters}) => {
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            commit("RESET_CRM_DATA_LOADED")
            return Promise.all([
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listCancelRefundsRecords, {
                        start: moment.utc(getters.getCompareDashboardDates[0]).unix(),
                        end: moment.utc(getters.getCompareDashboardDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_RECORDS_DATA_SECOND", items.map((item) => {
                                if (item.currencySymbol != '$' && item.currencySymbol != 'CA$') {
                                    item.currencySymbol = '$'
                                }
                                return item
                            }))
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listPurchasesRecords, {
                        start: moment.utc(getters.getCompareDashboardDates[0]).startOf('day').format("YYYY-MM-DD"),
                        end: moment.utc(getters.getCompareDashboardDates[1]).startOf('day').format("YYYY-MM-DD")
                    }, 2000).then((items) => {
                            commit("SET_CRM_PURCHASES_RECORDS_DATA_SECOND", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listTransactionsRecords, {
                        start: moment.utc(getters.getCompareDashboardDates[0]).unix(),
                        end: moment.utc(getters.getCompareDashboardDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_TRANSACTIONS_RECORDS_DATA_SECOND", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
                new Promise((resolve, reject) => {
                    fetchItemsNextTokenGraphQL(listNotesRecords, {
                        start: moment.utc(getters.getCompareDashboardDates[0]).unix(),
                        end: moment.utc(getters.getCompareDashboardDates[1]).endOf('day').unix()
                    }, 2000).then((items) => {
                            commit("SET_CRM_NOTES_RECORDS_DATA_SECOND", items)
                            commit('SET_CRM_DATA_LOADED')
                            resolve()
                        }
                    ).catch(() => {
                        reject()
                        commit('SET_CRM_DATA_LOADED')
                    })
                }),
            ])
        },
    },
    getters: {
        getCRMAgentDashboardRecords: (state) => state.crm_agent_dashboard_records,
        getRefundsByAgentType: (state, getters) => {
            const crmCampaignFilter = getters.crmCampaignFilter

            const supportedAgents = getters.getAllAgents.reduce((accum, item) => {
                accum[item.Alias] = item.agent_type
                return accum
            }, {})
            let data = state.crm_transactions_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true).filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientFilter(item))

            if (getters.getExcludeVoids == true) {
                data = data.map((item) => {
                    if (item.txnType == 'VOID' && item.billingCycleNumber >= 2) {
                        return {...item, totalAmount: 0}
                    }
                    return item
                })
            }
            let validCustomerIds = data.reduce((accum, item) => {
                accum[item.customerId] = 1
                return accum
            }, {})
            let source_notes_refunds = state.crm_notes_records_first.filter((item) => validCustomerIds[item.customerId] ? true : false).filter((item) => item.categoryType === 'REFUNDED').reduce((accum, item) => {
                accum[item.customerId] = item.agentName
                return accum
            }, {})


            return data.map((item) => {
                item.agentName = source_notes_refunds[item.customerId]
                return item
            }).reduce((accum, item) => {
                let type = item.agentName ? (supportedAgents[item.agentName]) : 'Other'
                if (type === undefined) {
                    if (item.agentName.startsWith('LC-E') || item.agentName.startsWith('LC-GL') || emailAgentNames.includes(item.agentName) ) {
                        type = 'Email Refunds'
                    } else if (item.agentName.startsWith('LC-R') || item.agentName.startsWith('Va_') || item.agentName === 'Support123') {
                        type = 'Return Refunds'
                    } else if (item.agentName.startsWith('LC-')) {
                        type = 'Agent Call Refunds'
                    } else if (alertAgentNames.includes(item.agentName) || item.agentName.includes('Xprotect')) {
                        type = 'Alerts'
                    } else {
                        type = 'Other'
                    }
                }
                accum[type].count += 1
                accum[type].$ += item['totalAmount'] / 100
                return accum
            }, {
                "Agent Call Refunds": {"$": 0, "count": 0},
                "Email Refunds": {"$": 0, "count": 0},
                "Return Refunds": {"$": 0, "count": 0},
                "Alerts": {"$": 0, "count": 0},
                "Other": {"$": 0, "count": 0}
            })
        },
        getCancelsByAgentType: (state, getters) => {
            const crmCampaignFilter = getters.crmCampaignFilter

            const supportedAgents = getters.getAllAgents.reduce((accum, item) => {
                accum[item.Alias] = item.agent_type
                return accum
            }, {})
            const data = state.crm_purchase_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true).filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientPurchaseFilter(item))

            let validCustomerIds = data.reduce((accum, item) => {
                accum[item.customerId] = 1
                return accum
            }, {})

            let source_notes_cancels = state.crm_notes_records_first.filter((item) => validCustomerIds[item.customerId] ? true : false).filter((item) => item.categoryType === 'REBILL_CANCELLED').reduce((accum, item) => {
                accum[item.customerId] = item.agentName
                return accum
            }, {})
            return data.map((item) => {
                item.agentName = source_notes_cancels[item.customerId]
                return item
            }).reduce((accum, item) => {

                let type = item.agentName ? (supportedAgents[item.agentName]) : 'Other'
                if (type === undefined) {
                    if (item.agentName.startsWith('LC-E') || item.agentName.startsWith('LC-GL') || emailAgentNames.includes(item.agentName)) {
                        type = 'Email Refunds'
                    } else if (item.agentName.startsWith('LC-R') || item.agentName.startsWith('Va_')) {
                        type = 'Return Refunds'
                    } else if (item.agentName.startsWith('LC-')) {
                        type = 'Agent Call Refunds'
                    } else if (alertAgentNames.includes(item.agentName)) {
                        type = 'Alerts'
                    } else {
                        type = 'Other'
                    }
                }
                accum[type].count += 1
                accum[type].$ += item['price'] / 100
                return accum
            }, {
                "Agent Call Refunds": {"$": 0, "count": 0},
                "Email Refunds": {"$": 0, "count": 0},
                "Return Refunds": {"$": 0, "count": 0},
                "Alerts": {"$": 0, "count": 0},
                "Other": {"$": 0, "count": 0}
            })
        },
        getCRMPerAgentStats: (state, getters) => {
            const supportedAgents = getters.getAllAgents.reduce((accum, item) => {
                accum[item.CRMID] = item.Alias
                return accum
            }, {})
            const crmCampaignFilter = getters.crmCampaignFilter
            let data = state.crm_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true).filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientFilter(item))
            const results1 = data.filter((item) => supportedAgents[item.agentUserId] !== undefined).reduce((accum, item) => {
                const name = supportedAgents[item.agentUserId]
                if (accum[name] === undefined) {
                    accum[name] = {
                        completeCount: 0,
                        complete$: 0,
                        completeCA$: 0,
                        cancelledCount: 0,
                        cancelled$: 0,
                        cancelledCA$: 0,
                        refundedCount: 0,
                        refunded$: 0,
                        refundedCA$: 0,
                        totalCount: 0,
                        total$: 0,
                        totalCA$: 0,
                    }
                }
                if (item.orderStatus === 'COMPLETE') {
                    accum[name].completeCount += item['count']
                    accum[name].totalCount += item['count']
                    if (item['currencySymbol'] === '$') {
                        accum[name].complete$ += item['totalAmount']
                        accum[name].total$ += item['totalAmount']
                    } else if (item['currencySymbol'] === 'CA$') {
                        accum[name].completeCA$ += item['totalAmount']
                        accum[name].totalCA$ += item['totalAmount']
                    }
                }
                return accum
            }, {})
            data = state.crm_transactions_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true).filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientFilter(item))
            if (getters.getExcludeVoids == true) {
                data = data.map((item) => {
                    if (item.txnType == 'VOID' && item.billingCycleNumber >= 2) {
                        return {...item, totalAmount: 0}
                    }
                    return item
                })
            }
            let source_notes_refunds = state.crm_notes_records_first.filter((item) => item.categoryType === 'REFUNDED' || item.categoryType === 'VOID').reduce((accum, item) => {
                accum[item.customerId] = item.agentName
                return accum
            }, {})

            const results2 = data.map((item) => {
                item.agentName = source_notes_refunds[item.customerId]
                return item
            }).filter((item) => item.agentName && item.agentName.startsWith("LC-")).reduce((accum, item) => {
                if (accum[item.agentName] === undefined) {
                    accum[item.agentName] = {
                        completeCount: 0,
                        complete$: 0,
                        completeCA$: 0,
                        cancelledCount: 0,
                        cancelled$: 0,
                        cancelledCA$: 0,
                        refundedCount: 0,
                        refunded$: 0,
                        refundedCA$: 0,
                        totalCount: 0,
                        total$: 0,
                        totalCA$: 0,
                    }
                }
                accum[item.agentName].refundedCount += 1
                accum[item.agentName].totalCount += 1
                if (item['currencySymbol'] === '$') {
                    accum[item.agentName].refunded$ += item['totalAmount']
                    accum[item.agentName].total$ += item['totalAmount']
                } else if (item['currencySymbol'] === 'CA$') {
                    accum[item.agentName].refundedCA$ += item['totalAmount']
                    accum[item.agentName].totalCA$ += item['totalAmount']
                }
                return accum
            }, {})
            data = state.crm_purchase_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true).filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientPurchaseFilter(item))

            let source_notes_cancels = state.crm_notes_records_first.filter((item) => item.categoryType === 'REBILL_CANCELLED').reduce((accum, item) => {
                accum[item.customerId] = item.agentName
                return accum
            }, {})

            const results3 = data.map((item) => {
                item.agentName = source_notes_cancels[item.customerId]
                return item
            }).filter((item) => item.agentName && item.agentName.startsWith("LC-")).reduce((accum, item) => {
                if (accum[item.agentName] === undefined) {
                    accum[item.agentName] = {
                        completeCount: 0,
                        complete$: 0,
                        completeCA$: 0,
                        cancelledCount: 0,
                        cancelled$: 0,
                        cancelledCA$: 0,
                        refundedCount: 0,
                        refunded$: 0,
                        refundedCA$: 0,
                        totalCount: 0,
                        total$: 0,
                        totalCA$: 0,
                    }
                }
                accum[item.agentName].cancelledCount += 1
                accum[item.agentName].totalCount += 1
                accum[item.agentName].cancelled$ += item['price']

                return accum
            }, {})
            let results = Object.keys(results1).reduce((acc, item) => {
                acc[item] = {}
                return acc
            }, {})

            results = Object.keys(results2).reduce((acc, item) => {
                acc[item] = {}
                return acc
            }, results)

            results = Object.keys(results3).reduce((acc, item) => {
                acc[item] = {}
                return acc
            }, results)

            const crmCampaigns = Object.keys(getters.crmCampaigns)
            let agentData = getters.getCRMAgentDashboardRecords.filter((item) => crmCampaigns.includes(item.campaign))

            let final_result = Object.keys(results).reduce((acc, item) => {
                if (acc.item === undefined) {
                    acc[item] = {
                        name: item,
                        completeCount: 0,
                        complete$: 0,
                        completeCA$: 0,
                        cancelledCount: 0,
                        cancelled$: 0,
                        cancelledCA$: 0,
                        refundedCount: 0,
                        refunded$: 0,
                        refundedCA$: 0,
                        refundedPerCall$: 0,
                        refundedPerCallCA$: 0,
                        totalCount: 0,
                        total$: 0,
                        totalCA$: 0,
                        calls: state.crm_agent_dashboard_records_loading ? undefined : null
                    }
                }

                if (results1[item]) {
                    acc[item].completeCount += results1[item].completeCount
                    acc[item].complete$ += results1[item].complete$
                    acc[item].completeCA$ += results1[item].completeCA$
                    acc[item].cancelledCount += results1[item].cancelledCount
                    acc[item].cancelled$ += results1[item].cancelled$
                    acc[item].cancelledCA$ += results1[item].cancelledCA$
                    acc[item].refundedCount += results1[item].refundedCount
                    acc[item].refunded$ += results1[item].refunded$
                    acc[item].refundedCA$ += results1[item].refundedCA$
                    acc[item].totalCount += results1[item].totalCount
                    acc[item].total$ += results1[item].total$
                    acc[item].totalCA$ += results1[item].totalCA$
                }
                if (results2[item]) {
                    acc[item].completeCount += results2[item].completeCount
                    acc[item].complete$ += results2[item].complete$
                    acc[item].completeCA$ += results2[item].completeCA$
                    acc[item].cancelledCount += results2[item].cancelledCount
                    acc[item].cancelled$ += results2[item].cancelled$
                    acc[item].cancelledCA$ += results2[item].cancelledCA$
                    acc[item].refundedCount += results2[item].refundedCount
                    acc[item].refunded$ += results2[item].refunded$
                    acc[item].refundedCA$ += results2[item].refundedCA$
                    acc[item].totalCount += results2[item].totalCount
                    acc[item].total$ += results2[item].total$
                    acc[item].totalCA$ += results2[item].totalCA$
                }
                if (results3[item]) {
                    acc[item].completeCount += results3[item].completeCount
                    acc[item].complete$ += results3[item].complete$
                    acc[item].completeCA$ += results3[item].completeCA$
                    acc[item].cancelledCount += results3[item].cancelledCount
                    acc[item].cancelled$ += results3[item].cancelled$
                    acc[item].cancelledCA$ += results3[item].cancelledCA$
                    acc[item].refundedCount += results3[item].refundedCount
                    acc[item].refunded$ += results3[item].refunded$
                    acc[item].refundedCA$ += results3[item].refundedCA$
                    acc[item].totalCount += results3[item].totalCount
                    acc[item].total$ += results3[item].total$
                    acc[item].totalCA$ += results3[item].totalCA$
                }
                return acc
            }, {})
            agentData.every((item) => {
                const agent = getters.getAgentByEmail(item.agent)
                if (final_result[agent.Alias]) {
                    if (final_result[agent.Alias].calls === undefined || final_result[agent.Alias].calls === null) {
                        final_result[agent.Alias].calls = item.CallCount
                    } else {
                        final_result[agent.Alias].calls += item.CallCount
                    }
                }
                return true
            })
            Object.values(final_result).every((item) => {
                if (final_result[item.name].calls !== undefined && final_result[item.name].calls !== null) {
                    final_result[item.name].refundedPerCall$ = final_result[item.name].refunded$ / final_result[item.name].calls
                    final_result[item.name].refundedPerCallCA$ = final_result[item.name].refundedCA$ / final_result[item.name].calls
                }
                else {
                    final_result[item.name].refundedPerCall$ = 'N/A'
                    final_result[item.name].refundedPerCallCA$ = 'N/A'
                }
                return true
            })
            if (getters.getCurrentUserShowName == 1) {
                Object.values(final_result).forEach((agent) => {
                    let agent_from_lookup = getters.getAllAgents.find(item => item.Alias === agent.name)
                    if (agent_from_lookup !== undefined) {
                        agent.name = agent.name + '<br/>' + agent_from_lookup.firstName + ' ' + agent_from_lookup.secondName
                    }
                })
            }
            return Object.values(final_result).sort((a, b) => {
                return b.totalCount - a.totalCount
            })
        },
        getLogicallCRMStatsFirst: (state, getters) => {
            return getters.calcCRMStats(true, true)
        },
        getCRMStatsFirst: (state, getters) => {
            return getters.calcCRMStats(true, false)
        },
        getLogicallCRMStatsSecond: (state, getters) => {
            return getters.calcCRMStats(false, true)
        },
        getCRMStatsSecond: (state, getters) => {
            return getters.calcCRMStats(false, false)
        },
        crmClients: (state, getters) => {
            return getters.selectedCampaigns.reduce((accum, item) => {
                const campaign = getters.getCampaignByID(item)
                if (campaign.client_name) {
                    accum[campaign.client_name] = 1
                }
                return accum
            }, {})
        },
        getExcludeVoids: (state) => state.excludeVoids,
        crmCampaigns: (state, getters) => {
            return getters.selectedCampaigns.reduce((accum, item) => {
                const campaign = getters.getCampaignByID(item)
                if (campaign.client_name) {
                    accum[campaign.name] = 1
                }
                return accum
            }, {})
        },
        crmClientsSelected: (state, getters) => {
            return Object.values(getters.crmClients).length
        },
        crmDataLoaded: (state) => {
            return state.crm_data_loaded === 0
        },
        calcCRMStats: (state, getters) => (first, isLogicall) => {
            const crmCampaignFilter = getters.crmCampaignFilter
            const sales = {
                'NEW_SALE': {
                    'PARTIAL': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'COMPLETE': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'DECLINED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'CANCELLED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'REFUNDED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'PENDING': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                },
                'RECURRING': {
                    'PARTIAL': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'COMPLETE': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'DECLINED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'CANCELLED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'REFUNDED': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                    'PENDING': {
                        'count': 0,
                        '$': {'price': 0, 'refund': 0, 'total': 0},
                        'CA$': {'price': 0, 'refund': 0, 'total': 0},
                    },
                },
                'REFUND_1_BC': {
                    'count': 0,
                    '$': {'price': 0, 'refund': 0, 'total': 0},
                    'CA$': {'price': 0, 'refund': 0, 'total': 0},
                },
                'REFUND_2_BC': {
                    'count': 0,
                    '$': {'price': 0, 'refund': 0, 'total': 0},
                    'CA$': {'price': 0, 'refund': 0, 'total': 0},
                },
                'CANCEL_1_BC': {
                    'count': 0,
                    'price': 0
                },
                'CANCEL_2_BC': {
                    'count': 0,
                    'price': 0
                }
            }
            let source_data = state.crm_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
            if (first === false)
                source_data = state.crm_records_second.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
            let data = source_data
            if (isLogicall) {
                const supportedAgents = getters.getAllAgents.reduce((accum, item) => {
                    accum[item.CRMID] = 1
                    return accum
                }, {})
                data = source_data.filter((item) => supportedAgents[item.agentUserId] === 1)
            }
            data = data.filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientFilter(item))
            const results = data.reduce((accum, item) => {
                accum[item['orderType']][item['orderStatus']]['count'] += item['count']
                accum[item['orderType']][item['orderStatus']][item['currencySymbol']]['price'] += item['price']
                accum[item['orderType']][item['orderStatus']][item['currencySymbol']]['total'] += item['totalAmount']
                accum[item['orderType']][item['orderStatus']][item['currencySymbol']]['refund'] += item['refundRemaining']
                return accum
            }, sales)

            source_data = state.crm_transactions_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
            let source_notes = state.crm_notes_records_first
            if (first === false) {
                source_data = state.crm_transactions_records_second.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
                source_notes = state.crm_notes_records_second
            }
            data = source_data
            data = data.filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientFilter(item))
            if (isLogicall) {
                source_notes = source_notes.filter((item) => item.categoryType === 'REFUNDED').reduce((accum, item) => {
                    accum[item.customerId] = item.agentName
                    return accum
                }, {})
                data = data.map((item) => {
                    item['agentName'] = source_notes[item.customerId]
                    return item
                }).filter((item) => item.agentName && item.agentName.startsWith("LC-"))
            }
            for (var item of data) {
                if (item.billingCycleNumber === 1 || item.billingCycleNumber === 0 || item.billingCycleNumber === null) {
                    results['REFUND_1_BC']['count'] += 1
                    results['REFUND_1_BC'][item['currencySymbol']]['price'] += item['totalAmount']
                } else if (item.billingCycleNumber >= 2) {
                    results['REFUND_2_BC']['count'] += 1
                    if (item.txnType != 'VOID' || getters.getExcludeVoids == false) {
                        results['REFUND_2_BC'][item['currencySymbol']]['price'] += item['totalAmount']
                    }
                }
            }

            source_data = state.crm_purchase_records_first.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
            source_notes = state.crm_notes_records_first
            if (first === false) {
                source_data = state.crm_purchase_records_second.filter(item => crmCampaignFilter[item.campaignId] ? false : true)
                source_notes = state.crm_notes_records_second
            }
            data = source_data
            data = data.filter((item) => getters.crmClients[item.clientName] === 1).filter((item) => getters.crmClientPurchaseFilter(item))
            if (isLogicall) {
                source_notes = source_notes.filter((item) => item.categoryType === 'REBILL_CANCELLED').reduce((accum, item) => {
                    accum[item.customerId] = item.agentName
                    return accum
                }, {})
                data = data.map((item) => {
                    item['agentName'] = source_notes[item.customerId]
                    return item
                }).filter((item) => item.agentName && item.agentName.startsWith("LC-"))
            }
            for (item of data) {
                if (item.billingCycleNumber === 1 || item.billingCycleNumber === 0 || item.billingCycleNumber === null) {
                    results['CANCEL_1_BC']['count'] += 1
                    results['CANCEL_1_BC']['price'] += item['price']
                } else if (item.billingCycleNumber >= 2) {
                    results['CANCEL_2_BC']['count'] += 1
                    results['CANCEL_2_BC']['price'] += item['price']
                }
            }

            return results
        }
    }
}
