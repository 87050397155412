import {listPayments} from "../graphql/queries";
import {createPayment, deletePayment} from "../graphql/mutations";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
} from "@/utils/query_all_utils";
import {API, graphqlOperation} from "aws-amplify";

export default {
    state: {
        payments: [],
        payments_per_page: 20,
        next_available: false,
        page: 1
    },
    mutations: {
        SET_PAYMENTS: (state, items) => state.payments = Object.freeze(items),
        UPDATE_PAYMENTS_PER_PAGE: (state, amount) => state.payments_per_page = amount,
        SET_NEXT_AVAILABLE: (state, next_available) => state.next_available = next_available,
        SET_PAGE: (state, page) => state.page = page,
    },
    actions: {
        setPayments: ({commit}, items) => {
            commit('SET_PAYMENTS', items)
        },
        removePayment({dispatch, getters}, id) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deletePayment,
                        {id: id}
                    ).then(() => {
                        dispatch('setPayments', getters.getAllPayments.filter((item) => item.id !== id))
                        dispatch('endActionAnimation')
                        resolve()
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addPayment({dispatch}, payment) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let payment_input = {
                        ...payment,
                    }
                    executeGraphQLMutationCreate(createPayment, {createPaymentInput: payment_input}).then(() => {
                        dispatch('loadPayments')
                        dispatch('initializeLastPaymentsForAgent').then(() => {
                            dispatch('calculatedProductivityMetrics').finally(() => {
                                dispatch('endActionAnimation')
                                resolve()
                            })
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        updatePaymentsPerPage: ({commit}, amount) => {
            commit('UPDATE_PAYMENTS_PER_PAGE', amount)
        },
        setNextAvailable: ({commit}, next_available) => {
            commit('SET_NEXT_AVAILABLE', next_available)
        },
        resetPayments: ({commit, dispatch}) => {
            commit('SET_PAGE', 1)
            commit('SET_NEXT_AVAILABLE', false)
            dispatch('setPayments', [])
        },
        loadNextPagePayments({dispatch, commit, state}) {
            commit('SET_PAGE', state.page + 1)
            dispatch('loadPayments')
        },
        loadPayments({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {

                let filter = {
                    agent_filter: '',
                    LIMIT: getters.getPaymentsPerPage + 1,
                    OFFSET: getters.getPaymentsPerPage * (getters.getPaymentsPage - 1),
                }
                dispatch('setNextAvailable', false)

                API.graphql(graphqlOperation(listPayments, {...filter}))
                    .then((response) => {
                        var payments = response.data.listPayments

                        if (payments.length > getters.getPaymentsPerPage) {
                            dispatch('setNextAvailable', true)
                            dispatch('setPayments', payments.slice(0, payments.length - 1))
                        } else {
                            dispatch('setNextAvailable', false)
                            dispatch('setPayments', payments)
                        }
                        resolve()
                    }).catch((e) => {
                    dispatch('processError', e)
                    commit('NAVIGATE_ENDED')
                    reject(e)
                })
            })
        }
    },
    getters: {
        getAllPayments: state => state.payments,
        getPaymentByID: state => (id) => state.payments.find((item) => item.id == id),
        getPaymentsPerPage: state => state.payments_per_page,
        getPaymentsPage: state => state.page,
        getPaymentsNextAvailable: state => state.next_available
    },
};
