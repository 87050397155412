import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import VueRandomColor from 'vue-randomcolor'


Vue.use(VueRandomColor)
Amplify.configure(awsconfig);
Vue.use(require('vue-moment'));


Vue.use(VueFeather);
Vue.use(VueSkycons, {
    color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.directive('blur', {
    inserted: function (el) {
        el.onfocus = (ev) => ev.target.blur()
    }
});
new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
