import randomColor from 'randomcolor/randomColor'

export default function generate(count, luminosity, seed)
{
    return randomColor({
        count,
        luminosity,
        seed,
        hue: 'random',
        reset: true
    })
}