import {Auth} from 'aws-amplify';
import {USERS_GROUP_NAME, ADMINS_GROUP_NAME, AGENTS_GROUP_NAME, SUPERVISORS_GROUP_NAME, COMMISSIONS_REVIEWER_GROUP_NAME, API_USER_GROUP_NAME, LESSER_ADMINS_GROUP_NAME} from "@/constants"
import {getAmUser} from "../graphql/queries";
import {DEFAULT_TIMEZONE} from "../constants";
import {API, graphqlOperation} from 'aws-amplify';

export default {
    state: {
        current_user: null,
        current_user_extended_data: null,
        supported_roles: [ADMINS_GROUP_NAME, USERS_GROUP_NAME, SUPERVISORS_GROUP_NAME, AGENTS_GROUP_NAME, COMMISSIONS_REVIEWER_GROUP_NAME, API_USER_GROUP_NAME, LESSER_ADMINS_GROUP_NAME],
    },
    mutations: {
        SET_CURRENT_USER: (state, user) => {
            state.current_user = user
            if (user == null) {
                state.current_user_extended_data = null
            }
        },
        SET_CURRENT_USER_EXTENDED_DATA: (state, data) => state.current_user_extended_data = data
    },
    actions: {
        initializeAuth: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser()
                    .then((user_data) => {
                        API.graphql(graphqlOperation(getAmUser, {id:user_data.attributes.sub})).then((response) => {
                            commit('SET_CURRENT_USER', user_data)
                            if (response.data.getAmUser) {
                                response.data.getAmUser.campaigns = response.data.getAmUser.campaigns.replaceAll(' ','').split(',').filter((item) => item.length > 0)
                                response.data.getAmUser.agents = response.data.getAmUser.agents ? response.data.getAmUser.agents.replaceAll(' ','').split(',').filter((item) => item.length > 0) : []
                                commit('SET_CURRENT_USER_EXTENDED_DATA', response.data.getAmUser)
                            } else {
                                commit('SET_CURRENT_USER_EXTENDED_DATA', {campaigns: [], timezone: DEFAULT_TIMEZONE, display_from: 0})
                            }
                            resolve()
                        }).catch((e) => {
                                commit('SET_CURRENT_USER', null)
                                dispatch('processError', e)
                                reject()
                            }
                        )
                    })
                    .catch(() => {
                            commit('SET_CURRENT_USER', null)
                            reject()
                        }
                    )
            })
        },
        logout: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                Auth.signOut().then(() => {
                    commit('INITIALIZE_RESET')
                    commit('SET_CURRENT_USER', null)
                    dispatch('updateSelectedCampaigns', undefined)
                    dispatch('updateTimezone', undefined)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject()
                })
            })
        }
    },
    getters: {
        isAuthenticated: state => !!state.current_user,
        isAdmin: (state, getters) => getters.getCurrentUserGroup == ADMINS_GROUP_NAME,
        isLesserAdmin: (state, getters) => getters.getCurrentUserGroup == LESSER_ADMINS_GROUP_NAME,
        isUser: (state, getters) => getters.getCurrentUserGroup == USERS_GROUP_NAME,
        isSupervisor: (state, getters) => getters.getCurrentUserGroup == SUPERVISORS_GROUP_NAME,
        isCommissionsReviewer: (state, getters) => getters.getCurrentUserGroup == COMMISSIONS_REVIEWER_GROUP_NAME,
        isApiUser: (state, getters) => getters.getCurrentUserGroup == API_USER_GROUP_NAME,
        isAgent: (state, getters) => getters.getCurrentUserGroup == AGENTS_GROUP_NAME,
        getCurrentUserGroup: state => {
            if (state.current_user) {
                if (state.current_user.signInUserSession.idToken.payload['cognito:groups']) {
                    if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(ADMINS_GROUP_NAME)) {
                        return ADMINS_GROUP_NAME
                    }
                    if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(LESSER_ADMINS_GROUP_NAME)) {
                        return LESSER_ADMINS_GROUP_NAME
                    }
                    if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(SUPERVISORS_GROUP_NAME)) {
                        return SUPERVISORS_GROUP_NAME
                    }
                    if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(AGENTS_GROUP_NAME)) {
                        return AGENTS_GROUP_NAME
                    }
                    if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(COMMISSIONS_REVIEWER_GROUP_NAME)) {
                        return COMMISSIONS_REVIEWER_GROUP_NAME
                    }
                     if (state.current_user.signInUserSession.idToken.payload['cognito:groups'].includes(API_USER_GROUP_NAME)) {
                        return API_USER_GROUP_NAME
                    }
                }
            }
            return USERS_GROUP_NAME
        },
        getCurrentUserJWTToken: state => state.current_user.signInUserSession.accessToken.jwtToken,
        getCurrentUserEmail: state => {
            if (state.current_user) {
                return state.current_user.attributes.email;
            }
            return "Logged Out..."
        },
        getCurrentUserName: state => {
            if (state.current_user) {
                return state.current_user.attributes.name;
            }
            return "Logged Out..."
        },
        getCurrentUserDisplayFrom: state => {
            if (state.current_user) {
                return state.current_user_extended_data.display_from;
            }
            return 0
        },
        getCurrentUserCampaigns: (state) => {
            if (state.current_user) {
                return state.current_user_extended_data.campaigns
            }
            return []
        },
        getCurrentUserAgents: (state) => {
            if (state.current_user) {
                return state.current_user_extended_data.agents
            }
            return []
        },
        getCurrentUserTimezone: state => {
            if (state.current_user) {
                return state.current_user_extended_data.timezone
            }
            return DEFAULT_TIMEZONE
        },
        getCurrentUserShowName: state => {
            if (state.current_user) {
                return state.current_user_extended_data.show_name
            }
            return 0
        },
        getSupportedRoles: state => state.supported_roles,
        getCurrentUserIsExpirimental: (state, getters) => getters.getCurrentUserEmail == 'mitchtest@logicall.live' ? true : false
    }
}
