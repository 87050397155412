import {Logger} from 'aws-amplify';
const logger = new Logger('frontend');

export default {
    state: {
        error: ''
    },
    mutations: {
        SET_ERROR(state, value) {
            state.error = value;
            if (value) {
                // eslint-disable-next-line no-console
                console.log(value)
                logger.error(value)
            }
        },
    },
    actions: {
        processError: ({commit}, value) => commit('SET_ERROR', value),
        resetError: ({commit}) => commit('SET_ERROR', ''),
    },
    getters: {
        getCurrentError:
            state => state.error,
    },
};
