import {listAgents} from "../graphql/queries";
import {AGENTS_GROUP_NAME} from "@/constants"
import {createAgent, updateAgent} from "../graphql/mutations";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationUpdate
} from "@/utils/query_all_utils";
import {API} from "aws-amplify";
import {AGENT_TYPE_CONTRACTOR, AGENT_TYPE_EMPLOYEE} from "../constants";

export default {
    state: {
        agents: [],
    },
    mutations: {
        SET_AGENTS: (state, agents) => state.agents = Object.freeze(agents),
    },
    actions: {
        setAgents: ({commit, getters}, agents) => {
            let alias_count = 1
            agents.forEach(agent => {
                agent.payrate = agent.payrate / 10000
                agent.type = agent.type == AGENT_TYPE_CONTRACTOR ? AGENT_TYPE_CONTRACTOR : AGENT_TYPE_EMPLOYEE
                agent.agent_type = agent.agent_type?.length > 0 ? agent.agent_type : 'Agent Call Refunds'
                agent.Alias = agent.Alias?.length > 0 ? agent.Alias : `No Alias Defined ${alias_count++}`
                if (getters.getCurrentUserShowName == 0 && !getters.isAdmin) {
                    agent.firstName = agent.Alias
                    agent.secondName = ''
                }
                if (agent.AccessiableOutgoingCustomers) {
                    agent.AccessiableOutgoingCustomers = agent.AccessiableOutgoingCustomers.split(',')
                } else {
                    agent.AccessiableOutgoingCustomers = []
                }
                if (agent.vacation) {
                    agent.vacation = JSON.parse(agent.vacation.replaceAll('`', '"').replaceAll('(', '[').replaceAll(')', ']'))
                } else {
                    agent.vacation = []
                }

            })
            commit('SET_AGENTS', agents)
        },
        initializeAgents: ({dispatch, getters}) => {
            return new Promise((resolve, reject) => {
                let filter = {
                    agent_filter: getters.getFilter
                }
                fetchItemsNextTokenGraphQL(listAgents, filter).then((items) => {
                    dispatch('setAgents', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        changeAgentStatus({dispatch, getters}, params) {
            var user = getters.getUserByEmail(params.email)
            if (user) {
                if (user.email == 'systemadmin@dogezer.com') {
                    alert("You can't change that user")
                    return;
                }
                if (user.email == undefined) {
                    alert("Unable to disable/enable user, no user with such email")
                    return;
                }
                return new Promise((resolve, reject) => {
                        let apiName = 'AdminQueries';
                        let path = params.to_archive ? '/disableUser' : '/enableUser';
                        let requestData = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: getters.getCurrentUserJWTToken
                            },
                            body: {
                                'username': user['Username'],
                            }
                        }
                        API.post(apiName, path, requestData).then(() => {
                            resolve()
                        }).catch((e) => {
                            dispatch('processError', e)
                            reject(e)
                        })

                    }
                )
            } else {
                return new Promise((resolve) => resolve())
            }
        },
        updateAgent({dispatch}, agent) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let agent_input = {
                        ...agent,
                        'is_rate_hourly': agent.is_rate_hourly ? 1 : 0,
                        'payrate': agent.payrate ? Math.round(agent.payrate * 10000) : 0,
                        'vacation': JSON.stringify(agent.vacation).replaceAll('"', '`'),
                        'agent_type': agent.agent_type === 'Agent Call Refunds' ? '' : agent.agent_type,
                        'Alias': agent.Alias?.length > 0 ? agent.Alias : '',
                        'AccessiableOutgoingCustomers': agent.AccessiableOutgoingCustomers.join(",")
                    }

                    executeGraphQLMutationUpdate(updateAgent, {updateAgentInput: agent_input}).then(() => {
                        dispatch('initializeAgents')
                            .then(() => {
                                dispatch('endActionAnimation')
                                resolve()
                            })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        removeAgent({dispatch, getters}, agent) {
            dispatch('startActionAnimation')
            let foundAgent = getters.getAgentByID(agent.id)
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationUpdate(updateAgent,
                        {updateAgentInput: agent}
                    ).then(() => {
                        dispatch('changeAgentStatus', {email: foundAgent.email, to_archive: agent.archived}).then(() =>
                            dispatch('initializeAgents').then(() => {
                                dispatch('endActionAnimation')
                                resolve()
                            }))
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addAgent({dispatch}, agent) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let agent_input = {
                        ...agent,
                        'is_rate_hourly': agent.is_rate_hourly ? 1 : 0,
                        'payrate': agent.payrate ? Math.round(agent.payrate * 10000) : 0,
                        'vacation': JSON.stringify(agent.vacation).replaceAll('"', '`'),
                        'AccessiableOutgoingCustomers': agent.AccessiableOutgoingCustomers.join(",")
                    }
                    executeGraphQLMutationCreate(createAgent, {createAgentInput: agent_input}).then(() => {
                        dispatch('initializeAgents').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getFilter: (state, rootGetters) => {
            if (rootGetters.getCurrentUserGroup == AGENTS_GROUP_NAME) {
                return rootGetters.getCurrentUserEmail
            } else {
                return ''
            }
        },
        getAllAgents: (state, rootGetters) => {
            if (rootGetters.getCurrentUserAgents.length == 0) {
                return state.agents
            } else {
                return state.agents.filter((agent) => rootGetters.getCurrentUserAgents.includes(agent.id))
            }
        },
        getAllActiveAgents: (state, rootGetters) => {
            if (rootGetters.getCurrentUserAgents.length == 0) {
                return state.agents.filter((item) => !item.archived)
            } else {
                return state.agents.filter((agent) => rootGetters.getCurrentUserAgents.includes(agent.id) && !agent.archived)
            }
        },
        getAgentByID: state => (id) => state.agents.find((item) => item.id == id),
        getAgentByEmail: state => (email) => state.agents.find((item) => item.email.toLowerCase() == email.toLowerCase()),
        getAgentNameByEmail: state => (email) => {
            let agent = state.agents.find((item) => item.email.toLowerCase() == email.toLowerCase())
            return agent.firstName + ' ' + agent.secondName
        },
    },
};
