import random_color_generator from "@/utils/random_color_generator"
import {listAgentDashboardRecords} from "../graphql/queries";
import {
    fetchItemsNextTokenGraphQL,
    BuildProductivityFilter,
    emptyProductivityData,
    toDPIndex,
    remapping,
    agent_type_tiers_commission_type_mapping,
    sumToHierarchy,
    shouldBePaid,
    shouldCountUnproductive
} from "@/utils/query_all_utils";
import {
    AGENT_TYPE_EMPLOYEE,
    COMMISSION_TYPE_INBOUND,
    COMMISSION_TYPE_OUTBOUND,
    COMMISSION_TYPE_UPSELL
} from "../constants";

const moment = require('moment-timezone')


moment.tz.setDefault('America/Vancouver')


export default {
    state: {
        startTime: 0,
        endTime: 23,
        initial_productivity_loaded: false,
        productivitySelectedAgents: [],
        savedItems: [],
        calculatedProductivityData: {...emptyProductivityData, xAxisNormal: Array()},
        calculatedAgentsStats: [],
        calculatedPayrollsData: {
            earned_hourly: 0,
            earned_statutory: 0,
            earned_commissions: 0,
            already_paid: 0,
            to_be_paid: 0,
        },
        payrolls: [],
    },
    mutations: {
        SET_SAVED_ITEMS: (state, items) => state.savedItems = items,
        SET_START_TIME: (state, time) => state.startTime = time,
        SET_END_TIME: (state, time) => state.endTime = time,
        SET_INITIAL_PRODUCTIVITY_LOADED: (state) => state.initial_productivity_loaded = true,
        RESET_INITIAL_PRODUCTIVITY_LOADED: (state) => state.initial_productivity_loaded = false,
        UPDATE_SELECTED_AGENTS: (state, agents) => state.productivitySelectedAgents = agents,
        SET_CALCULATED_PRODUCTIVITY_DATA: (state, value) => state.calculatedProductivityData = Object.freeze(value),
        SET_CALCULATED_AGENTS_STATS: (state, value) => state.calculatedAgentsStats = Object.freeze(value),
        SET_PAYROLLS: (state, value) => state.payrolls = Object.freeze(value),
        SET_CALCULATED_PAYROLL_DATA: (state, value) => state.calculatedPayrollsData = value
    },
    actions: {
        updateSelectedAgents({state, commit, dispatch}, agents) {
            let current_agents = state.productivitySelectedAgents.sort((a, b) => a.email.localeCompare(b.email))
            let agents_array = agents.sort((a, b) => a.email.localeCompare(b.email))
            if (!(agents_array.length === current_agents.length && agents_array.every((value, index) => value === current_agents[index]))) {
                commit('UPDATE_SELECTED_AGENTS', agents)
                commit("START_PRODUCTIVITY_LOADING")
                setTimeout(() => {
                    dispatch('calculatedProductivityMetrics')
                }, 10)
            }
        },
        setInitialAgents({getters, commit}) {
            commit('UPDATE_SELECTED_AGENTS', getters.getAllActiveAgents)
        },
        setStartTime({commit}, time) {
            commit('SET_START_TIME', time)
        },
        setEndTime({commit}, time) {
            commit('SET_END_TIME', time)
        },
        initializeProductivity({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                    commit("START_PRODUCTIVITY_LOADING")
                    var filter = BuildProductivityFilter(getters.getDashboardMainDates, getters.getCurrentUserDisplayFrom, getters.getLastPayments, getters.getPayrollDate, getters.getAllHolidays, {
                        start: getters.getStartTime, end: getters.getEndTime
                    })

                    if (getters.getSelectedAgents.length == 0) {
                        commit('SET_SAVED_ITEMS', [])
                        dispatch('calculatedProductivityMetrics')
                        resolve()
                        return
                    }
                    fetchItemsNextTokenGraphQL(listAgentDashboardRecords, {
                        ...filter
                    }, 160).then((items) => {
                        commit('SET_SAVED_ITEMS', items)
                        dispatch('calculatedProductivityMetrics')
                        resolve()
                    }).catch((e) => {
                        dispatch("processError", e)
                        reject(e)
                    })

                }
            )
        },
        initializeProductivityForPayrolls({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                    commit("START_PRODUCTIVITY_LOADING")
                    var filter = BuildProductivityFilter(null, getters.getCurrentUserDisplayFrom, getters.getLastPayments, getters.getPayrollDate, getters.getAllHolidays, null)

                    if (getters.getSelectedAgents.length == 0) {
                        commit('SET_SAVED_ITEMS', [])
                        dispatch('calculatedProductivityMetrics')
                        resolve()
                        return
                    }
                    fetchItemsNextTokenGraphQL(listAgentDashboardRecords, {
                        ...filter
                    }, 160).then((items) => {
                        commit('SET_SAVED_ITEMS', items)
                        dispatch('calculatedProductivityMetrics')
                        resolve()
                    }).catch((e) => {
                        dispatch("processError", e)
                        reject(e)
                    })

                }
            )
        },
        calculatedProductivityMetrics({commit, getters, state}) {
            let mappingNormal = {}
            let xAxisNormal = []
            let lowestStartDate
            let lowest_normal_date
            let highest_normal_date
            let hour
            let last_day
            let current_day


            let calculatedPayrollsData = {
                earned_hourly: 0,
                earned_statutory: 0,
                earned_commissions: 0,
                holiday_bonus: 0,
                already_paid: 0,
                to_be_paid: 0,
            }

            lowestStartDate = moment(getters.getDashboardMainDates[0]).startOf('day')
            lowest_normal_date = Math.floor(toDPIndex(moment(getters.getDashboardMainDates[0]).startOf('day')))
            highest_normal_date = Math.floor(toDPIndex(moment(getters.getDashboardMainDates[1]).endOf('day')))

            if (getters.getDashboardMainDates[0] == getters.getDashboardMainDates[1]) {
                getters.getEmptyDashboardsData.xAxisHourly.forEach((acc, index) => {
                    xAxisNormal.push(moment({hour: index}).format('h:mm A'));
                })
                for (hour of getters.getEmptyDashboardsData.xAxisHourly) {
                    mappingNormal[lowest_normal_date + hour] = hour
                }
            } else {
                last_day = undefined;
                for (hour = lowest_normal_date; hour < highest_normal_date; hour++) {
                    mappingNormal[hour] = Math.floor((hour - lowest_normal_date) / 24)
                    current_day = lowestStartDate.format("MMM D")
                    if (current_day != last_day) {
                        xAxisNormal.push(current_day)
                        last_day = current_day
                    }
                    lowestStartDate.add(1, "hours")
                }
            }
            var calculatedData = {
                xAxisNormal: xAxisNormal
            }
            for (var key of Object.keys(emptyProductivityData)) {
                calculatedData[key] = new Array(xAxisNormal.length).fill(0)
            }

            var agents_lookup = getters.getSelectedAgents.reduce((accum, item) => {
                accum[item.email] = 0
                return accum
            }, {})

            var agents_stat = Object.keys(emptyProductivityData).reduce((accum, item) => {
                accum[item] = Object.assign({}, agents_lookup)
                return accum
            }, {})


            for (let record of state.savedItems) {
                let dp_index = record.dp_index
                if (dp_index >= lowest_normal_date && dp_index <= highest_normal_date) {
                    var records = JSON.parse(record.agents)
                    for (let key of Object.keys(records)) {
                        if (agents_lookup[key] !== undefined) {
                            for (let activity of Object.keys(records[key])) {
                                if (activity in remapping) {
                                    for (let to_remap of remapping[activity]) {
                                        let value = records[key][activity]
                                        if (value > 3600)
                                            value = 3600
                                        calculatedData[to_remap][mappingNormal[dp_index]] += value
                                        agents_stat[to_remap][key] += value
                                    }
                                } else {
                                    let value = records[key][activity]
                                    if (value > 3600)
                                        value = 3600
                                    calculatedData["Unclassified"][mappingNormal[dp_index]] += value
                                    agents_stat["Unclassified"][key] += value
                                }
                            }
                        }
                    }
                }
            }
            for (key of Object.keys(emptyProductivityData)) {
                if ((key != 'xAxisNormal') && (key != 'xAxisNormal')) {
                    calculatedData[key] = calculatedData[key].map((item) => Math.round(item / 60))
                }
            }

            let last_agent_paid_lookup = getters.getLastPayments
            //let last_agent_debt_lookup = getters.getLastDebt

            let payrollsLookup = getters.getSelectedAgents.reduce((accum, item) => {
                accum[item.email] = {
                    ...item,
                    by_day: {},
                    commission: 0,
                    per_hour: 0,
                    inbound_sales_count: 0,
                    outbound_sales_count: 0,
                    upsell_sales_count: 0,
                    commission_sales: 0,
                    to_pay_hourly: 0,
                    to_pay_hourly_holiday: 0,
                    to_pay_hourly_holiday_overtime: 0,
                    holiday_bonus: 0,
                    to_pay_overtime: 0,
                    hourly_payment: 0,
                    final_payment: 0,
                    debt: 0,
                    unpaid_time: 0,
                    unpaid_activities: []

                }
                return accum
            }, {})
            let payrollsLookupForHoliday = getters.getSelectedAgents.reduce((accum, item) => {
                accum[item.email] = {
                    ...item,
                    by_day: {},
                }
                return accum
            }, {})


            if (getters.isAgent) {
                for (let commission of getters.getAllCommissionsForAgent) {
                    if ((commission.status == 'Approved') &&
                        (moment(commission.complete_date).startOf('day') >= moment(last_agent_paid_lookup[commission.agent]).startOf('day')) &&
                        (moment(commission.complete_date).startOf('day') <= moment(getters.getPayrollDate).startOf('day'))) {
                        payrollsLookup[commission.agent]['commission'] += agent_type_tiers_commission_type_mapping[payrollsLookup[commission.agent].type][payrollsLookup[commission.agent].tier][commission.type](commission.sum)
                        payrollsLookup[commission.agent]['commission_sales'] += commission.sum
                        if (commission.type === COMMISSION_TYPE_INBOUND) {
                            payrollsLookup[commission.agent]['inbound_sales_count'] += 1
                        } else if (commission.type === COMMISSION_TYPE_OUTBOUND) {
                            payrollsLookup[commission.agent]['outbound_sales_count'] += 1
                        } else if (commission.type === COMMISSION_TYPE_UPSELL) {
                            payrollsLookup[commission.agent]['upsell_sales_count'] += 1
                        }

                        if (payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')] === undefined) {
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')] = {
                                commission: 0,
                                per_hour: 0,
                                inbound_sales_count: 0,
                                outbound_sales_count: 0,
                                upsell_sales_count: 0,
                                commission_sales: 0,
                                to_pay_hourly: 0,
                                holiday_bonus: 0,
                                to_pay_hourly_holiday: 0,
                                to_pay_hourly_holiday_overtime: 0,
                                to_pay_overtime: 0,
                                hourly_payment: 0,
                                unpaid_time: 0,
                            }
                        }
                        payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['commission_sales'] += commission.sum
                        if (commission.type === COMMISSION_TYPE_INBOUND) {
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['inbound_sales_count'] += 1
                        } else if (commission.type === COMMISSION_TYPE_OUTBOUND) {
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['outbound_sales_count'] += 1
                        } else if (commission.type === COMMISSION_TYPE_UPSELL) {
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['upsell_sales_count'] += 1
                        }
                        payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['commission'] += agent_type_tiers_commission_type_mapping[payrollsLookup[commission.agent].type][payrollsLookup[commission.agent].tier][commission.type](commission.sum)
                    }
                }
            } else {
                for (let commission of getters.getAllCommissionsForPayments) {
                    if ((moment(commission.complete_date).startOf('day') >= moment(last_agent_paid_lookup[commission.agent]).startOf('day')) &&
                        (moment(commission.complete_date).startOf('day') <= moment(getters.getPayrollDate).startOf('day'))) {
                        if (payrollsLookup[commission.agent]) {
                            payrollsLookup[commission.agent]['commission'] += agent_type_tiers_commission_type_mapping[payrollsLookup[commission.agent].type][payrollsLookup[commission.agent].tier][commission.type](commission.sum)

                            payrollsLookup[commission.agent]['commission_sales'] += commission.sum
                            if (commission.type === COMMISSION_TYPE_INBOUND) {
                                payrollsLookup[commission.agent]['inbound_sales_count'] += 1
                            } else if (commission.type === COMMISSION_TYPE_OUTBOUND) {
                                payrollsLookup[commission.agent]['outbound_sales_count'] += 1
                            } else if (commission.type === COMMISSION_TYPE_UPSELL) {
                                payrollsLookup[commission.agent]['upsell_sales_count'] += 1
                            }


                            if (payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')] === undefined) {
                                payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')] = {
                                    commission: 0,
                                    per_hour: 0,
                                    inbound_sales_count: 0,
                                    outbound_sales_count: 0,
                                    upsell_sales_count: 0,
                                    commission_sales: 0,
                                    to_pay_hourly: 0,
                                    to_pay_hourly_holiday: 0,
                                    to_pay_hourly_holiday_overtime: 0,
                                    to_pay_overtime: 0,
                                    hourly_payment: 0,
                                    holiday_bonus: 0,
                                    unpaid_time: 0,
                                }
                            }
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['commission_sales'] += commission.sum
                            if (commission.type === COMMISSION_TYPE_INBOUND) {
                                payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['inbound_sales_count'] += 1
                            } else if (commission.type === COMMISSION_TYPE_OUTBOUND) {
                                payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['outbound_sales_count'] += 1
                            } else if (commission.type === COMMISSION_TYPE_UPSELL) {
                                payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['upsell_sales_count'] += 1
                            }
                            payrollsLookup[commission.agent]['by_day'][moment(commission.complete_date).format('YYYY-MM-DD')]['commission'] += agent_type_tiers_commission_type_mapping[payrollsLookup[commission.agent].type][payrollsLookup[commission.agent].tier][commission.type](commission.sum)
                        }
                    }
                }
            }


            for (let record of state.savedItems) {

                let dp_index = record.dp_index
                var payrecords = JSON.parse(record.agents)
                for (let key2 of Object.keys(payrecords)) {
                    if (agents_lookup[key2] !== undefined) {
                        let day = moment.unix(dp_index * 60 * 60).startOf('day')
                        let agent_record = payrollsLookup[key2]
                        if (payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] === undefined && (day > moment(last_agent_paid_lookup[key2]) && day <= moment(getters.getPayrollDate))) {
                            payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] = {
                                commission: 0,
                                per_hour: 0,
                                inbound_sales_count: 0,
                                outbound_sales_count: 0,
                                upsell_sales_count: 0,
                                commission_sales: 0,
                                to_pay_hourly: 0,
                                to_pay_hourly_holiday: 0,
                                holiday_bonus: 0,
                                to_pay_hourly_holiday_overtime: 0,
                                to_pay_overtime: 0,
                                hourly_payment: 0,
                                unpaid_time: 0,
                            }
                        }
                        if (payrollsLookupForHoliday[key2]['by_day'][day.format('YYYY-MM-DD')] === undefined) {
                            payrollsLookupForHoliday[key2]['by_day'][day.format('YYYY-MM-DD')] = 0
                        }

                        if (agent_record.type === AGENT_TYPE_EMPLOYEE) {
                            for (let activity_payrolls of Object.keys(payrecords[key2])) {
                                let sbp = shouldBePaid
                                if (activity_payrolls in sbp[agent_record.type][agent_record.tier]) {
                                    let value = payrecords[key2][activity_payrolls]
                                    if (value > 3600)
                                        value = 3600
                                    payrollsLookupForHoliday[key2]['by_day'][day.format('YYYY-MM-DD')] += value
                                }
                            }
                        }
                        if (day > moment(last_agent_paid_lookup[key2]) && day <= moment(getters.getPayrollDate)) {

                            for (let activity_payrolls of Object.keys(payrecords[key2])) {
                                let sbp = shouldBePaid
                                let value = payrecords[key2][activity_payrolls]
                                if (value > 3600)
                                    value = 3600
                                if (activity_payrolls in sbp[agent_record.type][agent_record.tier]) {
                                    if (agent_record.type === AGENT_TYPE_EMPLOYEE) {
                                        if (getters.getHolidayByDate(day.format('YYYY-MM-DD'))) {
                                            payrollsLookup[key2]['to_pay_hourly_holiday'] += value
                                            payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] ['to_pay_hourly_holiday'] += value

                                        } else {
                                            payrollsLookup[key2]['to_pay_hourly'] += value
                                            payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] ['to_pay_hourly'] += value
                                        }
                                    } else {
                                        payrollsLookup[key2]['to_pay_hourly'] += value
                                        payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] ['to_pay_hourly'] += value
                                    }
                                } else if (activity_payrolls in shouldCountUnproductive) {
                                    (payrollsLookup[key2]['unpaid_activities'].indexOf(activity_payrolls) === -1) && payrollsLookup[key2]['unpaid_activities'].push(activity_payrolls);
                                    payrollsLookup[key2]['unpaid_time'] += value
                                    payrollsLookup[key2]['by_day'][day.format('YYYY-MM-DD')] ['unpaid_time'] += value
                                }
                            }
                        }
                    }
                }
            }
            for (let agent of Object.keys(payrollsLookup)) {
                /*let number_of_days = 0
                //let number_of_holidays = 0
                for (var i = moment(last_agent_paid_lookup[agent]); i <= moment(getters.getPayrollDate); i.add(1, 'day')) {
                    number_of_days += 1
                    if (getters.getHolidayByDate(i.format('YYYY-MM-DD'))) {
                        //number_of_holidays += 1
                    }
                }

                let overtime_limit = number_of_days * 8 * 60 * 60
                //TODO what if all days are holidays
                if ((payrollsLookup[agent].type === AGENT_TYPE_EMPLOYEE) && (payrollsLookup[agent].to_pay_hourly + payrollsLookup[agent].to_pay_hourly_holiday > overtime_limit)) {
                    let delta = payrollsLookup[agent].to_pay_hourly + payrollsLookup[agent].to_pay_hourly_holiday - overtime_limit
                    payrollsLookup[agent].to_pay_hourly -= delta
                    payrollsLookup[agent].to_pay_overtime = delta
                }
                if ((payrollsLookup[agent].type === AGENT_TYPE_EMPLOYEE) && (payrollsLookup[agent].to_pay_hourly_holiday > (12 * 60 * 60))) {
                    let delta = payrollsLookup[agent].to_pay_hourly_holiday - (12 * 60 * 60)
                    payrollsLookup[agent].to_pay_hourly_holiday = (12 * 60 * 60)
                    payrollsLookup[agent].to_pay_hourly_holiday_overtime = delta
                }*/


                let rate = payrollsLookup[agent].is_rate_hourly ? payrollsLookup[agent].payrate / 60 / 60 : payrollsLookup[agent].payrate / 60
                let to_pay_hourly = payrollsLookup[agent].to_pay_hourly //(payrollsLookup[agent].to_pay_hourly/60).toFixed(0)*60
                let to_pay_overtime = payrollsLookup[agent].to_pay_overtime //(payrollsLookup[agent].to_pay_overtime/60).toFixed(0)*60
                let to_pay_hourly_holiday = payrollsLookup[agent].to_pay_hourly_holiday //(payrollsLookup[agent].to_pay_hourly_holiday/60).toFixed(0)*60
                let to_pay_hourly_holiday_overtime = payrollsLookup[agent].to_pay_hourly_holiday_overtime //(payrollsLookup[agent].to_pay_hourly_holiday_overtime/60).toFixed(0)*60
                payrollsLookup[agent].hourly_payment = to_pay_hourly * rate + to_pay_overtime * rate * 2 + to_pay_hourly_holiday * rate * 1.5 + to_pay_hourly_holiday_overtime * rate * 2
                // payrollsLookup[agent].debt = (last_agent_paid_lookup[agent].remaining_debt/100).toFixed(2)
                payrollsLookup[agent].final_payment = payrollsLookup[agent].hourly_payment + payrollsLookup[agent].commission
                if (payrollsLookup[agent].type === AGENT_TYPE_EMPLOYEE) {
                    for (let payroll_day of Object.keys(payrollsLookup[agent]['by_day'])) {
                        if (getters.getHolidayByDate(payroll_day) && (moment(payroll_day) > moment(last_agent_paid_lookup[agent]) && moment(payroll_day) <= moment(getters.getPayrollDate))) {
                            for (let day2 of Object.keys(payrollsLookupForHoliday[agent]['by_day'])) {
                                if (moment(day2).isBefore(moment(payroll_day).add(1, 'day')) && moment(day2).isAfter(moment(payroll_day).subtract(30, 'day'))) {
                                    payrollsLookup[agent]['by_day'][payroll_day]['holiday_bonus'] += payrollsLookupForHoliday[agent]['by_day'][day2]
                                }
                            }
                            payrollsLookup[agent]['by_day'][payroll_day]['holiday_bonus'] = Math.floor(payrollsLookup[agent]['by_day'][payroll_day]['holiday_bonus'] / 30) * rate  //average per 30 days
                            payrollsLookup[agent]['holiday_bonus'] += payrollsLookup[agent]['by_day'][payroll_day]['holiday_bonus']
                        }
                    }
                }
                for (let day of Object.keys(payrollsLookup[agent]['by_day'])) {
                    payrollsLookup[agent]['by_day'][day]['hourly_payment'] = payrollsLookup[agent]['by_day'][day]['to_pay_hourly'] * rate +
                        payrollsLookup[agent]['by_day'][day]['to_pay_overtime'] * rate * 2 +
                        payrollsLookup[agent]['by_day'][day]['to_pay_hourly_holiday'] * rate * 1.5 +
                        payrollsLookup[agent]['by_day'][day]['to_pay_hourly_holiday_overtime'] * rate * 2
                }
                payrollsLookup[agent].final_payment = payrollsLookup[agent].hourly_payment + payrollsLookup[agent].commission + payrollsLookup[agent].holiday_bonus
            }

            if (getters.isAgent) {
                calculatedPayrollsData['earned_hourly'] = payrollsLookup[getters.getCurrentUserEmail].hourly_payment
                calculatedPayrollsData['earned_statutory'] = payrollsLookup[getters.getCurrentUserEmail].holiday_bonus
                calculatedPayrollsData['earned_commissions'] = payrollsLookup[getters.getCurrentUserEmail].commission
                calculatedPayrollsData['already_paid'] = getters.alreadyPaid
                calculatedPayrollsData['to_be_paid'] = calculatedPayrollsData['earned_hourly'] + calculatedPayrollsData['earned_statutory'] + calculatedPayrollsData['earned_commissions'] + getters.lastDebt
            }

            commit('SET_CALCULATED_PRODUCTIVITY_DATA', Object.freeze(calculatedData))
            commit('SET_CALCULATED_AGENTS_STATS', Object.freeze(agents_stat))
            commit('SET_PAYROLLS', Object.values(payrollsLookup))
            commit('SET_CALCULATED_PAYROLL_DATA', calculatedPayrollsData)
            commit('STOP_PRODUCTIVITY_LOADING')
            commit('SET_INITIAL_PRODUCTIVITY_LOADED')
        }
    },
    getters: {
        getStartTime: state => state.startTime,
        getEndTime: state => state.endTime,
        getCalculatedAgentsStats: state => state.calculatedAgentsStats,
        getSelectedAgents: state => state.productivitySelectedAgents,
        getProductivityAxisNormal: (state) => state.calculatedProductivityData.xAxisNormal,
        productivityTotalGraphOptions: (state, getters) => {
            return {
                chart: {
                    animations: {
                        enabled: false
                    },
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: [
                    "rgb(116, 90, 242)",
                    "rgb(30,136,229)",
                    "#06d79c",
                    "#ef5350",
                ],
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: getters.getProductivityAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },


                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                    y: {
                        formatter: (value) => value.toLocaleString() + " min",
                    },
                },
            }
        },
        productivityBillableWorkGraphOptions: (state, getters) => {
            return {
                chart: {
                    animations: {
                        enabled: false
                    },
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: [
                    "#ef5350",
                    "#E57373",
                    "#EF9A9A",
                    "#FFCDD2",
                ],
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: getters.getProductivityAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                    y: {
                        formatter: (value) => value.toLocaleString() + " min",
                    },
                },
            }
        },
        productivityUnproductiveGraphOptions: (state, getters) => {
            return {
                chart: {
                    animations: {
                        enabled: false
                    },
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: [
                    "#1E88E5",
                    "#42A5F5",
                    "#64B5F6",
                    "#90CAF9",
                ],
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: getters.getProductivityAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },


                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                    y: {
                        formatter: (value) => value.toLocaleString() + " min",
                    },
                },
            }
        },
        productivityProductiveGraphOptions: (state, getters) => {
            return {
                chart: {
                    animations: {
                        enabled: false
                    },
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: random_color_generator(getters.productivityProductiveGraphSeries.length, 'bright', 1221),
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: getters.getProductivityAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                    y: {
                        formatter: (value) => value.toLocaleString() + " min",
                    },
                },

            }
        },
        totalLoginTime: (state, getters) => {
            return sumToHierarchy.reduce((accum, item) => {
                return accum + getters.getCalculatedProductivityData[item].reduce((acc, item) => acc + item, 0)
            }, 0).toLocaleString() + " min"
        },
        productivityTotalGraphSeries: (state, getters) => {
            return [
                {
                    name: "Billable Work",
                    data: getters.getCalculatedProductivityData['Billable Work']
                },
                {
                    name: "Productive",
                    data: getters.getCalculatedProductivityData['Productive']
                },

                {
                    name: "Unproductive",
                    data: getters.getCalculatedProductivityData['Unproductive']
                },
                {
                    name: "Unclassified",
                    data: getters.getCalculatedProductivityData['Unclassified']
                },
            ]
        },
        productivityBillableWorkGraphSeries: (state, getters) => {
            return [
                {
                    name: "On Call",
                    data: getters.getCalculatedProductivityData['On Call']
                },
                {
                    name: "After Work",
                    data: getters.getCalculatedProductivityData['After Work']
                },
                {
                    name: "Emails and Tickets",
                    data: getters.getCalculatedProductivityData['Emails and Tickets']
                },
                {
                    name: "Outbound Sales Calls",
                    data: getters.getCalculatedProductivityData['Outbound Sales Calls']
                },
            ]
        },
        productivityUnproductiveGraphSeries: (state, getters) => {
            return [
                {
                    name: "Breaks",
                    data: getters.getCalculatedProductivityData['Breaks'],
                },
                {
                    name: "Ending Shift",
                    data: getters.getCalculatedProductivityData['Ending Shift'],
                },
                {
                    name: "System",
                    data: getters.getCalculatedProductivityData['System'],
                },
                {
                    name: "Meal",
                    data: getters.getCalculatedProductivityData['Meal'],
                },
                {
                    name: "No Reason",
                    data: getters.getCalculatedProductivityData['No Reason'],
                },
                {
                    name: "Personal",
                    data: getters.getCalculatedProductivityData['Personal'],
                },
                {
                    name: "Not Ready",
                    data: getters.getCalculatedProductivityData['Not Ready'],
                },
            ]
        },
        productivityProductiveGraphSeries: (state, getters) => {
            let productivitySeries = [
                {
                    name: "Ready Time",
                    data: getters.getCalculatedProductivityData['Ready Time'],
                },
                {
                    name: "Administrative",
                    data: getters.getCalculatedProductivityData['Administrative'],
                },
                {
                    name: "Audit",
                    data: getters.getCalculatedProductivityData['Audit'],
                },
                {
                    name: "Escalation",
                    data: getters.getCalculatedProductivityData['Escalation'],
                },
                {
                    name: "Follow Up Work",
                    data: getters.getCalculatedProductivityData['Follow Up Work'],
                },
                {
                    name: "Meeting",
                    data: getters.getCalculatedProductivityData['Meeting'],
                },
                {
                    name: "On Voicemails",
                    data: getters.getCalculatedProductivityData['On Voicemails'],
                },
                {
                    name: "QA Monitoring",
                    data: getters.getCalculatedProductivityData['QA Monitoring'],
                },
                {
                    name: "QA Training",
                    data: getters.getCalculatedProductivityData['QA Training'],
                },
                {
                    name: "Task Completion",
                    data: getters.getCalculatedProductivityData['Task Completion'],
                },
                {
                    name: "Training",
                    data: getters.getCalculatedProductivityData['Training'],
                },
                {
                    name: "Team Meeting",
                    data: getters.getCalculatedProductivityData['Team Meeting'],
                },
            ]
            return productivitySeries.filter((series) => {
                return series.data.reduce((a, b) => a + b, 0) > 0
            })
        },
        getCalculatedProductivityData: (state) => state.calculatedProductivityData,
        getInitialProductivityLoaded: state => state.initial_productivity_loaded,
        getEarnedHourly: state => state.calculatedPayrollsData.earned_hourly,
        getEarnedStatutory: state => state.calculatedPayrollsData.earned_statutory,
        getEarnedCommissions: state => state.calculatedPayrollsData.earned_commissions,
        getToBePaid: state => state.calculatedPayrollsData.to_be_paid,
        getPayrolls: state => {
            return state.payrolls
        }
    },
};
