import Vue from 'vue';
import Vuex from "vuex";
import authStore from "./auth_store";
import campaignListStore from "./campaign_lists_store";
import campaignStore from "./campaigns_store";
import dashboardsStore from "./dashboards_store";
import dispositionsStore from "./dispositions_store";
import dispositionsBucketStore from "./dispositions_buckets_store";
import usersStore from "./users_store";
import errorsStore from "./errors_store";
import actionsAnimationsStore from "./actions_animations_store";
import productivityStore from "./productivity_store"
import agentsStore from "./agents_store"
import callsStore from "./calls_store"
import personsSalesStore from "./persons_sales_store"
import reportsStore from "./reports_store"
import holidaysStore from "./holidays_store"
import keysStore from "./keys_store"
import adminPaymentsStore from "./admin_payments_store"
import agentPaymentsStore from "./agent_payments_store"
import commissionsStore from "./commissions_store"
import payrollsStore from "./payrolls_store"
import clientsStore from "./clients_store"
import crmStore from "./crm_store"
import currency_adjustment_store from "./currency_adjustment_store";
import {
    PAGE_NAME_DASHBOARDS,
    PAGE_NAME_PRODUCTIVITY,
    PAGE_NAME_CALLS,
    PAGE_NAME_PERSONS_SALES,
    PAGE_NAME_REPORTS,
    PAGE_NAME_PAYROLLS
} from "../constants";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        initialized: false,
        navigate_started: false,
        current_page_name: "",
        dashboard_loading: false,
        productivity_loading: false,

    },
    mutations: {
        INITIALIZE_COMPLETED: state => state.initialized = true,
        INITIALIZE_RESET: state => state.initialized = false,
        NAVIGATE_STARTED: state => state.navigate_started = true,
        NAVIGATE_ENDED: state => state.navigate_started = false,
        SET_CURRENT_PAGE_NAME: (state, value) => state.current_page_name = value,
        START_DASHBOARD_LOADING: (state) => state.dashboard_loading = true,
        STOP_DASHBOARD_LOADING: (state) => state.dashboard_loading = false,
        START_PRODUCTIVITY_LOADING: (state) => state.productivity_loading = true,
        STOP_PRODUCTIVITY_LOADING: (state) => state.productivity_loading = false,
    },
    actions: {
        setCurrentPageName: ({commit}, name) => commit("SET_CURRENT_PAGE_NAME", name),
        loadDashboards: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                commit("START_DASHBOARD_LOADING")
                commit("START_TOP_GRAPH_LOADING")
                dispatch("initializeDashboards").then(() => {
                    commit("STOP_DASHBOARD_LOADING")
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    commit("STOP_DASHBOARD_LOADING")
                    reject()
                })
                dispatch('loadRefundsCancelsFirst')
                dispatch('loadRefundsCancelsSecond')
                dispatch("initializeDashboardsTopGraph").then(() => {
                    commit("STOP_TOP_GRAPH_LOADING")
                }).catch((e) => {
                    dispatch('processError', e)
                    commit("STOP_TOP_GRAPH_LOADING")
                })
            })
        },
        loadProductivity: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                commit("START_PRODUCTIVITY_LOADING")
                dispatch("initializeProductivity").then(() => {
                    commit("STOP_PRODUCTIVITY_LOADING")
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    commit("STOP_PRODUCTIVITY_LOADING")
                    reject()
                })
            })
        },
        loadProductivityForPayrolls: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                commit("START_PRODUCTIVITY_LOADING")
                dispatch("initializeProductivityForPayrolls").then(() => {
                    commit("STOP_PRODUCTIVITY_LOADING")
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    commit("STOP_PRODUCTIVITY_LOADING")
                    reject()
                })
            })
        },
        navigateStarted: ({commit}) => commit("NAVIGATE_STARTED"),
        navigateEnded: ({commit}) => commit("NAVIGATE_ENDED"),
        initialize: ({dispatch, commit, getters}) => {
            dispatch('resetError')
            return new Promise((resolve, reject) => {
                dispatch("initializeAuth").then(() => {
                    Promise.all([
                        dispatch("initializeCampaigns"),
                        dispatch("initializeClients"),
                        dispatch("initializeDispositions"),
                        dispatch("initializeCampaignLists"),
                        dispatch("initializeDispositionBuckets"),
                        dispatch("initializeUsers"),
                    ]).then(() => {
                        dispatch('updateTimezone', getters.getCurrentUserTimezone)
                        if (getters.getCurrentUserCampaigns.length) {
                            dispatch('updateSelectedCampaigns', getters.getCurrentUserCampaigns.filter((item) => {
                                let campaign = getters.getCampaignByID(item)
                                if (campaign.archived == true){
                                    return false
                                }
                                return true
                            }))
                        } else {
                            dispatch('updateSelectedCampaigns', getters.getAllActiveCampaigns.map((item) => item.id))
                        }
                        dispatch('setInitialDates');
                        document.getElementById('loader').style.display = 'none'
                        commit("INITIALIZE_COMPLETED")
                        resolve()
                    }).catch((e) => {
                        document.getElementById('loader').style.display = 'none'
                        dispatch('processError', e)
                        commit("INITIALIZE_RESET")
                        reject()
                    })
                }).catch(() => {
                    document.getElementById('loader').style.display = 'none'
                    //dispatch('processError', "Can't get user information")
                    reject()
                })
            })
        }
    },
    getters: {
        isInitialized: state => state.initialized,
        navigatingPage: state => state.navigate_started,
        loadingState: (state) => state.dashboard_loading || state.productivity_loading,
        dashboardReloadNeeded: (state) => state.current_page_name == PAGE_NAME_DASHBOARDS,
        productivityReloadNeeded: (state) => state.current_page_name == PAGE_NAME_PRODUCTIVITY || state.current_page_name == PAGE_NAME_PAYROLLS,
        callsReloadNeeded: (state) => state.current_page_name == PAGE_NAME_CALLS,
        personsSalesReloadNeeded: (state) => state.current_page_name == PAGE_NAME_PERSONS_SALES,
        reportsReloadNeeded: (state) => state.current_page_name == PAGE_NAME_REPORTS,
        getCurrentPageName: (state) => state.current_page_name

    },
    modules: {
        authStore,
        campaignListStore,
        campaignStore,
        dashboardsStore,
        dispositionsStore,
        usersStore,
        errorsStore,
        actionsAnimationsStore,
        dispositionsBucketStore,
        productivityStore,
        agentsStore,
        callsStore,
        personsSalesStore,
        reportsStore,
        holidaysStore,
        keysStore,
        adminPaymentsStore,
        agentPaymentsStore,
        commissionsStore,
        payrollsStore,
        clientsStore,
        crmStore,
        currency_adjustment_store,
    }
});
