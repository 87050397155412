import {listCampaignLists} from "../graphql/queries";
import {createCampaignList, updateCampaignList, deleteCampaignList} from "../graphql/mutations";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
    executeGraphQLMutationUpdate,
    fetchItemsNextTokenGraphQL
} from "@/utils/query_all_utils";

export default {
    state: {
        campaign_lists: []
    },
    mutations: {
        SET_CAMPAIGN_LISTS: (state, campaign_lists) => state.campaign_lists = Object.freeze(campaign_lists),
    },
    actions: {
        setCampaignLists: ({commit}, campaign_lists) => {
            for (let list of campaign_lists) {
                list.campaigns = JSON.parse(list.campaigns.replaceAll('`', '"'))
            }
            commit('SET_CAMPAIGN_LISTS', campaign_lists)
        },
        initializeCampaignLists: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listCampaignLists).then((items) => {
                    dispatch('setCampaignLists', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateCampaignList({dispatch}, campaign_list) {
            var campaign_list_escapted = {...campaign_list}
            campaign_list_escapted.campaigns = JSON.stringify(campaign_list_escapted.campaigns).replaceAll('"', '`')
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationUpdate(updateCampaignList, {updateCampaignListInput: campaign_list_escapted}).then(() => {
                        dispatch('initializeCampaignLists').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        removeCampaignList({dispatch}, campaignListID) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteCampaignList, {id: campaignListID}).then(() => {
                        dispatch('initializeCampaignLists').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addCampaignList({dispatch}, campaign_list) {
            var campaign_list_escapted = {...campaign_list}
            campaign_list_escapted.campaigns = JSON.stringify(campaign_list_escapted.campaigns).replaceAll('"', '`')
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationCreate(createCampaignList, {createCampaignListInput: campaign_list_escapted}).then(() => {
                        dispatch('initializeCampaignLists').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getAllCampaignLists: state => state.campaign_lists,
        getCampaignGroupNameByCampaignName: (state, getters) => (campaignName) => {
            let campaignId = getters.getCampaignIdByName(campaignName).id
            let campaignGroup = state.campaign_lists.find(campaignList => (campaignList.campaigns.length !== 0 && campaignList.campaigns.includes(campaignId)))
            return campaignGroup ? campaignGroup.name : 'Others (Not In List)'
        },
    },
};
