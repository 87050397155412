import {listClientCRM} from "../graphql/queries";
import {
    createClientCRM,
    updateClientCRM,
    deleteClientCRM,
} from "../graphql/mutations";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationUpdate,
    executeGraphQLMutationDelete
} from "@/utils/query_all_utils";


export default {
    state: {
        clients: []
    },
    mutations: {
        SET_CLIENTS: (state, clients) => state.clients = Object.freeze(clients)
    },
    actions: {
        setClients: ({commit}, clients) => {
            clients.forEach((client) => {
                if (client['filter']) {
                    client['filter'] = client['filter'].replaceAll("`", "'")
                }
                if (client['purchases_filter']) {
                    client['purchases_filter'] = client['purchases_filter'].replaceAll("`", "'")

                }

            })
            commit('SET_CLIENTS', clients)
        },
        initializeClients: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listClientCRM).then((items) => {
                    dispatch('setClients', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateClient({dispatch}, client) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    if (client['filter']) {
                        client['filter'] = client['filter'].replaceAll("'", "`")
                    }
                    if (client['purchases_filter']) {
                        client['purchases_filter'] = client['purchases_filter'].replaceAll("'", "`")
                    }
                    executeGraphQLMutationUpdate(updateClientCRM, {updateClientCRMInput: client}).then(() => {
                        dispatch('initializeClients').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        removeClient({dispatch}, item) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteClientCRM, {id: item.id, name: item.name}).then(() => {
                        dispatch('initializeClients').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addClient({dispatch}, client) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationCreate(createClientCRM, {createClientCRMInput: client}).then(() => {
                        if (client['filter']) {
                            client['filter'] = client['filter'].replaceAll("'", "`")
                        }
                        if (client['purchases_filter']) {
                            client['purchases_filter'] = client['purchases_filter'].replaceAll("'", "`")
                        }
                        dispatch('initializeClients').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        crmClientFilter: (state, getters) => (item) => {
            let client = getters.getClientByName(item.clientName)
            if (client.filter) {
                return eval(client.filter)
            }
            return 1
        },
        crmClientPurchaseFilter: (state, getters) => (item) => {
            let client = getters.getClientByName(item.clientName)
            if (client.purchases_filter) {
                return eval(client.purchases_filter)
            }
            return 1
        },
        getAllClients: state => state.clients,
        getClientByID: state => (id) => state.clients.find((item) => item.id == id),
        getClientByName: state => (name) => state.clients.find((item) => item.name == name),
    },
};
