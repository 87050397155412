import {API, graphqlOperation} from "aws-amplify";
import {getCallRecordForCommission, listCallRecords} from "../graphql/queries";
import {fetchItemsNextTokenGraphQL} from "../utils/query_all_utils";

const moment = require('moment-timezone')

moment.tz.setDefault('America/Vancouver')


export default {
    state: {
        calls: [],
        call_to_find: undefined,
        calls_per_page: 20,
        next_available: false,
        page: 1,
        allCallsEnabled: false
    },
    mutations: {
        SET_ALL_CALLS_ENABLED: (state, value) => state.allCallsEnabled = value,
        SET_CALLS: (state, calls) => state.calls = Object.freeze(calls),
        UPDATE_CALL_TO_FIND: (state, id) => state.call_to_find = id,
        UPDATE_CALLS_PER_PAGE: (state, amount) => state.calls_per_page = amount,
        SET_NEXT_AVAILABLE: (state, next_available) => state.next_available = next_available,
        SET_PAGE: (state, page) => state.page = page,
    },
    actions: {
        setAllCallsEnabled: ({commit, dispatch}, value) => {
          commit('SET_ALL_CALLS_ENABLED', value)
          dispatch('loadCalls')
        },
        setCalls: ({commit}, calls) => {
            for (var call of calls) {
                let capped_ivr_time = parseInt(call['IVR_TIME']) < 300 ? parseInt(call['IVR_TIME']) : 300
                let capped_ivr_time_3m = parseInt(call['IVR_TIME']) < 180 ? parseInt(call['IVR_TIME']) : 180
                let capped_ivr_time_1m = parseInt(call['IVR_TIME']) < 60 ? parseInt(call['IVR_TIME']) : 60
                let capped_ivr_time_2m = parseInt(call['IVR_TIME']) < 120 ? parseInt(call['IVR_TIME']) : 120
                call['CALCULATED_QUEUE_WAIT_TIME'] = parseInt(call['QUEUE_WAIT_TIME'])
                if (call['CALCULATED_QUEUE_WAIT_TIME'] > 600)
                    call['CALCULATED_QUEUE_WAIT_TIME'] = 600
                switch (call["TIER"]) {
                    case 0:
                        call['CALCULATED_CALL_TIME'] = 0
                        break;
                    case 1:
                    case 11:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME']) + parseInt(call['IVR_TIME']) + parseInt(call['HOLD_TIME'])
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 2:
                    case 12:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME'])
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 3:
                    case 13:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME'])
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 4:
                    case 14:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME'])
                        if (call['CALCULATED_CALL_TIME'] > 900)
                            call['CALCULATED_CALL_TIME'] = 900
                        break;
                    case 5:
                    case 15:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME']) + capped_ivr_time
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 6:
                    case 16:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME'])
                        break;
                    case 7:
                    case 17:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME']) + capped_ivr_time_3m
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 8:
                    case 18:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME']) + capped_ivr_time_1m
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                    case 9:
                    case 19:
                        call['CALCULATED_CALL_TIME'] = parseInt(call['TALK_TIME']) + parseInt(call['AFTER_CALL_WORK_TIME']) + capped_ivr_time_2m
                        if (call['CALCULATED_CALL_TIME'] > 1200)
                            call['CALCULATED_CALL_TIME'] = 1200
                        break;
                }
            }
            commit('SET_CALLS', calls)
        },
        updateCallToFind: ({commit}, id) => {
            commit('UPDATE_CALL_TO_FIND', id)
        },
        updateCallsPerPage: ({commit}, amount) => {
            commit('UPDATE_CALLS_PER_PAGE', amount)
        },
        setNextAvailable: ({commit}, next_available) => {
            commit('SET_NEXT_AVAILABLE', next_available)
        },
        resetCalls: ({commit, dispatch}) => {
            commit('SET_PAGE', 1)
            commit('SET_NEXT_AVAILABLE', false)
            dispatch('setCalls', [])
        },
        loadNextPageCalls({dispatch, commit, state}) {
            commit('SET_PAGE', state.page + 1)
            dispatch('loadCalls')
        },
        loadCalls({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                let lowest_date = moment.utc((getters.getDashboardMainDates[0])).startOf('day')
                let highest_date = moment.utc((getters.getDashboardMainDates[1])).endOf('day')
                let lowest_dp_index = Math.floor(lowest_date.unix())
                let highest_dp_index = Math.ceil(highest_date.unix())
                if (getters.getCurrentUserDisplayFrom) {
                    let min_data_point = Math.floor(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day').unix())
                    if (lowest_dp_index < min_data_point) {
                        lowest_dp_index = min_data_point
                    }
                    if (highest_dp_index < min_data_point) {
                        highest_dp_index = min_data_point
                    }
                }
                let filter = {
                    lowest: lowest_dp_index,
                    highest: highest_dp_index,
                    LIMIT: !getters.getAllCallsEnabled ? getters.getCallsPerPage + 1 : 1000000000,
                    OFFSET: !getters.getAllCallsEnabled ? getters.getCallsPerPage * (getters.getPage - 1) : 0,
                    orderBy: "TIMESTAMP_REAL"
                }

                filter['CALL_ID_QUERY'] = getters.callToFind ? "CALL_ID = '" + getters.callToFind + "'" : "1 = 1"

                if (getters.selectedCampaigns.length > 0) {
                    filter['campaign'] = []
                    for (let campaign of getters.selectedCampaigns) {
                        let campaign_record = getters.getCampaignByID(campaign)
                        if (campaign_record) {
                            filter['campaign'].push("'" + campaign_record.name + "'")
                        }
                    }
                    filter['campaign'] = "(" + filter['campaign'].join(",") + ")"
                } else {
                    dispatch('setCalls', [])
                    dispatch('setLoadedCalls', [])
                    dispatch('setNextAvailable', false)
                    return
                }
                dispatch('setNextAvailable', false)

                if (getters.getAllCallsEnabled) {
                    commit('NAVIGATE_STARTED')
                    fetchItemsNextTokenGraphQL(listCallRecords, {...filter}).then((items) => {
                        var filtered_calls = []
                        for (var item of items) {
                            item['TIER'] = getters.getCampaignByName(item['CAMPAIGN']).tier
                            if (!(parseInt(item['TALK_TIME'] === 0 && [11, 12, 13, 14, 15, 16, 17, 18, 19].includes(item['TIER'])))) {
                                if (item['TIER'] != 6 || parseInt(item['TALK_TIME']) >= 60) {
                                    filtered_calls.push(item)
                                }
                            }
                        }
                        commit('NAVIGATE_ENDED')
                        dispatch('setCalls', filtered_calls)
                        resolve()
                    }).catch((e) => {
                        dispatch('processError', e)
                        commit('NAVIGATE_ENDED')
                        reject(e)
                    })
                } else {
                    API.graphql(graphqlOperation(listCallRecords, {...filter}))
                        .then((response) => {
                            var filtered_calls = []
                            for (var item of response.data.listCallRecords) {
                                item['TIER'] = getters.getCampaignByName(item['CAMPAIGN']).tier
                                if (!(parseInt(item['TALK_TIME'] === 0 && [11, 12, 13, 14, 15, 16, 17, 18, 19].includes(item['TIER'])))) {
                                    if (item['TIER'] != 6 || parseInt(item['TALK_TIME']) >= 60) {
                                        filtered_calls.push(item)
                                    }
                                }
                            }
                            if (filtered_calls.length > getters.getCallsPerPage) {
                                dispatch('setNextAvailable', true)
                                dispatch('setCalls', filtered_calls.slice(0, filtered_calls.length - 1))
                            } else {
                                dispatch('setNextAvailable', false)
                                dispatch('setCalls', filtered_calls)
                            }
                            resolve()
                        }).catch((e) => {
                        dispatch('processError', e)
                        commit('NAVIGATE_ENDED')
                        reject(e)
                    })
                }
            })
        },
        getCallRecordByCallID({dispatch}, id) {
            return new Promise((resolve, reject) => {
                API.graphql(graphqlOperation(getCallRecordForCommission, {CALL_ID: id}))
                    .then((response) => {
                        var call = response.data.getCallRecordForCommission
                        resolve(call)
                    }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
    },
    getters: {
        getAllCalls: state => state.calls,
        callToFind: state => state.call_to_find,
        getCallsPerPage: state => state.calls_per_page,
        getPage: state => state.page,
        getNextAvailable: state => state.next_available,
        getAllCallsEnabled: state => state.allCallsEnabled
    }


}
