import {API, graphqlOperation} from "aws-amplify";
import {listPersonsSale, getCustomers, getNumberDisplayTime} from "../graphql/queries";
import {updatePersonsSale, updateNumberDisplayTime} from "../graphql/mutations";
import {fetchItemsNextTokenGraphQL} from "../utils/query_all_utils";

const moment = require('moment-timezone')

moment.tz.setDefault('America/Vancouver')

export default {
    state: {
        numberDisplayTime: null,
        customers: [],
        personsSales: [],
        personsSalesStatusSale: [],
        personsSalesOtherStatuses: [],
        persons_sales_to_find: undefined,
        persons_sales_to_find_status_sale: undefined,
        persons_sales_to_find_other_statuses: undefined,
        personsSales_per_page: 250,
        personsSales_per_page_status_sale: 250,
        personsSales_per_page_other_statuses: 250,
        next_available: false,
        next_available_status_sale: false,
        next_available_other_statuses: false,
        page: 1,
        pageSale: 1,
        pageOtherStatuses: 1,
        allPersonsSalesEnabled: false,
        allPersonsSalesEnabledStatusSale: false,
        allPersonsSalesEnabledOtherStatuses: false,
        filterCustomer: undefined,
        isLoadingPersonsSales: false,
        isLoadingPersonsSalesStatusSale: false,
        isLoadingPersonsSalesOtherStatuses: false,
    },
    mutations: {
        SET_IS_LOADING_PERSONS_SALES: (state, value) => state.isLoadingPersonsSales = value,
        SET_IS_LOADING_PERSONS_SALES_STATUS_SALE: (state, value) => state.isLoadingPersonsSalesStatusSale = value,
        SET_IS_LOADING_PERSONS_SALES_OTHER_STATUSES: (state, value) => state.isLoadingPersonsSalesOtherStatuses = value,

        SET_NUMBER_DISPLAY_TIME: (state, value) => state.numberDisplayTime = value,
        SET_FILTER_CUSTOMER: (state, value) => state.filterCustomer = value,
        SET_CUSTOMERS: (state, customers) => state.customers = customers,
        SET_ALL_PERSONS_SALES_ENABLED: (state, value) => state.allPersonsSalesEnabled = value,
        SET_ALL_PERSONS_SALES_ENABLED_STATUS_SALE: (state, value) => state.allPersonsSalesEnabledStatusSale = value,
        SET_ALL_PERSONS_SALES_ENABLED_OTHER_STATUSES: (state, value) => state.allPersonsSalesEnabledOtherStatuses = value,
        SET_PERSONS_SALES: (state, personsSales) => state.personsSales = Object.freeze(personsSales),
        SET_PERSONS_SALES_STATUS_SALE: (state, personsSalesStatusSale) => state.personsSalesStatusSale = Object.freeze(personsSalesStatusSale),
        SET_PERSONS_SALES_OTHER_STATUSES: (state, personsSalesOtherStatuses) => state.personsSalesOtherStatuses = Object.freeze(personsSalesOtherStatuses),
        UPDATE_PERSONS_SALES_TO_FIND: (state, id) => state.persons_sales_to_find = id,
        UPDATE_PERSONS_SALES_TO_FIND_STATUS_SALE: (state, id) => state.persons_sales_to_find_status_sale = id,
        UPDATE_PERSONS_SALES_TO_FIND_OTHER_STATUSES: (state, id) => state.persons_sales_to_find_other_statuses = id,
        UPDATE_PERSONS_SALES_PER_PAGE: (state, amount) => state.personsSales_per_page = amount,
        UPDATE_PERSONS_SALES_PER_PAGE_STATUS_SALE: (state, amount) => state.personsSales_per_page_status_sale = amount,
        UPDATE_PERSONS_SALES_PER_PAGE_OTHER_STATUSES: (state, amount) => state.personsSales_per_page_other_statuses = amount,
        SET_NEXT_AVAILABLE: (state, next_available) => state.next_available = next_available,
        SET_NEXT_AVAILABLE_STATUS_SALE: (state, next_available_status_sale) => state.next_available_status_sale = next_available_status_sale,
        SET_NEXT_AVAILABLE_OTHER_STATUSES: (state, next_available_other_statuses) => state.next_available_other_statuses = next_available_other_statuses,
        SET_PAGE: (state, page) => state.page = page,
        SET_PAGE_SALE: (state, pageSale) => state.pageSale = pageSale,
        SET_PAGE_OTHER_STATUSES: (state, pageOtherStatuses) => state.pageOtherStatuses = pageOtherStatuses,
    },
    actions: {
        setIsLoadingPersonsSales: ({commit}, value) => {
            commit('SET_IS_LOADING_PERSONS_SALES', value)
        },
        setIsLoadingPersonsSalesStatusSale: ({commit}, value) => {
            commit('SET_IS_LOADING_PERSONS_SALES_STATUS_SALE', value)
        },        
        setIsLoadingPersonsSalesOtherStatuses: ({commit}, value) => {
            commit('SET_IS_LOADING_PERSONS_SALES_OTHER_STATUSES', value)
        },
        setFilterCustomer: ({commit, dispatch}, value) => {
            commit('SET_FILTER_CUSTOMER', value)
            dispatch('loadPersonsSales')
            dispatch('loadPersonsSalesStatusSale')
            dispatch('loadPersonsSalesOtherStatuses')
        },
        setCustomers: ({commit}, customers) => {
            commit('SET_CUSTOMERS', customers)
        },
        getCustomers({dispatch}) {
            API.graphql(graphqlOperation(getCustomers)).then((response) => {
                const allCustomers = response.data.getCustomers
                dispatch('setCustomers', allCustomers)
            })
        },
        setAllPersonsSalesEnabled: ({commit, dispatch}, value) => {
            commit('SET_ALL_PERSONS_SALES_ENABLED', value)
            dispatch('loadPersonsSales')
        },
        setAllPersonsSalesEnabledStatusSale: ({commit, dispatch}, value) => {
            commit('SET_ALL_PERSONS_SALES_ENABLED_STATUS_SALE', value)
            dispatch('loadPersonsSalesStatusSale')
        },
        setAllPersonsSalesEnabledOtherStatuses: ({commit, dispatch}, value) => {
            commit('SET_ALL_PERSONS_SALES_ENABLED_OTHER_STATUSES', value)
            dispatch('loadPersonsSalesOtherStatuses')
        },
        setNumberDisplayTime: ({commit}, numberDisplayTime) => {
            commit('SET_NUMBER_DISPLAY_TIME', numberDisplayTime)
        },
        setPersonsSales: ({commit}, personsSales) => {
            const filteredPersonsSales = personsSales.filter(personsSale => {
                if (personsSale.CustomerName === 'GSC')
                {
                    if (personsSale.Source == 'BuyGoods') {//} && parseInt(personsSale.PhoneNumber) % 2 === 0) {
                        return false
                    }
                }
                else if (personsSale.CustomerName === 'Living Good - CheckoutChamp') {
                    if (personsSale.Additional4 === 'COMPLETE') {
                        return false
                    }
                }
                return true
            });
            commit('SET_PERSONS_SALES', filteredPersonsSales)
        },
        setPersonsSalesStatusSale: ({commit}, personsSalesStatusSale) => {
            for (let personsSale of personsSalesStatusSale) {
                if (personsSale.Sales === '') {
                    personsSale.Sales = []
                } else {
                    personsSale.Sales = JSON.parse(personsSale.Sales.replaceAll('`', '"'))
                }
            }
            commit('SET_PERSONS_SALES_STATUS_SALE', personsSalesStatusSale)
        },
        setPersonsSalesOtherStatuses: ({commit}, personsSalesOtherStatuses) => {
            for (let personsSale of personsSalesOtherStatuses) {
                if (personsSale.Sales === '') {
                    personsSale.Sales = []
                } else {
                    personsSale.Sales = JSON.parse(personsSale.Sales.replaceAll('`', '"'))
                }
            }
            commit('SET_PERSONS_SALES_OTHER_STATUSES', personsSalesOtherStatuses)
        },
        updatePersonsSalesToFind: ({commit}, id) => {
            commit('UPDATE_PERSONS_SALES_TO_FIND', id)
        },
        updatePersonsSalesToFindStatusSale: ({commit}, id) => {
            commit('UPDATE_PERSONS_SALES_TO_FIND_STATUS_SALE', id)
        },
        updatePersonsSalesToFindOtherStatuses: ({commit}, id) => {
            commit('UPDATE_PERSONS_SALES_TO_FIND_OTHER_STATUSES', id)
        },
        updatePersonsSalesPerPage: ({commit}, amount) => {
            commit('UPDATE_PERSONS_SALES_PER_PAGE', amount)
        },
        updatePersonsSalesPerPageStatusSale: ({commit}, amount) => {
            commit('UPDATE_PERSONS_SALES_PER_PAGE_STATUS_SALE', amount)
        },
        updatePersonsSalesPerPageOtherStatuses: ({commit}, amount) => {
            commit('UPDATE_PERSONS_SALES_PER_PAGE_OTHER_STATUSES', amount)
        },
        setNextAvailable: ({commit}, next_available) => {
            commit('SET_NEXT_AVAILABLE', next_available)
        },
        setNextAvailableStatusSale: ({commit}, next_available_status_sale) => {
            commit('SET_NEXT_AVAILABLE_STATUS_SALE', next_available_status_sale)
        },
        setNextAvailableOtherStatuses: ({commit}, next_available_other_statuses) => {
            commit('SET_NEXT_AVAILABLE_OTHER_STATUSES', next_available_other_statuses)
        },
        resetPersonsSales: ({commit, dispatch}) => {
            commit('SET_PAGE', 1)
            commit('SET_NEXT_AVAILABLE', false)
            dispatch('setPersonsSales', [])
        },
        resetPersonsSalesStatusSale: ({commit, dispatch}) => {
            commit('SET_PAGE_SALE', 1)
            commit('SET_NEXT_AVAILABLE_STATUS_SALE', false)
            dispatch('setPersonsSalesStatusSale', [])
        },
        resetPersonsSalesOtherStatuses: ({commit, dispatch}) => {
            commit('SET_PAGE_OTHER_STATUSES', 1)
            commit('SET_NEXT_AVAILABLE_OTHER_STATUSES', false)
            dispatch('setPersonsSalesOtherStatuses', [])
        },
        loadNextPagePersonsSales({dispatch, commit, state}) {
            commit('SET_PAGE', state.page + 1)
            dispatch('loadPersonsSales')
        },
        loadNextPagePersonsSalesStatusSale({dispatch, commit, state}) {
            commit('SET_PAGE_SALE', state.pageSale + 1)
            dispatch('loadPersonsSalesStatusSale')
        },
        loadNextPagePersonsSalesOtherStatuses({dispatch, commit, state}) {
            commit('SET_PAGE_OTHER_STATUSES', state.pageOtherStatuses + 1)
            dispatch('loadPersonsSalesOtherStatuses')
        },
        loadPersonsSales({dispatch, commit, getters}) {
            dispatch('setIsLoadingPersonsSales', true)
            if (getters.getFilterCustomer) {
                return new Promise((resolve, reject) => {
                    const lowest_date = moment(getters.getDashboardMainDates[0]).tz(getters.getCurrentTimezone).startOf('day');
                    const highest_date = moment(getters.getDashboardMainDates[1]).tz(getters.getCurrentTimezone).endOf('day');
                    let lowest_dp_index = Math.floor(lowest_date.unix())
                    let highest_dp_index = Math.ceil(highest_date.unix())
                    if (getters.getCurrentUserDisplayFrom) {
                        let min_data_point = Math.floor(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day').unix())
                        if (lowest_dp_index < min_data_point) {
                            lowest_dp_index = min_data_point
                        }
                        if (highest_dp_index < min_data_point) {
                            highest_dp_index = min_data_point
                        }
                    }
                    let filter = {
                        status: "'New', 'Attempt Made'",
                        customerNameFilter: Array.isArray(getters.getFilterCustomer) ? '' : getters.getFilterCustomer,
                        LIMIT: !getters.getAllPersonsSalesEnabled ? getters.getPersonsSalesPerPage + 1 : 1000000000,
                        OFFSET: !getters.getAllPersonsSalesEnabled ? getters.getPersonsSalesPerPage * (getters.getPagePesonsSales - 1) : 0,
                        orderBy: "PurchaseTime"
                    }
                    dispatch('setNextAvailable', !getters.getAllPersonsSalesEnabled)
                    if (getters.getAllPersonsSalesEnabled) {
                        commit('NAVIGATE_STARTED')
                        fetchItemsNextTokenGraphQL(listPersonsSale, {...filter}).then((items) => {
                            commit('NAVIGATE_ENDED')
                            dispatch('setPersonsSales', items)
                            resolve()
                        }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    } else {
                        API.graphql(graphqlOperation(listPersonsSale, {...filter}))
                            .then((response) => {
                                var personsSalesResponse = response.data.listPersonsSale
                            
                                if (personsSalesResponse.length > getters.getPersonsSalesPerPage) {
                                    dispatch('setNextAvailable', true)
                                    dispatch('setPersonsSales', personsSalesResponse.slice(0, personsSalesResponse.length - 1))
                                } else {
                                    dispatch('setNextAvailable', false)
                                    dispatch('setPersonsSales', personsSalesResponse)
                                }
                                resolve()
                                dispatch('setIsLoadingPersonsSales', false)
                            }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    }
                })
            }
        },
        loadPersonsSalesStatusSale({dispatch, commit, getters}) {
            dispatch('setIsLoadingPersonsSalesStatusSale', true)
            if (getters.getFilterCustomer) {
                return new Promise((resolve, reject) => {
                    const lowest_date = moment(getters.getDashboardMainDates[0]).tz(getters.getCurrentTimezone).startOf('day');
                    const highest_date = moment(getters.getDashboardMainDates[1]).tz(getters.getCurrentTimezone).endOf('day');
                    let lowest_dp_index = Math.floor(lowest_date.unix())
                    let highest_dp_index = Math.ceil(highest_date.unix())
                    if (getters.getCurrentUserDisplayFrom) {
                        let min_data_point = Math.floor(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day').unix())
                        if (lowest_dp_index < min_data_point) {
                            lowest_dp_index = min_data_point
                        }
                        if (highest_dp_index < min_data_point) {
                            highest_dp_index = min_data_point
                        }
                    }
                    let filter = {
                        status: "'Sale', 'Sale Reviewed'",
                        customerNameFilter: Array.isArray(getters.getFilterCustomer) ? '' : getters.getFilterCustomer,
                        LIMIT: !getters.getAllPersonsSalesEnabledStatusSale ? getters.getPersonsSalesPerPageStatusSale + 1 : 1000000000,
                        OFFSET: !getters.getAllPersonsSalesEnabledStatusSale ? getters.getPersonsSalesPerPageStatusSale * (getters.getPagePesonsSalesStatusSale - 1) : 0,
                        orderBy: "PurchaseTime"
                    }
                    dispatch('setNextAvailableStatusSale', !getters.getAllPersonsSalesEnabledStatusSale)
                    if (getters.getAllPersonsSalesEnabledStatusSale) {
                        commit('NAVIGATE_STARTED')
                        fetchItemsNextTokenGraphQL(listPersonsSale, {...filter}).then((items) => {
                            commit('NAVIGATE_ENDED')
                            dispatch('setPersonsSalesStatusSale', items)
                            resolve()
                        }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    } else {
                        API.graphql(graphqlOperation(listPersonsSale, {...filter}))
                            .then((response) => {
                                var personsSalesResponse = response.data.listPersonsSale
                            
                                if (personsSalesResponse.length > getters.getPersonsSalesPerPageStatusSale) {
                                    dispatch('setNextAvailableStatusSale', true)
                                    dispatch('setPersonsSalesStatusSale', personsSalesResponse.slice(0, personsSalesResponse.length - 1))
                                } else {
                                    dispatch('setNextAvailableStatusSale', false)
                                    dispatch('setPersonsSalesStatusSale', personsSalesResponse)
                                }
                                resolve()
                                dispatch('setIsLoadingPersonsSalesStatusSale', false)
                            }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    }
                })
            }
        },
        loadPersonsSalesOtherStatuses({dispatch, commit, getters}) {
            dispatch('setIsLoadingPersonsSalesOtherStatuses', true)
            if (getters.getFilterCustomer) {
                return new Promise((resolve, reject) => {
                    const lowest_date = moment(getters.getDashboardMainDates[0]).tz(getters.getCurrentTimezone).startOf('day');
                    const highest_date = moment(getters.getDashboardMainDates[1]).tz(getters.getCurrentTimezone).endOf('day');
                    let lowest_dp_index = Math.floor(lowest_date.unix())
                    let highest_dp_index = Math.ceil(highest_date.unix())
                    if (getters.getCurrentUserDisplayFrom) {
                        let min_data_point = Math.floor(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day').unix())
                        if (lowest_dp_index < min_data_point) {
                            lowest_dp_index = min_data_point
                        }
                        if (highest_dp_index < min_data_point) {
                            highest_dp_index = min_data_point
                        }
                    }
                    let filter = {
                        status: "'Sale Approved', 'Sale Rejected', 'Not Interested', 'DNC'",
                        customerNameFilter: Array.isArray(getters.getFilterCustomer) ? '' : getters.getFilterCustomer,
                        LIMIT: !getters.getAllPersonsSalesEnabledOtherStatuses ? getters.getPersonsSalesPerPageOtherStatuses + 1 : 1000000000,
                        OFFSET: !getters.getAllPersonsSalesEnabledOtherStatuses ? getters.getPersonsSalesPerPageOtherStatuses * (getters.getPagePesonsSalesOtherStatuses - 1) : 0,
                        orderBy: "PurchaseTime"
                    }
                    dispatch('setNextAvailableOtherStatuses', !getters.getAllPersonsSalesEnabledOtherStatuses)
                    if (getters.getAllPersonsSalesEnabledOtherStatuses) {
                        commit('NAVIGATE_STARTED')
                        fetchItemsNextTokenGraphQL(listPersonsSale, {...filter}).then((items) => {
                            commit('NAVIGATE_ENDED')
                            dispatch('setPersonsSalesOtherStatuses', items)
                            resolve()
                        }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    } else {
                        API.graphql(graphqlOperation(listPersonsSale, {...filter}))
                            .then((response) => {
                                var personsSalesResponse = response.data.listPersonsSale
                            
                                if (personsSalesResponse.length > getters.getPersonsSalesPerPageOtherStatuses) {
                                    dispatch('setNextAvailableOtherStatuses', true)
                                    dispatch('setPersonsSalesOtherStatuses', personsSalesResponse.slice(0, personsSalesResponse.length - 1))
                                } else {
                                    dispatch('setNextAvailableOtherStatuses', false)
                                    dispatch('setPersonsSalesOtherStatuses', personsSalesResponse)
                                }
                                resolve()
                                dispatch('setIsLoadingPersonsSalesOtherStatuses', false)
                            }).catch((e) => {
                            dispatch('processError', e)
                            commit('NAVIGATE_ENDED')
                            reject(e)
                        })
                    }
                })
            }
        },
        updatePersonsSale({dispatch}, payload) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                if (payload.Sales === '') {
                    payload.Sales = []
                } else {
                    payload.Sales = JSON.stringify(payload.Sales).replaceAll('"', '`')
                }
                let input_personsSale = {
                    ...payload
                }
                API.graphql(graphqlOperation(updatePersonsSale, {updatePersonsSaleInput: input_personsSale})).then(() => {
                    Promise.all([dispatch('loadPersonsSales'),dispatch('loadPersonsSalesStatusSale'),dispatch('loadPersonsSalesOtherStatuses')]).then(() => {
                        dispatch('endActionAnimation')
                        resolve()
                    })
                }).catch((e) => {
                    dispatch('endActionAnimation')
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        setStatusPersonsSale({dispatch}, payload) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                if (payload.item.Sales === '') {
                    payload.item.Sales = []
                } else {
                    payload.item.Sales = JSON.stringify(payload.item.Sales).replaceAll('"', '`')
                }
                let input_personsSale = {
                    ...payload.item
                }
                input_personsSale.Status = payload.status
                API.graphql(graphqlOperation(updatePersonsSale, {updatePersonsSaleInput: input_personsSale})).then(() => {
                    Promise.all([dispatch('loadPersonsSales'),dispatch('loadPersonsSalesStatusSale'),dispatch('loadPersonsSalesOtherStatuses')]).then(() => {
                        dispatch('endActionAnimation')
                        resolve()
                    })
                }).catch((e) => {
                    dispatch('endActionAnimation')
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateNumberDisplayTime({dispatch}, payload) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                let input_numberDisplayTime = {
                    ...payload
                }
                API.graphql(graphqlOperation(updateNumberDisplayTime, {updatePersonsSaleNumberDisplayTimeInput: input_numberDisplayTime})).then(() => {
                    Promise.all([]).then(() => {
                        dispatch('endActionAnimation')
                        resolve()
                    })
                }).catch((e) => {
                    dispatch('endActionAnimation')
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        loadNumberDisplayTime({dispatch}, id) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(getNumberDisplayTime, {id: id})).then((response) => {
                const numberDisplayTime = response.data
                dispatch('setNumberDisplayTime', numberDisplayTime)
                dispatch('endActionAnimation')
                resolve()
            }).catch((e) => {
                dispatch('endActionAnimation')
                dispatch('processError', e)
                reject(e)
            })
        })
        },
    },
    getters: {
        getIsLoadingPersonsSales: state => state.isLoadingPersonsSales,
        getIsLoadingPersonsSalesStatusSale: state => state.isLoadingPersonsSalesStatusSale,
        getIsLoadingPersonsSalesOtherStatuses: state => state.isLoadingPersonsSalesOtherStatuses,
        getNumberDisplayTime: state => state.numberDisplayTime,
        getAllCustomers: state => state.customers,
        getFilterCustomer: state => state.filterCustomer,
        getAllPersonsSales: state => state.personsSales,
        getAllPersonsSalesStatusSale: state => state.personsSalesStatusSale,
        getAllPersonsSalesOtherStatuses: state => state.personsSalesOtherStatuses,
        personsSalesToFind: state => state.persons_sales_to_find,
        personsSalesToFindStatusSale: state => state.persons_sales_to_find_status_sale,
        personsSalesToFindOtherStatuses: state => state.persons_sales_to_find_other_statuses,
        getPersonsSalesPerPage: state => state.personsSales_per_page,
        getPersonsSalesPerPageStatusSale: state => state.personsSales_per_page_status_sale,
        getPersonsSalesPerPageOtherStatuses: state => state.personsSales_per_page_other_statuses,
        getPagePesonsSales: state => state.page,
        getPagePesonsSalesStatusSale: state => state.pageSale,
        getPagePesonsSalesOtherStatuses: state => state.pageOtherStatuses,
        getNextAvailablePersonsSales: state => state.next_available,
        getNextAvailablePersonsSalesStatusSale: state => state.next_available_status_sale,
        getNextAvailablePersonsSalesOtherStatuses: state => state.next_available_other_statuses,
        getAllPersonsSalesEnabled: state => state.allPersonsSalesEnabled,
        getAllPersonsSalesEnabledStatusSale: state => state.allPersonsSalesEnabledStatusSale,
        getAllPersonsSalesEnabledOtherStatuses: state => state.allPersonsSalesEnabledOtherStatuses,
    }
}
