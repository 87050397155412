export const USERS_GROUP_NAME = "Users";
export const ADMINS_GROUP_NAME = "Admins";
export const LESSER_ADMINS_GROUP_NAME = "LesserAdmin";
export const AGENTS_GROUP_NAME = "Agents";
export const SUPERVISORS_GROUP_NAME = "Supervisors";
export const COMMISSIONS_REVIEWER_GROUP_NAME = "CommissionsReviewer";
export const API_USER_GROUP_NAME = "ApiUser";

export const PAGE_NAME_DASHBOARDS = "Dashboards";
export const PAGE_NAME_USERS = "Users";
export const PAGE_NAME_CAMPAIGN_LISTS = "Campaign Lists";
export const PAGE_NAME_CAMPAIGNS = "Campaigns";
export const PAGE_NAME_CLIENTS = "Clients";
export const PAGE_NAME_DISPOSITIONS = "Dispositions";
export const PAGE_NAME_DISPOSITIONS_BUCKETS = "Dispositions Bucket";
export const PAGE_NAME_LOGIN = "Login";
export const PAGE_NAME_500 = "Server Error";
export const PAGE_NAME_403 = "No Permissions";
export const PAGE_NAME_404 = "Page Not Found";
export const PAGE_NAME_AGENTS = "Agents";
export const PAGE_NAME_PRODUCTIVITY = "Productivity";
export const PAGE_NAME_CALLS = "Calls";
export const PAGE_NAME_PERSONS_SALES = "Outgoing Calls";
export const PAGE_NAME_REPORTS = "Agent Reports";
export const PAGE_NAME_HOLIDAYS = "Holidays";
export const PAGE_NAME_KEYS= "Keys";
export const PAGE_NAME_COMMISSIONS = "Commissions";
export const PAGE_NAME_PAYROLLS = "Payrolls";


export const STANDARD_DATETIME_FORMAT = "MMMM Do YYYY, h:mm:ss a"
export const STANDARD_DATE_FORMAT = "MMMM Do YYYY"
export const DEFAULT_TIMEZONE = 'America/Vancouver'

export const CONST_OTHER_DISPOSITIONS_NAME = 'Other'

export const NAVIGATION_GROUP_SETTINGS = 'Settings'
export const NAVIGATION_GROUP_COMMISSIONS = 'Payments & Commissions'

export const PAYMENT_RELEASE_DATE = '2023-08-31'

export const AGENT_TYPE_CONTRACTOR = 'Contractor'
export const AGENT_TYPE_EMPLOYEE = 'Employee'

export const COMMISSION_TYPE_INBOUND = 'Inbound'
export const COMMISSION_TYPE_OUTBOUND = 'Outbound'
export const COMMISSION_TYPE_UPSELL = 'Upsell'

export const CONST_OFFSET_FOR_KONNEKTIVE = -3

export const stateTimezones = {
  'AL': 'America/Chicago',
  'AK': 'America/Anchorage',
  'AZ': 'America/Phoenix',
  'AR': 'America/Chicago',
  'CA': 'America/Los_Angeles',
  'CO': 'America/Denver',
  'CT': 'America/New_York',
  'DE': 'America/New_York',
  'FL': 'America/New_York',
  'GA': 'America/New_York',
  'HI': 'Pacific/Honolulu',
  'ID': 'America/Denver',
  'IL': 'America/Chicago',
  'IN': 'America/Indiana/Indianapolis',
  'IA': 'America/Chicago',
  'KS': 'America/Chicago',
  'KY': 'America/New_York',
  'LA': 'America/Chicago',
  'ME': 'America/New_York',
  'MD': 'America/New_York',
  'MA': 'America/New_York',
  'MI': 'America/Detroit',
  'MN': 'America/Chicago',
  'MS': 'America/Chicago',
  'MO': 'America/Chicago',
  'MT': 'America/Denver',
  'NE': 'America/Chicago',
  'NV': 'America/Los_Angeles',
  'NH': 'America/New_York',
  'NJ': 'America/New_York',
  'NM': 'America/Denver',
  'NY': 'America/New_York',
  'NC': 'America/New_York',
  'ND': 'America/North_Dakota/Center',
  'OH': 'America/New_York',
  'OK': 'America/Chicago',
  'OR': 'America/Los_Angeles',
  'PA': 'America/New_York',
  'RI': 'America/New_York',
  'SC': 'America/New_York',
  'SD': 'America/Chicago',
  'TN': 'America/Chicago',
  'TX': 'America/Chicago',
  'UT': 'America/Denver',
  'VT': 'America/New_York',
  'VA': 'America/New_York',
  'WA': 'America/Los_Angeles',
  'WV': 'America/New_York',
  'WI': 'America/Chicago',
  'WY': 'America/Denver',
  'UM': 'Etc/UTC',

  'Alabama': 'America/Chicago',
  'Alaska': 'America/Anchorage',
  'Arizona': 'America/Phoenix',
  'Arkansas': 'America/Chicago',
  'California': 'America/Los_Angeles',
  'Colorado': 'America/Denver',
  'Connecticut': 'America/New_York',
  'Delaware': 'America/New_York',
  'Florida': 'America/New_York',
  'Georgia': 'America/New_York',
  'Hawaii': 'Pacific/Honolulu',
  'Idaho': 'America/Denver',
  'Illinois': 'America/Chicago',
  'Indiana': 'America/Indiana/Indianapolis',
  'Iowa': 'America/Chicago',
  'Kansas': 'America/Chicago',
  'Kentucky': 'America/New_York',
  'Louisiana': 'America/Chicago',
  'Maine': 'America/New_York',
  'Maryland': 'America/New_York',
  'Massachusetts': 'America/New_York',
  'Michigan': 'America/Detroit',
  'Minnesota': 'America/Chicago',
  'Mississippi': 'America/Chicago',
  'Missouri': 'America/Chicago',
  'Montana': 'America/Denver',
  'Nebraska': 'America/Chicago',
  'Nevada': 'America/Los_Angeles',
  'New Hampshire': 'America/New_York',
  'New Jersey': 'America/New_York',
  'New Mexico': 'America/Denver',
  'New York': 'America/New_York',
  'North Carolina': 'America/New_York',
  'North Dakota': 'America/North_Dakota/Center',
  'Ohio': 'America/New_York',
  'Oklahoma': 'America/Chicago',
  'Oregon': 'America/Los_Angeles',
  'Pennsylvania': 'America/New_York',
  'Rhode Island': 'America/New_York',
  'South Carolina': 'America/New_York',
  'South Dakota': 'America/Chicago',
  'Tennessee': 'America/Chicago',
  'Texas': 'America/Chicago',
  'Utah': 'America/Denver',
  'Vermont': 'America/New_York',
  'Virginia': 'America/New_York',
  'Washington': 'America/Los_Angeles',
  'West Virginia': 'America/New_York',
  'Wisconsin': 'America/Chicago',
  'Wyoming': 'America/Denver',

  'AB': 'America/Edmonton',
  'BC': 'America/Vancouver',
  'MB': 'America/Winnipeg',
  'NL': 'America/St_Johns',
  'NS': 'America/Halifax',
  'ON': 'America/Toronto',
  'PE': 'America/Halifax',
  'QC': 'America/Montreal',
  'SK': 'America/Regina',
  'NT': 'America/Yellowknife',
  'NU': 'America/Iqaluit',
  'YT': 'America/Whitehorse',

  'Alberta': 'America/Edmonton',
  'British Columbia': 'America/Vancouver',
  'Manitoba': 'America/Winnipeg',
  'Newfoundland and Labrador': 'America/St_Johns',
  'Nova Scotia': 'America/Halifax',
  'Ontario': 'America/Toronto',
  'Prince Edward Island': 'America/Halifax',
  'Quebec': 'America/Montreal',
  'Saskatchewan': 'America/Regina',
  'Northwest Territories': 'America/Yellowknife',
  'Nunavut': 'America/Iqaluit',
  'Yukon': 'America/Whitehorse'
};