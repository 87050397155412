import {
    listCommissionsForAgent,
    listCommissionsToReview,
    listCommissionsCompleted,
    listCommissionsForPayments
} from "../graphql/queries";
import {createCommission, updateCommission, deleteCommission} from "../graphql/mutations";
import {
    fetchItemsNextTokenGraphQL,
    executeGraphQLMutationCreate,
    executeGraphQLMutationUpdate,
    executeGraphQLMutationDelete,

} from "@/utils/query_all_utils";
import {API, graphqlOperation} from "aws-amplify";

const moment = require('moment-timezone')
export default {
    state: {
        commissions_for_agent: [],
        commissions_to_review: [],
        commissions_completed: [],
        commissions_for_payments: [],
        commissions_per_page: 10,
        next_commissions_available: false,
        page: 1
    },
    mutations: {
        SET_COMMISSIONS_FOR_AGENT: (state, items) => state.commissions_for_agent = Object.freeze(items),
        SET_COMMISSIONS_TO_REVIEW: (state, items) => state.commissions_to_review = Object.freeze(items),
        SET_COMMISSIONS_COMPLETED: (state, items) => state.commissions_completed = Object.freeze(items),
        SET_COMMISSIONS_FOR_PAYMENTS: (state, items) => state.commissions_for_payments = Object.freeze(items),
        UPDATE_COMMISSIONS_PER_PAGE: (state, amount) => state.commissions_per_page = amount,
        SET_NEXT_COMMISSIONS_AVAILABLE: (state, next_available) => state.next_commissions_available = next_available,
        SET_COMMISSIONS_PAGE: (state, page) => state.page = page,
        SET_COMMISSIONS_PER_PAGE: (state, per_page) => state.commissions_per_page = per_page,
    },
    actions: {
        setCommissionsForAgent: ({commit}, items) => {
            commit('SET_COMMISSIONS_FOR_AGENT', items)
        },
        setCommissionsToReview: ({commit}, items) => {
            commit('SET_COMMISSIONS_TO_REVIEW', items)
        },
        setCommissionsCompleted: ({commit}, items) => {
            commit('SET_COMMISSIONS_COMPLETED', items)
        },
        setCommissionsForPayment: ({commit}, items) => {
            commit('SET_COMMISSIONS_FOR_PAYMENTS', items)
        },
        updateCommissionsPerPage: ({commit}, amount) => {
            commit('SET_COMMISSIONS_PER_PAGE', amount)
        },
        initializeCommissionsForPayment: ({dispatch}, date) => {
            let filter = {}
            filter['date_filter'] = date.format('YYYY/MM/DD')
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listCommissionsForPayments, filter).then((items) => {
                    dispatch('setCommissionsForPayment', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        initializeCommissionsForAgent: ({dispatch, getters}) => {
            let filter = {}
            filter['agent_filter'] = getters.getCommissionsFilter
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listCommissionsForAgent, filter).then((items) => {
                    dispatch('setCommissionsForAgent', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        initializeCommissionsToReview: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listCommissionsToReview).then((items) => {
                    dispatch('setCommissionsToReview', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        setNextCommissionsAvailable: ({commit}, next_available) => {
            commit('SET_NEXT_COMMISSIONS_AVAILABLE', next_available)
        },
        resetCommissions: ({commit, dispatch}) => {
            commit('SET_COMMISSIONS_PAGE', 1)
            commit('SET_NEXT_COMMISSIONS_AVAILABLE', false)
            dispatch('setCommissionsCompleted', [])
        },
        loadNextPageCommissions({dispatch, commit, state}) {
            commit('SET_COMMISSIONS_PAGE', state.page + 1)
            dispatch('loadCommissions')
        },
        loadCommissions({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                let filter = {
                    LIMIT: getters.getCommissionsPerPage + 1,
                    OFFSET: getters.getCommissionsPerPage * (getters.getCommissionsPage - 1),
                }
                dispatch('setNextCommissionsAvailable', false)
                API.graphql(graphqlOperation(listCommissionsCompleted, {...filter}))
                    .then((response) => {
                        var commissions = response.data.listCommissionsCompleted
                        if (commissions.length > getters.getCommissionsPerPage) {
                            dispatch('setNextCommissionsAvailable', true)
                            dispatch('setCommissionsCompleted', commissions.slice(0, commissions.length - 1))
                        } else {
                            dispatch('setNextCommissionsAvailable', false)
                            dispatch('setCommissionsCompleted', commissions)
                        }
                        resolve()
                    }).catch((e) => {
                    dispatch('processError', e)
                    commit('NAVIGATE_ENDED')
                    reject(e)
                })
            })
        },
        setStatusCommission({dispatch}, payload) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {

                let input_commission = {
                    ...payload.item
                }
                input_commission.status = payload.status
                if (payload.status === 'Rejected' || payload.status === 'Approved') {
                    input_commission.complete_date = moment.tz(moment(), 'America/Vancouver').format('YYYY-MM-DD HH:mm:ss')
                } else {
                    input_commission.complete_date = ''
                }
                executeGraphQLMutationUpdate(updateCommission, {updateCommissionInput: input_commission}).then(() => {
                    dispatch('resetCommissions')
                    Promise.all([dispatch('initializeCommissionsToReview'), dispatch('loadCommissions')]).then(() => {
                        dispatch('endActionAnimation')
                        resolve()
                    })
                }).catch((e) => {
                    dispatch('endActionAnimation')
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        addCommission({dispatch, getters}, commission) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let commission_input = {
                        ...commission,
                    }
                    commission_input.status = 'New'
                    commission_input.agent = commission_input.agent || getters.getCurrentUserEmail
                    commission_input.complete_date = ''
                    executeGraphQLMutationCreate(createCommission, {createCommissionInput: commission_input}).then(() => {
                        dispatch('initializeCommissionsForAgent').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })


                }
            )
        },
        removeCommission({dispatch}, id) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteCommission,
                        {id: id}
                    ).then(() => {
                        dispatch('initializeCommissionsForAgent').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getCommissionsFilter: (state, rootGetters) => {
            return rootGetters.getCurrentUserEmail
        },
        getAllCommissionsForAgent: state => state.commissions_for_agent,
        getAllCommissionsToReview: state => state.commissions_to_review,
        getAllCommissionsForPayments: state => state.commissions_for_payments,
        getAllCommissionsCompleted: state => state.commissions_completed,
        getCommissionsPerPage: state => state.commissions_per_page,
        getCommissionsPage: state => state.page,
        getNextCommissionsAvailable: state => state.next_commissions_available
    },
};
