import {API, graphqlOperation} from 'aws-amplify';
import {AGENT_TYPE_CONTRACTOR, AGENT_TYPE_EMPLOYEE} from "../constants";

const moment = require('moment-timezone')

export var agent_type_tiers_commission_type_mapping = {}
agent_type_tiers_commission_type_mapping[AGENT_TYPE_CONTRACTOR] = {
    0: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    1: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    2: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    3: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
}

agent_type_tiers_commission_type_mapping[AGENT_TYPE_EMPLOYEE] = {
    0: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    1: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    2: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
    3: {
        'Inbound': () => 5,
        'Outbound': (value) => Math.round(value * 0.1 * 100) / 100,
        'Upsell': (value) => Math.round(value * 0.1 * 100) / 100,
    },
}

export function callTypeToCampaignTypeMatch() {
    return true
    /* Available call types
        Manual
        Inbound
        Queue Callback
        3rd party transfer
        Inbound Voicemail
     */
}

export var shouldCountUnproductive = {
    "Not Ready_No Reason": 'No Reason',
    "Not Ready_No reason": 'No Reason',
    "Not Ready_Not Ready": 'Not Ready',
    "Not Ready_Personal": 'Personal',
    "Not Ready_Break": 'Break',
    "Not Ready_Meal": 'Meal',
    "Not Ready_System": 'System',
    "Not Ready_Ending Shift": 'Ending Shift',
}


export var shouldBePaid = {}
shouldBePaid[AGENT_TYPE_CONTRACTOR] = {
    0: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
        "Not Ready_Break": 1
    },
    1: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
    2: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
    3: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
}

shouldBePaid[AGENT_TYPE_EMPLOYEE] = {
    0: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
        "Not Ready_Break": 1
    },
    1: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
    2: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
    3: {
        "On Call_": 1,
        "On Hold_": 1,
        "Ringing_": 1,
        "After Call Work_": 1,
        "Not Ready_Outbound Sales Calls": 1,
        "Not Ready_Email and Tickets": 1,
        "Ready_": 1,
        "Not Ready_Meeting": 1,
        "Not Ready_Management": 1,
        "Not Ready_QA Monitoring": 1,
        "Not Ready_QA Training": 1,
        "Not Ready_Escalation": 1,
        "Not Ready_Administrative": 1,
        "Not Ready_Audit": 1,
        "Not Ready_Follow-Up Work": 1,
        "Not Ready_Training": 1,
        "Not Ready_Team Meeting": 1,
        "Not Ready_Task Completion": 1,
        "Not Ready_On Voicemails": 1,
    },
}
export var emptyProductivityData = {
    "Total Login": Array(),
    'Unproductive': Array(),
    'On Call': Array(),
    'Productive': Array(),
    'Billable Work': Array(),
    'After Work': Array(),
    'Emails and Tickets': Array(),
    'Outbound Sales Calls': Array(),
    'Breaks': Array(),
    'Meal': Array(),
    'No Reason': Array(),
    'Personal': Array(),
    'Ready Time': Array(),
    'Administrative': Array(),
    'Audit': Array(),
    'Ending Shift': Array(),
    'Escalation': Array(),
    'Follow Up Work': Array(),
    'Meeting': Array(),
    'Management': Array(),
    'On Voicemails': Array(),
    'QA Monitoring': Array(),
    'QA Training': Array(),
    'System': Array(),
    'Task Completion': Array(),
    'Training': Array(),
    'Team Meeting': Array(),
    'Not Ready': Array(),
    'Unclassified': Array(),
}

export var reportsThresholds = {}
export var defaultDistributionItems = {
    'Revenue By Campaign': 1
}

export var defaultMoneyItems = {
    'Billable Work': 1,
    'On Call': 1,
    'After Work': 1,
    'Outbound Sales Calls': 1,
    'Emails and Tickets': 1,
    'Productive': 1,
    'Ready Time': 1,
    'Management': 1,
    'Meeting': 1,
    'QA Monitoring': 1,
    'QA Training': 1,
    'Escalation': 1,
    'Administrative': 1,
    'Audit': 1,
    'Follow Up Work': 1,
    'Training': 1,
    'Team Meeting': 1,
    'Task Completion': 1,
    'On Voicemails': 1,
    'Revenue By Campaign': 1
}
export var hierarchy = [
    {"Total Login": []},
    {
        'Billable Work': [
            'On Call',
            'After Work',
            'Outbound Sales Calls',
            'Emails and Tickets'
        ]
    },
    {
        'Productive': [
            'Ready Time',
            'Meeting',
            'QA Monitoring',
            'QA Training',
            'Escalation',
            'Administrative',
            'Audit',
            'Follow Up Work',
            'Training',
            'Team Meeting',
            'Task Completion',
            'On Voicemails',
            'Management'
        ]
    },
    {
        'Unproductive':
            [
                'Ending Shift',
                'System',
                'No Reason',
                'Not Ready',
                'Personal',
                'Breaks',
                'Meal'
            ]
    },
    {
        'Unclassified': []
    },
    {
        'Revenue By Campaign': [],
    },
    {
        'Unknown Agents': []
    }
]

export var sumToHierarchy = ['Billable Work', 'Productive', 'Unproductive', 'Unclassified']

export var remapping = {
    "Login_": [],
    "Logout_Logout": [],
    "Logout_System": [],
    "Logout_": [],
    "DID Ringing_": [],
    "Logout_End Shift": [],
    "Logout_No Reason": [],
    "Logout_Forced": [],
    "Logout_Split Shift": [],
    "Not Ready_Text": [],

    "On Call_": ['On Call', 'Billable Work'],
    "On Hold_": ['On Call', 'Billable Work'],
    "Ringing_": ['On Call', 'Billable Work'],
    "After Call Work_": ['After Work', 'Billable Work'],
    "Not Ready_Outbound Sales Calls": ['Outbound Sales Calls', 'Billable Work'],
    "Not Ready_Email and Tickets": ['Emails and Tickets', 'Billable Work'],

    "Ready_": ['Ready Time', 'Productive'],
    "Not Ready_Meeting": ['Meeting', 'Productive'],
    "Not Ready_Management": ['Management', 'Productive'],
    "Not Ready_QA Monitoring": ['QA Monitoring', 'Productive'],
    "Not Ready_QA Training": ['QA Training', 'Productive'],
    "Not Ready_Escalation": ['Escalation', 'Productive'],
    "Not Ready_Administrative": ['Administrative', 'Productive'],
    "Not Ready_Audit": ['Audit', 'Productive'],
    "Not Ready_Follow-Up Work": ['Follow Up Work', 'Productive'],
    "Not Ready_Training": ['Training', 'Productive'],
    "Not Ready_Team Meeting": ['Team Meeting', 'Productive'],
    "Not Ready_Task Completion": ['Task Completion', 'Productive'],
    "Not Ready_On Voicemails": ['On Voicemails', 'Productive'],

    "Not Ready_System": ['System', 'Unproductive'],
    "Not Ready_Ending Shift": ['Ending Shift', 'Unproductive'],
    "Not Ready_No Reason": ['No Reason', 'Unproductive'],
    "Not Ready_No reason": ['No Reason', 'Unproductive'],
    "Not Ready_Not Ready": ['Not Ready', 'Unproductive'],
    "Not Ready_Personal": ['Personal', 'Unproductive'],
    "Not Ready_Break": ['Breaks', 'Unproductive'],
    "Not Ready_Meal": ['Meal', 'Unproductive'],
}

export function toDPIndex(value) {
    return value.unix() / 60 / 60
}

export function BuildProductivityFilter(dates, limit_date, last_paydates, end_date, holidays, start_end_time) {
    let lowest_date
    let highest_date
    let lowest_dp_index
    let highest_dp_index
    if (dates) {
        lowest_date = moment(dates[0]).startOf('day')
        highest_date = moment(dates[1]).endOf('day')
        if (start_end_time) {
            lowest_date = moment(dates[0]).set('hour', start_end_time.start)
            highest_date = moment(dates[1]).set('hour', start_end_time.end)
        }
        lowest_dp_index = Math.floor(toDPIndex(lowest_date))
        highest_dp_index = Math.ceil(toDPIndex(highest_date))
        if (limit_date) {
            let min_data_point = Math.floor(toDPIndex(moment.unix(limit_date).startOf('day')))
            if (lowest_dp_index < min_data_point) {
                lowest_dp_index = min_data_point
            }
            if (highest_dp_index < min_data_point) {
                highest_dp_index = min_data_point
            }
        }
    }


    if (last_paydates) {
        let last_paydate = Object.values(last_paydates).reduce((accum, item) => {
            if (accum > moment(item)) {
                accum = moment(item)
            }
            return accum
        }, moment('3000-01-01'))
        let last_paydate_dp_index = Math.floor(toDPIndex(last_paydate.startOf('day')))
        let end_date_paydate_dp_index = Math.ceil(toDPIndex(moment(end_date).endOf('day')))
        if (holidays.length) {
            let start_paydate = last_paydate.startOf('day')
            let end_paydate = moment(end_date).endOf('day')
            let day = start_paydate
            let days = []
            let intersecting_days = []
            while (day <= end_paydate) {
                days.push(day.format('YYYY-MM-DD'));
                day = day.clone().add(1, 'd');
            }
            let mapped_holidays = holidays.map(item => item['Holiday_date'])
            days.forEach(item => {
                if (mapped_holidays.includes(item)) {
                    intersecting_days.push(item)
                }
            })
            if (intersecting_days.length) {
                let first_holiday_in_period = intersecting_days.reduce((accum, item) => {
                    if (accum > moment(item)) {
                        accum = moment(item)
                    }
                    return accum
                })
                let start = moment(first_holiday_in_period).subtract(30, 'day').startOf('day')
                last_paydate_dp_index = Math.floor(toDPIndex(start))

            }
        }
        return {
            'dp_start': lowest_dp_index < last_paydate_dp_index ? lowest_dp_index : last_paydate_dp_index,
            'dp_end': highest_dp_index > end_date_paydate_dp_index ? highest_dp_index : end_date_paydate_dp_index
        }
    } else {
        return {
            'dp_start': lowest_dp_index,
            'dp_end': highest_dp_index
        }

    }
}


export async function fetchItemsNextTokenGraphQL(query, variables, LIMIT = 350, OFFSET = 0) {
    var params = {...variables, LIMIT: LIMIT, OFFSET: OFFSET}
    let data = {}
    let res = null
    let retry = 0
    for (retry = 0; retry < 3; retry++) {
        try {
            const response = await API.graphql(graphqlOperation(query, params));
            if (response.errors !== undefined)
            {
                continue;
            }
            data = response.data
            const key = Object.keys(data).find(k => k.includes('list'));
            res = data[key]; // res = { items: [], nextToken: '' }
            if (res !== null)
            {
                break;
            }
        } catch (e) {
            if (retry == 2) {
                throw e
            }
        }
    }
    if (res) {
        if (res.length == LIMIT) {
            var next_set = await fetchItemsNextTokenGraphQL(query, variables, LIMIT, OFFSET + LIMIT)
            return res.concat(next_set)
        } else {
            return res
        }
    } else {
        return []
    }
}

export async function fetchItemsNextTokenAPI(apiName, path, requestData, responseParamItemsName, items = []) {
    if (requestData['queryStringParameters'] === undefined) {
        requestData['queryStringParameters'] = {}
    }

    const data = await API.get(apiName, path, requestData);
    const res = data[responseParamItemsName]; // res = { items: [], nextToken: '' }

    items.push(...res);
    if (!data.NextToken) return items;

    // eslint-disable-next-line no-param-reassign

    requestData['queryStringParameters']['token'] = data.NextToken;
    return fetchItemsNextTokenAPI(apiName, path, requestData, responseParamItemsName, items);
}

export function executeGraphQLMutationCreate(graphql, input) {
    if (input[Object.keys(input)[0]]['id'] == undefined) {
        input[Object.keys(input)[0]]['id'] = generateUUID()
    }
    input[Object.keys(input)[0]]['createdAt'] = moment.utc().toISOString()
    input[Object.keys(input)[0]]['updatedAt'] = moment.utc().toISOString()
    return API.graphql(graphqlOperation(graphql, input))
}

export function executeGraphQLMutationDelete(graphql, input) {
    return API.graphql(graphqlOperation(graphql, input))
}

export function executeGraphQLMutationUpdate(graphql, input) {
    input[Object.keys(input)[0]]['updatedAt'] = moment.utc().toISOString()
    return API.graphql(graphqlOperation(graphql, input))
}

export function generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
