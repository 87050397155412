import {listDispositionBuckets} from "../graphql/queries";
import {createDispositionBucket, updateDispositionBucket, deleteDispositionBucket} from "../graphql/mutations";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
    executeGraphQLMutationUpdate,
    fetchItemsNextTokenGraphQL
} from "@/utils/query_all_utils";
import random_color_generator from "@/utils/random_color_generator"

export default {
    state: {
        disposition_buckets: [],
        disposition_buckets_colors: []
    },
    mutations: {
        SET_DISPOSITION_BUCKETS: (state, disposition_buckets) => state.disposition_buckets = Object.freeze(disposition_buckets),
        SET_DISPOSITION_BUCKETS_COLORS: (state, disposition_buckets_colors) => state.disposition_buckets_colors = Object.freeze(disposition_buckets_colors),
    },
    actions: {
        setDispositionBuckets: ({commit}, disposition_buckets) => {
            for (let bucket of disposition_buckets) {
                bucket.dispositions = JSON.parse(bucket.dispositions.replaceAll('`', '"'))
                if (bucket.campaigns) {
                    bucket.campaigns = JSON.parse(bucket.campaigns.replaceAll('`', '"'))
                } else {
                    bucket.campaigns = []
                }
            }
            commit('SET_DISPOSITION_BUCKETS', disposition_buckets)
            commit('SET_DISPOSITION_BUCKETS_COLORS', random_color_generator(disposition_buckets.length, 'bright', 136))
        },
        initializeDispositionBuckets: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listDispositionBuckets).then((items) => {
                    dispatch('setDispositionBuckets', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateDispositionBucket({dispatch}, disposition_bucket) {
            dispatch('startActionAnimation')
            var disposition_bucket_escapted = {...disposition_bucket}
            disposition_bucket_escapted.dispositions = disposition_bucket_escapted.dispositions.replaceAll('"', '`')
            disposition_bucket_escapted.campaigns =disposition_bucket_escapted.campaigns.replaceAll('"', '`')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationUpdate(updateDispositionBucket, {updateDispositionBucketInput: disposition_bucket_escapted}).then(() => {
                        dispatch('initializeDispositionBuckets').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        removeDispositionBucket({dispatch}, dispositionBucketID) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteDispositionBucket, {id: dispositionBucketID}).then(() => {
                        dispatch('initializeDispositionBuckets').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addDispositionBucket({dispatch}, disposition_bucket) {
            var disposition_bucket_escapted = {...disposition_bucket}
            disposition_bucket_escapted.dispositions = disposition_bucket_escapted.dispositions.replaceAll('"', '`')
            disposition_bucket_escapted.campaigns = disposition_bucket_escapted.campaigns.replaceAll('"', '`')
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationCreate(createDispositionBucket, {createDispositionBucketInput: disposition_bucket_escapted}).then(() => {
                        dispatch('initializeDispositionBuckets').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getAllDispositionBuckets: state => state.disposition_buckets,
        getAllDispositionBucketsColors: state => state.disposition_buckets_colors,
    },
};
