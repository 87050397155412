import {listCampaigns} from "../graphql/queries";
import {createCampaign, updateCampaign, updateCallsToProcess, updateCallsToProcessFor0} from "../graphql/mutations";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationUpdate
} from "@/utils/query_all_utils";
const moment = require('moment-timezone')
import {API, graphqlOperation} from 'aws-amplify';

export default {
    state: {
        campaigns: []
    },
    mutations: {
        SET_CAMPAIGNS: (state, campaigns) => state.campaigns = Object.freeze(campaigns)
    },
    actions: {
        setCampaigns: ({commit}, campaigns) => {
            campaigns.forEach(campaigns => {
                campaigns.rate = campaigns.rate / 10000
            })
            campaigns.forEach(campaigns => {
                campaigns.distribution = campaigns.distribution / 10000
            })
            commit('SET_CAMPAIGNS', campaigns)
        },
        initializeCampaigns: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listCampaigns).then((items) => {
                    items = items.map((item) => {
                        if (item.capped_to_5_min) {
                            item.capped_to_5_min = item.capped_to_5_min.split(',').filter((item) => item.length > 0)
                            return item
                        }
                        item.capped_to_5_min = []
                        return item
                    })
                    dispatch('setCampaigns', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateCampaign({dispatch, getters}, campaign) {
            dispatch('startActionAnimation')
            var campaign_escapted = {...campaign}
            campaign_escapted.capped_to_5_min = campaign_escapted.capped_to_5_min.join(',')
            campaign_escapted.is_rate_hourly = campaign_escapted.is_rate_hourly ? 1 : 0
            campaign_escapted.rate = campaign.rate ? Math.round(campaign.rate * 10000) : 0
            campaign_escapted.distribution = campaign.distribution ? Math.round(campaign.distribution * 10000) : 0
            if (campaign_escapted.client_name == null)
            {
                campaign_escapted.client_name = ''
            }
            return new Promise((resolve, reject) => {
                    var old_campaign = getters.getCampaignByID(campaign_escapted.id)
                    if (old_campaign) {
                        var update_from = undefined
                        let updateCallsMutation = updateCallsToProcess
                        if (campaign_escapted.datetime) {
                            if (old_campaign.tier != campaign_escapted.tier) {
                                update_from = campaign_escapted.datetime
                            }
                            delete campaign_escapted.datetime

                            if (old_campaign.tier == 0)
                            {
                                updateCallsMutation = updateCallsToProcessFor0
                            }
                        }
                        executeGraphQLMutationUpdate(updateCampaign, {updateCampaignInput: campaign_escapted}).then(() => {
                            if (update_from) {
                                API.graphql(graphqlOperation(updateCallsMutation, {
                                    campaign_name: campaign_escapted.name,
                                    from: moment(update_from).unix()
                                })).then(() => {
                                    dispatch('initializeCampaigns').then(() => {
                                        dispatch('endActionAnimation')
                                        resolve()
                                    })
                                })
                            } else {
                                dispatch('initializeCampaigns').then(() => {
                                    dispatch('endActionAnimation')
                                    resolve()
                                })
                            }
                        }).catch((e) => {
                            dispatch('endActionAnimation')
                            dispatch('processError', e)
                            reject(e)
                        })
                    } else {
                        reject("No campaign found")
                    }
                }
            )
        },
        removeCampaign({dispatch}, campaign) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationUpdate(updateCampaign,
                        {updateCampaignInput: campaign}
                    ).then(() => {
                        dispatch('initializeCampaigns').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addCampaign({dispatch}, campaign) {
            dispatch('startActionAnimation')
            var campaign_escapted = {...campaign}
            campaign_escapted.capped_to_5_min = campaign_escapted.capped_to_5_min.join(',')
            campaign_escapted.is_rate_hourly = campaign_escapted.is_rate_hourly ? 1 : 0
            campaign_escapted.rate = campaign.rate ? Math.round(campaign.rate * 10000) : 0
            campaign_escapted.distribution = campaign.distribution ? Math.round(campaign.distribution * 10000) : 0
            if (campaign_escapted.client_name == null)
            {
                campaign_escapted.client_name = ''
            }
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationCreate(createCampaign, {createCampaignInput: campaign_escapted}).then(() => {
                        dispatch('initializeCampaigns').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getAllCampaigns: state => state.campaigns,
        getAllActiveCampaigns: state => state.campaigns.filter((item)=> !item.archived),
        getCampaignByID: state => (id) => state.campaigns.find((item) => item.id == id),
        getCampaignByName: state => (name) => state.campaigns.find((item) => item.name == name),

        getCampaignIdByName: state => (name) => state.campaigns.find((item) => item.name === name ? item.id : null),
        getCampaignNameById: state => (id) => state.campaigns.find((item) => item.id === id ? item.name : null),
        getCampaignDisplayNameByName: state => (name) => state.campaigns.find((item) => item.name === name ? item.display_name : null).display_name
    },
};
