/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPersonsSale = /* GraphQL */ `
  query ListPersonsSale(
    $customerNameFilter: String
    $status: String
    $orderBy: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listPersonsSale(
      customerNameFilter: $customerNameFilter
      status: $status
      orderBy: $orderBy
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      PurchaseTime
      ProductName
      FirstName
      LastName
      PhoneNumber
      Email
      AddressLineOne
      AddressLineTwo
      State
      Country
      Source
      Additional1
      Additional2
      Additional3
      Additional4
      Additional5
      Additional6
      Additional7
      Additional8
      Additional9
      Additional10
      Additional11
      Additional12
      Additional13
      Additional14
      Additional15
      Additional16
      Additional17
      Additional18
      Additional19
      Additional20
      CustomerName
      Status
      OpenURL
      Agent
      CommissionType
      CompleteDate
      Campaign
      CountAttemptMade
      Sales
    }
  }
`;
export const getPersonSaleAPITest = /* GraphQL */ `
  query GetPersonSaleAPITest(
    $PurchaseTime: Int!
    $PhoneNumber: String!
    ) {
    getPersonSaleAPITest(
    PurchaseTime: $PurchaseTime
    PhoneNumber: $PhoneNumber
    ) {
      id
      PurchaseTime
      ProductName
      FirstName
      LastName
      PhoneNumber
      Email
      AddressLineOne
      AddressLineTwo
      State
      Country
      Source
      Additional1
      Additional2
      Additional3
      Additional4
      Additional5
      Additional6
      Additional7
      Additional8
      Additional9
      Additional10
      Additional11
      Additional12
      Additional13
      Additional14
      Additional15
      Additional16
      Additional17
      Additional18
      Additional19
      Additional20
      CustomerName
      Status
      OpenURL
      Agent
      CommissionType
      CompleteDate
      Campaign
      CountAttemptMade
      Sales
    }
  }
`;
export const getNumberDisplayTime = /* GraphQL */ `
  query GetNumberDisplayTime($id: Int!) {
    getNumberDisplayTime(id: $id) {
      NumberDisplayTime
      NumberDisplayAgent
    }
  }
`;
export const listKeysToCustomer = /* GraphQL */ `
  query ListKeysToCustomer {
    listKeysToCustomer {
      CustomerName
      KeyField
      Additional1
      Additional2
      Additional3
      Additional4
      Additional5
      Additional6
      Additional7
      Additional8
      Additional9
      Additional10
      Additional11
      Additional12
      Additional13
      Additional14
      Additional15
      Additional16
      Additional17
      Additional18
      Additional19
      Additional20
      OpenURL
      Campaigns
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      CustomerName
      Additional1
      Additional2
      Additional3
      Additional4
      Additional5
      Additional6
      Additional7
      Additional8
      Additional9
      Additional10
      Additional11
      Additional12
      Additional13
      Additional14
      Additional15
      Additional16
      Additional17
      Additional18
      Additional19
      Additional20
      Campaigns
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: String!) {
    getAgent(id: $id) {
      id
      email
      firstName
      secondName
      agent_group
      payrate
      is_rate_hourly
      tier
      type
      vacation
      archived
      CRMID
      Alias
      agent_type
      createdAt
      updatedAt
      CommissionPercentage
      AccessiableOutgoingCustomers
    }
  }
`;
export const getAgentByEmail = /* GraphQL */ `
  query GetAgentByEmail($draft_email: String!) {
    getAgentByEmail(draft_email: $draft_email) {
      email
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents($agent_filter: String, $LIMIT: Int, $OFFSET: Int) {
    listAgents(agent_filter: $agent_filter, LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      email
      firstName
      secondName
      agent_group
      payrate
      is_rate_hourly
      tier
      type
      vacation
      archived
      CRMID
      Alias
      agent_type
      createdAt
      updatedAt
      CommissionPercentage
      AccessiableOutgoingCustomers
    }
  }
`;
export const getHoliday = /* GraphQL */ `
  query GetHoliday($id: String!) {
    getHoliday(id: $id) {
      id
      name
      Holiday_date
      createdAt
      updatedAt
    }
  }
`;
export const listHolidays = /* GraphQL */ `
  query ListHolidays($LIMIT: Int, $OFFSET: Int) {
    listHolidays(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      Holiday_date
      createdAt
      updatedAt
    }
  }
`;
export const getClientCRM = /* GraphQL */ `
  query GetClientCRM($id: String!) {
    getClientCRM(id: $id) {
      id
      name
      login
      filter
      purchases_filter
      password
      createdAt
      updatedAt
    }
  }
`;
export const listClientCRM = /* GraphQL */ `
  query ListClientCRM($LIMIT: Int, $OFFSET: Int) {
    listClientCRM(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      login
      filter
      purchases_filter
      password
      createdAt
      updatedAt
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: String!) {
    getPayment(id: $id) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments($agent_filter: String, $LIMIT: Int, $OFFSET: Int) {
    listPayments(agent_filter: $agent_filter, LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const listLastPaymentsForAgent = /* GraphQL */ `
  query ListLastPaymentsForAgent($LIMIT: Int, $OFFSET: Int) {
    listLastPaymentsForAgent(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      agent
      payment_date
    }
  }
`;
export const listAgentPayments = /* GraphQL */ `
  query ListAgentPayments($agent_filter: String, $LIMIT: Int, $OFFSET: Int) {
    listAgentPayments(
      agent_filter: $agent_filter
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      agent
      payment_date
      paid_commissions
      paid_hourly
      remaining_debt
      tracking_info
      createdAt
      updatedAt
    }
  }
`;
export const getCommission = /* GraphQL */ `
  query GetCommission($id: String!) {
    getCommission(id: $id) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const listCommissions = /* GraphQL */ `
  query ListCommissions($agent_filter: String, $LIMIT: Int, $OFFSET: Int) {
    listCommissions(
      agent_filter: $agent_filter
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const listCommissionsForAgent = /* GraphQL */ `
  query ListCommissionsForAgent(
    $agent_filter: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listCommissionsForAgent(
      agent_filter: $agent_filter
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const listCommissionsToReview = /* GraphQL */ `
  query ListCommissionsToReview($LIMIT: Int, $OFFSET: Int) {
    listCommissionsToReview(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const listCommissionsCompleted = /* GraphQL */ `
  query ListCommissionsCompleted($LIMIT: Int, $OFFSET: Int) {
    listCommissionsCompleted(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const listCommissionsForPayments = /* GraphQL */ `
  query ListCommissionsForPayments(
    $date_filter: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listCommissionsForPayments(
      date_filter: $date_filter
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      agent
      sum
      type
      commission_date
      complete_date
      status
      campaign
      product
      call_id
      comment
      CRM
      CustomerName
      createdAt
      updatedAt
    }
  }
`;
export const getAgentDashboardRecord = /* GraphQL */ `
  query GetAgentDashboardRecord($id: String!) {
    getAgentDashboardRecord(id: $id) {
      id
      dp_index
      agents
      createdAt
      updatedAt
    }
  }
`;
export const listAgentDashboardRecords = /* GraphQL */ `
  query ListAgentDashboardRecords(
    $dp_start: Int
    $dp_end: Int
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listAgentDashboardRecords(
      dp_start: $dp_start
      dp_end: $dp_end
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      dp_index
      agents
      createdAt
      updatedAt
    }
  }
`;
export const getAgentRefundDashboardRecord = /* GraphQL */ `
  query GetAgentRefundDashboardRecord($id: String!) {
    getAgentRefundDashboardRecord(id: $id) {
      id
      dp_index
      agent_email
      calls_taken
      refund_count
      refund_dollars
      cancel_count
      sales
      global_sales
      returns
      chargeback
      other
      sales_count
      subscription_sales
      subscription_count
      prebill_cancels
      postbill_cancels
      createdAt
      updatedAt
    }
  }
`;
export const listAgentRefundDashboardRecords = /* GraphQL */ `
  query ListAgentRefundDashboardRecords(
    $dp_start: Int
    $dp_end: Int
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listAgentRefundDashboardRecords(
      dp_start: $dp_start
      dp_end: $dp_end
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      dp_index
      agent_email
      calls_taken
      refund_count
      refund_dollars
      cancel_count
      sales
      global_sales
      returns
      chargeback
      other
      sales_count
      subscription_sales
      subscription_count
      prebill_cancels
      postbill_cancels
      createdAt
      updatedAt
    }
  }
`;
export const getAgentRefundRecord = /* GraphQL */ `
  query GetAgentRefundRecord($id: String!) {
    getAgentRefundRecord(id: $id) {
      id
      dp_index
      client_name
      grossrev
      refunds
      rebill_cancels
      initial_cancels
      agent_refunds
      createdAt
      updatedAt
    }
  }
`;
export const listAgentRefundRecords = /* GraphQL */ `
  query ListAgentRefundRecords($LIMIT: Int, $OFFSET: Int) {
    listAgentRefundRecords(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      dp_index
      client_name
      grossrev
      refunds
      rebill_cancels
      initial_cancels
      agent_refunds
      createdAt
      updatedAt
    }
  }
`;
export const getAgentTimeRecord = /* GraphQL */ `
  query GetAgentTimeRecord($id: String!) {
    getAgentTimeRecord(id: $id) {
      id
      AGENT_EMAIL
      AGENT_STATE_TIME
      PROCESSED_STATUS
      STATE
      REASON_CODE
      TIMESTAMP
      TIMESTAMP_REAL
      dp_index
      createdAt
      updatedAt
    }
  }
`;
export const listAgentTimeRecords = /* GraphQL */ `
  query ListAgentTimeRecords($LIMIT: Int, $OFFSET: Int) {
    listAgentTimeRecords(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      AGENT_EMAIL
      AGENT_STATE_TIME
      PROCESSED_STATUS
      STATE
      REASON_CODE
      TIMESTAMP
      TIMESTAMP_REAL
      dp_index
      createdAt
      updatedAt
    }
  }
`;
export const getCallRecord = /* GraphQL */ `
  query GetCallRecord($id: String!) {
    getCallRecord(id: $id) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const getCallRecordForCommission = /* GraphQL */ `
  query GetCallRecordForCommission($CALL_ID: String!) {
    getCallRecordForCommission(CALL_ID: $CALL_ID) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const listCallRecords = /* GraphQL */ `
  query ListCallRecords(
    $CALL_ID_QUERY: String
    $lowest: Int
    $highest: Int
    $campaign: String
    $orderBy: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listCallRecords(
      CALL_ID_QUERY: $CALL_ID_QUERY
      lowest: $lowest
      highest: $highest
      campaign: $campaign
      orderBy: $orderBy
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      id
      CALL_ID
      TIMESTAMP
      CAMPAIGN
      CALL_TYPE
      AGENT
      AGENT_NAME
      DISPOSITION
      ANI
      CUSTOMER_NAME
      DNIS
      CALL_TIME
      BILL_TIME_ROUNDED
      COST
      IVR_TIME
      QUEUE_WAIT_TIME
      RING_TIME
      TALK_TIME
      HOLD_TIME
      PARK_TIME
      AFTER_CALL_WORK_TIME
      TRANSFERS
      CONFERENCES
      HOLDS
      ABANDONED
      RECORDINGS
      PROCESSED_STATUS
      PROCESSED_TIER
      TIMESTAMP_REAL
      createdAt
      updatedAt
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: String!) {
    getCampaign(id: $id) {
      id
      name
      display_name
      tier
      client_name
      is_rate_hourly
      rate
      distribution
      capped_to_5_min
      archived
      CRMFilter
      createdAt
      updatedAt
      join_campaign
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns($LIMIT: Int, $OFFSET: Int) {
    listCampaigns(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      display_name
      tier
      client_name
      is_rate_hourly
      rate
      distribution
      capped_to_5_min
      archived
      CRMFilter
      createdAt
      updatedAt
      join_campaign
    }
  }
`;
export const getCampaignList = /* GraphQL */ `
  query GetCampaignList($id: String!) {
    getCampaignList(id: $id) {
      id
      name
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignLists = /* GraphQL */ `
  query ListCampaignLists($LIMIT: Int, $OFFSET: Int) {
    listCampaignLists(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const getDashboardRecord = /* GraphQL */ `
  query GetDashboardRecord($id: String!) {
    getDashboardRecord(id: $id) {
      id
      dp_index
      dp_type
      campaign
      DISPOSITION
      ABANDONED
      COUNT
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      QUEUE_WAIT_TIME_5_MIN
      QUEUE_WAIT_TIME_5_MIN_1
      QUEUE_WAIT_TIME_5_MIN_2
      QUEUE_WAIT_TIME_5_MIN_3
      QUEUE_WAIT_TIME_5_MIN_4
      agent
      agent_time
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardRecordsForReports = /* GraphQL */ `
  query ListDashboardRecordsForReports(
    $dp_start: Int
    $dp_end: Int
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listDashboardRecordsForReports(
      dp_start: $dp_start
      dp_end: $dp_end
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      agent
      campaign
      agent_time
      CallCount
    }
  }
`;
export const listDashboardRecords = /* GraphQL */ `
  query ListDashboardRecords(
    $dp_start: Int
    $dp_end: Int
    $bucket_case: String
    $agents: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listDashboardRecords(
      dp_start: $dp_start
      dp_end: $dp_end
      bucket_case: $bucket_case
      agents: $agents
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      dp_index
      COUNT
      DISPOSITION_BUCKET
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      agent_time
    }
  }
`;
export const listDashboardRecordsForCampaigns = /* GraphQL */ `
  query ListDashboardRecordsForCampaigns(
    $dp_start: Int
    $dp_end: Int
    $bucket_case: String
    $campaign: String
    $agents: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listDashboardRecordsForCampaigns(
      dp_start: $dp_start
      dp_end: $dp_end
      bucket_case: $bucket_case
      campaign: $campaign
      agents: $agents
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      dp_index
      COUNT
      DISPOSITION_BUCKET
      TOTAL_TIME
      TOTAL_TIME_1
      TOTAL_TIME_2
      TOTAL_TIME_3
      TOTAL_TIME_4
      QUEUE_WAIT_TIME
      QUEUE_WAIT_TIME_1
      QUEUE_WAIT_TIME_2
      QUEUE_WAIT_TIME_3
      QUEUE_WAIT_TIME_4
      agent_time
    }
  }
`;
export const listDashboardRecordsTopChart = /* GraphQL */ `
  query ListDashboardRecordsTopChart(
    $dp_start: Int
    $dp_end: Int
    $dateOffset: Int
    $agents: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listDashboardRecordsTopChart(
      dp_start: $dp_start
      dp_end: $dp_end
      dateOffset: $dateOffset
      agents: $agents
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      day_dp_index
      COUNT
      TOTAL_TIME
      QUEUE_WAIT_TIME
    }
  }
`;
export const listDashboardRecordsForCampaignsTopChart = /* GraphQL */ `
  query ListDashboardRecordsForCampaignsTopChart(
    $dp_start: Int
    $dp_end: Int
    $dateOffset: Int
    $campaign: String
    $agents: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listDashboardRecordsForCampaignsTopChart(
      dp_start: $dp_start
      dp_end: $dp_end
      dateOffset: $dateOffset
      campaign: $campaign
      agents: $agents
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      day_dp_index
      COUNT
      TOTAL_TIME
      QUEUE_WAIT_TIME
    }
  }
`;
export const getDisposition = /* GraphQL */ `
  query GetDisposition($id: String!) {
    getDisposition(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listDispositions = /* GraphQL */ `
  query ListDispositions($LIMIT: Int, $OFFSET: Int) {
    listDispositions(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const getDispositionBucket = /* GraphQL */ `
  query GetDispositionBucket($id: String!) {
    getDispositionBucket(id: $id) {
      id
      name
      dispositions
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const listDispositionBuckets = /* GraphQL */ `
  query ListDispositionBuckets($LIMIT: Int, $OFFSET: Int) {
    listDispositionBuckets(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      name
      dispositions
      campaigns
      createdAt
      updatedAt
    }
  }
`;
export const getOutgoingCallsToMake = /* GraphQL */ `
  query GetOutgoingCallsToMake($id: String!) {
    getOutgoingCallsToMake(id: $id) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const listOutgoingCallsToMakes = /* GraphQL */ `
  query ListOutgoingCallsToMakes($LIMIT: Int, $OFFSET: Int) {
    listOutgoingCallsToMakes(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const listOutgoingCallsToMakesProcessed = /* GraphQL */ `
  query ListOutgoingCallsToMakesProcessed($LIMIT: Int, $OFFSET: Int) {
    listOutgoingCallsToMakesProcessed(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      email_address
      first_name
      last_name
      phone_number
      customer_id
      customer_name
      campaign_name
      date
      status
      agent
      click_id
      source_id
      city
      state
      order_id
      order_status
      list_id
      height
      weight
      bmi
      createdAt
      updatedAt
    }
  }
`;
export const getAmUser = /* GraphQL */ `
  query GetAmUser($id: String!) {
    getAmUser(id: $id) {
      id
      cognito_id
      campaigns
      timezone
      agents
      display_from
      show_name
      createdAt
      updatedAt
    }
  }
`;
export const listAmUsers = /* GraphQL */ `
  query ListAmUsers($LIMIT: Int, $OFFSET: Int) {
    listAmUsers(LIMIT: $LIMIT, OFFSET: $OFFSET) {
      id
      cognito_id
      campaigns
      timezone
      agents
      display_from
      show_name
      createdAt
      updatedAt
    }
  }
`;
export const listCancelRefundsRecords = /* GraphQL */ `
  query ListCancelRefundsRecords($start: Int, $end: Int) {
    listCancelRefundsRecords(start: $start, end: $end) {
      agentUserId
      campaignId
      campaignName
      orderStatus
      orderType
      currencySymbol
      clientName
      price
      count
      totalAmount
    }
  }
`;
export const listPurchasesRecords = /* GraphQL */ `
  query ListPurchasesRecords(
    $start: String
    $end: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listPurchasesRecords(
      start: $start
      end: $end
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      dateUpdated
      campaignId
      customerId
      orderId
      billingCycleNumber
      price
      clientName
    }
  }
`;
export const listNotesRecords = /* GraphQL */ `
  query ListNotesRecords(
    $start: String
    $end: String
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listNotesRecords(start: $start, end: $end, LIMIT: $LIMIT, OFFSET: $OFFSET) {
      message
      customerId
      dateCreated
      categoryType
      agentName
      orderId
      transactionId
    }
  }
`;
export const listTransactionsRecords = /* GraphQL */ `
  query ListTransactionsRecords(
    $start: Int
    $end: Int
    $LIMIT: Int
    $OFFSET: Int
  ) {
    listTransactionsRecords(
      start: $start
      end: $end
      LIMIT: $LIMIT
      OFFSET: $OFFSET
    ) {
      orderStatus
      billingCycleNumber
      campaignName
      campaignId
      orderType
      txnType
      currencySymbol
      clientName
      totalAmount
      merchantTxnId
      orderId
      customerId
    }
  }
`;
