import Vue from "vue";PAGE_NAME_403
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store/store';
import {AmplifyEventBus} from 'aws-amplify-vue';

import {
    PAGE_NAME_DASHBOARDS,
    PAGE_NAME_USERS,
    PAGE_NAME_CAMPAIGN_LISTS,
    PAGE_NAME_DISPOSITIONS_BUCKETS,
    PAGE_NAME_CAMPAIGNS,
    PAGE_NAME_CLIENTS,
    PAGE_NAME_DISPOSITIONS,
    PAGE_NAME_LOGIN,
    PAGE_NAME_403,
    PAGE_NAME_404,
    PAGE_NAME_500,
    PAGE_NAME_AGENTS,
    PAGE_NAME_PRODUCTIVITY,
    PAGE_NAME_CALLS,
    PAGE_NAME_PERSONS_SALES,
    PAGE_NAME_REPORTS,
    PAGE_NAME_HOLIDAYS,
    PAGE_NAME_KEYS,
    PAGE_NAME_PAYROLLS,
    PAGE_NAME_COMMISSIONS
} from '@/constants'


Vue.use(Router);

AmplifyEventBus.$on('authState', async (state) => {
    if (state === 'signedIn') {
        return new Promise((resolve, reject) => {
            store.dispatch('initialize').then(() => {
                if (store.getters.isAgent) {
                    router.push({name: PAGE_NAME_PRODUCTIVITY})
                }
                else {
                    router.push({name: PAGE_NAME_DASHBOARDS})
                }
                resolve()
            }).catch(() => {
                reject()
            })
        })
    }
});

const router = new Router({
    mode: "history",

    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;
        if (to.name === from.name && to.name === PAGE_NAME_PRODUCTIVITY) {
            return
        }
        else if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes: [
        {
            path: "/",
            redirect: {name: PAGE_NAME_DASHBOARDS},
            meta: {
                requiresAuth: true
            },
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: PAGE_NAME_DASHBOARDS,
                    path: "dashboards",
                     beforeEnter: (to,from, next) => {
                        if (store.getters.isAgent) {
                            next({name: PAGE_NAME_PRODUCTIVITY});
                        } else if (store.getters.isCommissionsReviewer) {
                            next({name: PAGE_NAME_COMMISSIONS});
                        } else {
                            next()
                        }
                    },
                    component: () => import("@/views/dashboards/DashboardPage"),

                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_USERS,
                    path: "users",
                    component: () => import("@/views/users/Users"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_CAMPAIGN_LISTS,
                    path: "campaign_lists",
                    component: () => import("@/views/campaign/CampaignLists"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_CAMPAIGNS,
                    path: "campaigns",
                    component: () => import("@/views/campaign/Campaigns"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_CLIENTS,
                    path: "clients",
                    component: () => import("@/views/clients/Clients"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_DISPOSITIONS,
                    path: "dispositions",
                    component: () => import("@/views/dispositions/Dispositions"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_DISPOSITIONS_BUCKETS,
                    path: "dispositions_buckets",
                    component: () => import("@/views/dispositions/DispositionsBucket"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_AGENTS,
                    path: "agents",
                    component: () => import("@/views/agents/Agents"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_PRODUCTIVITY,
                    path: "productivity",
                    component: () => import("@/views/productivity/ProductivityPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_CALLS,
                    path: "calls",
                    component: () => import("@/views/calls/CallsPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_PERSONS_SALES,
                    path: "person_sales",
                    component: () => import("@/views/persons-sales/PersonsSalesPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_REPORTS,
                    path: "reports/:query?",
                    component: () => import("@/views/reports/ReportsPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_HOLIDAYS,
                    path: "holidays",
                    component: () => import("@/views/holidays/Holidays"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_KEYS,
                    path: "keys",
                    component: () => import("@/views/keys/KeysPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_PAYROLLS,
                    path: "payrolls",
                    component: () => import("@/views/payrolls/PayrollsPage"),
                    meta: {requiresAuth: true},
                },
                {
                    name: PAGE_NAME_COMMISSIONS,
                    path: "commissions",
                    component: () => import("@/views/commissions/CommissionsPage"),
                    meta: {requiresAuth: true},
                },
            ],
        },
        {
            name: PAGE_NAME_LOGIN,
            path: "/login",
            component: () => import("@/views/authentication/FullLogin"),
            meta: {requiresLogout: true},
        },

        {
            name: PAGE_NAME_500,
            path: "/500",
            component: () => import("@/views/authentication/500"),
        },
        {
            name: PAGE_NAME_403,
            path: "/403",
            component: () => import("@/views/authentication/403"),
        },
        {
            name: PAGE_NAME_404,
            path: "*",
            component: () => import("@/views/authentication/404"),
        },
    ],
});

function processEnter(to, from, next) {
    store.dispatch('resetError')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next({name: PAGE_NAME_LOGIN})
        } else {
            next() // go to wherever I'm going
        }
    } else if (to.matched.some(record => record.meta.requiresLogout)) {
        if (store.getters.isAuthenticated) {
            store.dispatch('logout')
                .then(() => next({name: PAGE_NAME_LOGIN}))
                .catch(() => next({name: PAGE_NAME_LOGIN}))
        } else {
            next()
        }
    } else {
        next()
    }
}

router.beforeEach((to, from, next) => {
    store.dispatch("setCurrentPageName", to.name || "")
    if (!store.getters.isInitialized) {
        store.dispatch('initialize')
            .then(() => processEnter(to, from, next))
            .catch(() => processEnter(to, from, next))
    } else {
        processEnter(to, from, next)
    }
})


router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {

        store.dispatch("navigateStarted")
        // Start the route progress bar.
    }
    next();
});

router.afterEach((to) => {
    // Complete the animation of the route progress bar.
    store.dispatch("navigateEnded")
    store.dispatch('resetActionAnimation')
    Vue.nextTick(() => {
        document.title = to.name;
    });
});
export default router;
