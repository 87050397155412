import {listAgentPayments} from "../graphql/queries";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";

const moment = require('moment-timezone')
export default {
    state: {
        payments: [],
    },
    mutations: {
        SET_AGENT_PAYMENTS: (state, items) => state.payments = Object.freeze(items),
    },
    actions: {
        setAgentPayments: ({commit}, items) => {
            commit('SET_AGENT_PAYMENTS', items)
        },
        loadAgentPayments({dispatch, getters}) {
             let filter = {
                 agent_filter: getters.getAgentPaymentsFilter
             }
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listAgentPayments, {...filter}).then((items) => {
                    items.sort(function (a, b) {
                        let formatted_a = moment(a.payment_date).format('YYYY-MM-DD')
                        let formatted_b = moment(b.payment_date).format('YYYY-MM-DD')
                        return formatted_a < formatted_b ? 1 : (formatted_a > formatted_b ? -1 : 0)
                    })
                    dispatch('setAgentPayments', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        }
    },
    getters: {
        getAgentPaymentsFilter: (state, rootGetters) => {
            return rootGetters.getCurrentUserEmail
        },
        getAgentPaymentByID: state => (id) => state.payments.find((item) => item.id == id),
        getAllAgentPayments: state => state.payments,
        getAlreadyPaid: state => state.payments.reduce((accum, item) => {
            return accum + item.paid_hourly + item.paid_commissions
        }, 0) / 100,
        lastDebt: () => 0
    },
};
