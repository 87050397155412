import {listDispositions} from "../graphql/queries";
import {createDisposition, updateDisposition, deleteDisposition} from "../graphql/mutations";
import {
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
    executeGraphQLMutationUpdate
} from "@/utils/query_all_utils";
import {fetchItemsNextTokenGraphQL} from "@/utils/query_all_utils";

export default {
    state: {
        dispositions: [],
    },
    mutations: {
        SET_DISPOSITIONS: (state, dispositions) => state.dispositions = Object.freeze(dispositions),
    },
    actions: {
        setDispositions: ({commit}, dispositions) => {
            commit('SET_DISPOSITIONS', dispositions)
        },
        initializeDispositions: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listDispositions).then((items) => {
                    dispatch('setDispositions', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateDisposition({dispatch}, disposition) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationUpdate(updateDisposition, {updateDispositionInput: disposition}).then(() => {
                        dispatch('initializeDispositions').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        removeDisposition({dispatch}, dispositionID) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteDisposition, {id: dispositionID}).then(() => {
                        dispatch('initializeDispositions').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
        addDisposition({dispatch}, disposition) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationCreate(createDisposition, {createDispositionInput: disposition}).then(() => {
                        dispatch('initializeDispositions').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getAllDispositions: state => state.dispositions,
    },
};
