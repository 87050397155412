import {
    ADMINS_GROUP_NAME,
    LESSER_ADMINS_GROUP_NAME,
    USERS_GROUP_NAME,
    AGENTS_GROUP_NAME,
    SUPERVISORS_GROUP_NAME,
    COMMISSIONS_REVIEWER_GROUP_NAME,
    API_USER_GROUP_NAME,
    DEFAULT_TIMEZONE
} from '@/constants'
import {listAmUsers} from "../graphql/queries";
import {createAmUser, updateAmUser, deleteAmUser} from "../graphql/mutations";
import {API} from 'aws-amplify';
import {
    fetchItemsNextTokenGraphQL,
    fetchItemsNextTokenAPI,
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
    executeGraphQLMutationUpdate
} from "@/utils/query_all_utils";

export default {
    state: {
        users: [],
    },
    mutations: {
        SET_USERS: (state, users) => state.users = Object.freeze(users),
    },
    actions: {
        setUsers: ({commit}, users) => {
            commit('SET_USERS', users)
        },
        initializeUsers: ({dispatch, getters}) => {
            if (getters.getCurrentUserGroup == ADMINS_GROUP_NAME || getters.getCurrentUserGroup == LESSER_ADMINS_GROUP_NAME) {
                return new Promise((resolve, reject) => {
                    let apiName = 'AdminQueries';
                    let requestDataAllUsers = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        }
                    }

                    let requestDataAlLesserAdmins = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: LESSER_ADMINS_GROUP_NAME}
                    }

                    let requestDataAllAdmins = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: ADMINS_GROUP_NAME}
                    }

                    let requestDataAllAgents = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: AGENTS_GROUP_NAME}
                    }
                    let requestDataAllSupervisor = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: SUPERVISORS_GROUP_NAME}
                    }
                    let requestDataAllCommissionsReviewer = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: COMMISSIONS_REVIEWER_GROUP_NAME}
                    }
                    let requestDataAllApiUser = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        'queryStringParameters': {groupname: API_USER_GROUP_NAME}
                    }
                    Promise.all([
                        fetchItemsNextTokenAPI(apiName, '/listUsers', requestDataAllUsers, "Users"),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAllAdmins, "Users"),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAllAgents, "Users"),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAllSupervisor, "Users"),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAllCommissionsReviewer, "Users"),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAllApiUser, "Users"),
                        fetchItemsNextTokenGraphQL(listAmUsers),
                        fetchItemsNextTokenAPI(apiName, '/listUsersInGroup', requestDataAlLesserAdmins, "Users")
                    ]).then((responses) => {
                        var allUserItems = responses[0]
                        var adminUserItems = responses[1]
                        var agentUsersItems = responses[2]
                        var supervisorUsersItems = responses[3]
                        var commissionsReviewerUsersItems = responses[4]
                        var apiUserUsersItems = responses[5]
                        var items = responses[6]
                        var lesserAdminUserItems = responses[7]
                        let all_users = []
                        let admin_users = adminUserItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let lesser_admin_users = lesserAdminUserItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let agent_users = agentUsersItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let supervisor_users = supervisorUsersItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let commissions_reviewer_users = commissionsReviewerUsersItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let api_user_users = apiUserUsersItems.reduce((accum, item) => {
                            accum[item.Username] = 1
                            return accum
                        }, {})
                        let users_map = items.reduce((accum, item) => {
                            accum[item.id] = item
                            return accum
                        }, {})
                        for (let user of allUserItems) {
                            let new_user = {
                                'id': user.Attributes.find((item) => item.Name == 'sub').Value,
                                'Username': user.Username
                            }
                            if (user.Username in admin_users) {
                                new_user.role = ADMINS_GROUP_NAME
                            } else if (user.Username in agent_users) {
                                new_user.role = AGENTS_GROUP_NAME
                            } else if (user.Username in lesser_admin_users) {
                                new_user.role = LESSER_ADMINS_GROUP_NAME
                            } else if (user.Username in supervisor_users) {
                                new_user.role = SUPERVISORS_GROUP_NAME
                            } else if (user.Username in commissions_reviewer_users) {
                                new_user.role = COMMISSIONS_REVIEWER_GROUP_NAME
                            } else if (user.Username in api_user_users) {
                                new_user.role = API_USER_GROUP_NAME
                            } else {
                                new_user.role = USERS_GROUP_NAME
                            }
                            for (let attr of user.Attributes) {
                                if (attr.Name == 'email') {
                                    new_user['email'] = attr.Value
                                }
                                if (attr.Name == 'name') {
                                    new_user['name'] = attr.Value
                                }
                            }
                            if (new_user.id in users_map) {
                                new_user.timezone = users_map[new_user.id]['timezone']
                                new_user.campaigns = users_map[new_user.id]['campaigns'].replaceAll(' ', '').split(',').filter((item) => item.length > 0)
                                new_user.agents = users_map[new_user.id]['agents'] ? users_map[new_user.id]['agents'].replaceAll(' ', '').split(',').filter((item) => item.length > 0) : []
                                new_user.display_from = users_map[new_user.id]['display_from'] || 0
                                new_user.show_name = users_map[new_user.id]['show_name'] ? 1 : 0
                            } else {
                                new_user.timezone = DEFAULT_TIMEZONE
                                new_user.campaigns = []
                                new_user.agents = []
                                new_user.display_from = 0
                                new_user.show_name = 0

                            }
                            all_users.push(new_user)
                        }
                        dispatch('setUsers', all_users)
                        resolve()
                    }).catch((e) =>
                        reject(e)
                    )
                })
            }
            return new Promise((resolve) => {
                dispatch('setUsers', [])
                resolve()
            })
        },
        updateUser({dispatch, getters}, user) {
            dispatch('startActionAnimation')
            let existing_user = getters.getUserByID(user.id)
            //var update_group = !(existing_user.role == user.role)
            //var update_admin = !(existing_user.email == user.email && existing_user.name == user.name)
            let update_graphql = !((existing_user.timezone == user.timezone && existing_user.campaigns == user.campaigns) && existing_user.display_from == user.display_from && existing_user.agents == user.agents && existing_user.show_name == user.show_name)
            return new Promise((resolve, reject) => {
                if (update_graphql) {
                    let input_user = {
                        id: user.id,
                        display_from: user['display_from'] == '' ? 0 : parseInt(user['display_from']),
                        campaigns: user['campaigns'].join(','),
                        agents: user['agents'].join(','),
                        timezone: user['timezone'],
                        show_name: user['show_name'] ? 1 : 0
                    }
                    executeGraphQLMutationUpdate(updateAmUser, {updateAmUserInput: input_user}).then(() => {
                        dispatch('initializeUsers').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                } else {
                    dispatch('endActionAnimation')
                    resolve();
                }
            })
        },
        removeUser({dispatch, getters}, userID) {
            var user = getters.getUserByID(userID)
            if (user.email == 'systemadmin@dogezer.com') {
                alert("You can't remove that user")
                return;
            }
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    if (user.id) {
                        executeGraphQLMutationDelete(deleteAmUser, {id: user.id}).then(() => {
                            let apiName = 'AdminQueries';
                            let path = '/deleteUser';
                            let requestData = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: getters.getCurrentUserJWTToken
                                },
                                body: {
                                    'userName': user.Username
                                }
                            }
                            API.post(apiName, path, requestData).then(() => {
                                dispatch('initializeUsers').then(() => {
                                    dispatch('endActionAnimation')
                                    resolve()
                                })
                            })
                        }).catch((e) => {
                            dispatch('endActionAnimation')
                            dispatch('processError', e)
                            reject(e)
                        })
                    } else {
                        let apiName = 'AdminQueries';
                        let path = '/deleteUser';
                        let requestData = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: getters.getCurrentUserJWTToken
                            },
                            body: {
                                'userName': userID
                            }
                        }
                        API.post(apiName, path, requestData).then(() => {
                            dispatch('initializeUsers').then(() => {
                                dispatch('endActionAnimation')
                                resolve()
                            })
                        }).catch((e) => {
                            dispatch('endActionAnimation')
                            dispatch('processError', e)
                            reject(e)
                        })
                    }
                }
            )
        },
        setUserPassword({dispatch, getters}, params) {
            var user = getters.getUserByID(params.id)
            if (user.email == 'systemadmin@dogezer.com') {
                alert("You can't change that user")
                return;
            }
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let apiName = 'AdminQueries';
                    let path = '/adminSetUserPassword';
                    let requestData = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getters.getCurrentUserJWTToken
                        },
                        body: {
                            'username': user['Username'],
                            'password': params.password
                        }
                    }
                    API.post(apiName, path, requestData).then(() => {
                        dispatch('endActionAnimation')
                        resolve()
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })

                }
            )
        },
        addUser({dispatch, getters}, user) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                let apiName = 'AdminQueries';
                let path = '/createUser';
                let requestData = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: getters.getCurrentUserJWTToken
                    },
                    body: {
                        'email': user.email,
                        'password': user.password,
                        'name': user.name,
                        'group': user.role
                    }
                }
                API.post(apiName, path, requestData).then((response) => {
                    let input_user = {
                        id: response.result.User.Attributes.find((item) => item.Name == 'sub').Value,
                        campaigns: user['campaigns'].join(','),
                        agents: user['agents'].join(','),
                        display_from: user['display_from'] == '' ? 0 : parseInt(user['display_from']),
                        timezone: user['timezone']
                    }
                    executeGraphQLMutationCreate(createAmUser, {createAmUserInput: input_user}).then(() => {
                        dispatch('initializeUsers').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    })
                }).catch((e) => {
                    dispatch('endActionAnimation')
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
    },
    getters: {
        getAllUsers: state => state.users,
        getUserByID: state => (id) => state.users.find((item) => item.id == id),
        getUserByEmail: state => (email) => state.users.find((item) => item.email == email),
    },
};
