export default {
    state: {
        cad_rate: 1.22,
        cad_switcher: false
    },
    mutations: {
        SET_CAD_RATE: (state, rate) => state.cad_rate = rate,
        SET_CAD_SWITCHER: (state, switcher) => state.cad_switcher = switcher,
    },
    actions: {
        setCadRate: ({commit}, rate) => {
            commit('SET_CAD_RATE', rate)
        },
        setCadSwitcher: ({commit}, switcher) => {
            commit('SET_CAD_SWITCHER', switcher)
        }
    },
    getters: {
        getCadSwitcher: state => state.cad_switcher,
        dynamicCurrency: (state, getters) => (amount, default_currency) => {
            let displayAsCad = state.cad_switcher
            if (displayAsCad) {
                if (default_currency == 'CAD') {
                    return amount
                } else {
                    return getters.transferToCad(amount)
                }
            } else {
                if (default_currency == 'CAD') {
                    return getters.transferToUsd(amount)
                } else {
                    return amount
                }
            }

        },
        transferToCad: state => (valueInUsd) => {
            //Rounded to 3 integer after comma
            return Math.round((valueInUsd * state.cad_rate) * 1000) / 1000
        },
        transferToUsd: state => (valueInCad) => {
            //Rounded to 3 integer after comma
            return Math.round((valueInCad / state.cad_rate) * 1000) / 1000
        },
        getCadRate: state => state.cad_rate

    },
};
