export default {
    state: {
        animation_counter: 0
    },
    mutations: {
        START_ANIMATION: (state) => state.animation_counter += 1,
        END_ANIMATION: (state) => state.animation_counter -= 1,
        RESET_ANIMATION: (state) => state.animation_counter = 0
    },
    actions: {
        startActionAnimation: ({commit}) => commit("START_ANIMATION"),
        endActionAnimation: ({commit}) => commit("END_ANIMATION"),
        resetActionAnimation: ({commit}) => commit("RESET_ANIMATION"),
    },
    getters: {
        isActionAnimationActive: state => state.animation_counter > 0
    },
};
