import {listDashboardRecordsForCampaigns, listDashboardRecordsForCampaignsTopChart} from "../graphql/queries";
import {fetchItemsNextTokenGraphQL, toDPIndex} from "@/utils/query_all_utils";
import {CONST_OTHER_DISPOSITIONS_NAME} from '@/constants'
import {PAGE_NAME_PRODUCTIVITY, PAGE_NAME_REPORTS} from "../constants";
import {hideAllExcept} from "@/utils/hide_all_except";
const moment = require('moment-timezone')

let emptyDashboardsData = {
    calls_taken: undefined,
    minutes_used: undefined,
    avg_call_time: undefined,
    avg_queue_time: undefined,
    disposition_series: [],
    today_series: [],
    yesterday_series: [],
    hourly_series: [],
    total_series: [],
    abandons_series: [],
    queue1_series: [],
    queue2_series: [],
    queue3_series: [],
    queue4_series: [],
    call_duration_series: [],
    queue_time_series: [],
    averaged_hourly_volume: 0,
    xAxisNormal: [],
    xAxisHourly: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
}
let emptyDashboardDataTopGraph = {
    calls_taken: undefined,
    minutes_used: undefined,
    avg_call_time: undefined,
    avg_queue_time: undefined,
    xAxisNormal: [],
    last_month_series: [],

}


moment.tz.setDefault('America/Vancouver')

export default {
    state: {
        initial_dashboard_loaded: false,
        top_graph_loading: false,
        selectedCampaigns: undefined,
        selectedDates: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        selectedCompareDate: [moment().subtract(1, 'days').format("YYYY-MM-DD"), moment().subtract(1, 'days').format("YYYY-MM-DD")],
        selectedTimeZone: undefined,
        supportedTimezones: ['America/Vancouver', 'America/Los_Angeles', 'America/New_York', 'Canada/Mountain', 'America/Denver', 'America/Phoenix',
            'America/Winnipeg', 'America/Chicago', ' America/Toronto', 'Pacific/Honolulu', 'America/St_Johns', 'America/Anchorage', 'America/Halifax'],
        calculatedDashboardsData: emptyDashboardsData,
        calculatedDashboardsDataTopGraph: emptyDashboardDataTopGraph,
        queueTimeSelectedDataPoint: 'all',
        emptyDashboardsData: Object.freeze(emptyDashboardsData),
        overviewChartMode: 1
    },
    mutations: {
        SET_OVERVIEW_CHART_MODE: (state, value) => state.overviewChartMode = value,
        SET_INITIAL_DASHBOARD_LOADED: (state) => state.initial_dashboard_loaded = true,
        RESET_INITIAL_DASHBOARD_LOADED: (state) => state.initial_dashboard_loaded = false,
        UPDATE_QUEUE_TIME_DATA_POINT: (state, value) => state.queueTimeSelectedDataPoint = value,
        UPDATE_SELECTED_CAMPAIGNS: (state, value) => {
            state.selectedCampaigns = value
        },
        SET_CALCULATED_DATA: (state, value) => {
            state.calculatedDashboardsData = Object.freeze(value)
        },
        SET_CALCULATED_DATA_TOP_GRAPH: (state, value) => {
            state.calculatedDashboardsDataTopGraph = Object.freeze(value)
        },
        UPDATE_SELECTED_DATES: (state, value) => {
            state.selectedDates = value
        },
        UPDATE_SELECTED_COMPARE_DATES: (state, value) => {
            state.selectedCompareDate = value
        },
        UPDATE_SELECTED_TIMEZONE: (state, value) => {
            state.selectedTimeZone = value
            moment.tz.setDefault(value);
        },
        START_TOP_GRAPH_LOADING: (state) => state.top_graph_loading = true,
        STOP_TOP_GRAPH_LOADING: (state) => state.top_graph_loading = false
    },
    actions: {
        setOverviewChartMode: ({commit}, value) => commit('SET_OVERVIEW_CHART_MODE', value),
        updateQueueDataPoint: ({commit}, value) => {
            if (value != 'all') {
                commit('SET_OVERVIEW_CHART_MODE', 2)
            }
            commit('UPDATE_QUEUE_TIME_DATA_POINT', value)
        },
        setInitialDates: ({commit, state, getters}) => {
            if (moment.unix(getters.getCurrentUserDisplayFrom) > moment(state.selectedDates[0])) {
                commit('UPDATE_SELECTED_DATES', [moment.unix(getters.getCurrentUserDisplayFrom).format("YYYY-MM-DD"), moment.unix(getters.getCurrentUserDisplayFrom).format("YYYY-MM-DD")])
            } else {
                commit('UPDATE_SELECTED_DATES', [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")])
            }
            if (moment.unix(getters.getCurrentUserDisplayFrom) > moment(state.selectedCompareDate[0])) {
                commit('UPDATE_SELECTED_COMPARE_DATES', [moment.unix(getters.getCurrentUserDisplayFrom).format("YYYY-MM-DD"), moment.unix(getters.getCurrentUserDisplayFrom).format("YYYY-MM-DD")])
            } else {
                commit('UPDATE_SELECTED_COMPARE_DATES', [moment().subtract(1, 'days').format("YYYY-MM-DD"), moment().subtract(1, 'days').format("YYYY-MM-DD")])
            }
        },
        updateTimezone: ({commit, dispatch, state, getters}, value) => {
            if (value != state.selectedTimeZone) {
                let reinit_needed = true;
                if (value == undefined || state.selectedTimeZone == undefined) {
                    reinit_needed = false
                }
                commit('UPDATE_SELECTED_TIMEZONE', value)
                if (reinit_needed) {
                    commit('RESET_INITIAL_PRODUCTIVITY_LOADED')
                    commit('RESET_INITIAL_DASHBOARD_LOADED')
                    commit('RESET_INITIAL_REPORTS_LOADED')
                    if (getters.dashboardReloadNeeded) {
                        dispatch('initializeDashboards')
                        dispatch('initializeDashboardsTopGraph')
                    }
                    if (getters.productivityReloadNeeded) {
                        dispatch('initializeProductivity')
                    }
                    if (getters.callsReloadNeeded) {
                        dispatch('resetCalls')
                        dispatch('loadCalls')
                    }
                    if (getters.personsSalesReloadNeeded) {
                        dispatch('resetPersonsSales')
                        dispatch('loadPersonsSales')
                    }
                    if (getters.reportsReloadNeeded) {
                        dispatch('loadAndInitializeReports')
                    }
                }
            }
        },
        updateSelectedCampaigns: ({commit, dispatch, state, getters}, value) => {
            var value_sorted = value ? value.sort() : value
            var existing_sorted = state.selectedCampaigns ? state.selectedCampaigns.sort() : state.selectedCampaigns
            if (JSON.stringify(value_sorted) != JSON.stringify(existing_sorted)) {
                let reinit_needed = true;
                if (value == undefined || state.selectedCampaigns == undefined) {
                    reinit_needed = false
                }
                commit('UPDATE_SELECTED_CAMPAIGNS', value)
                if (reinit_needed) {
                    if (getters.dashboardReloadNeeded) {
                        dispatch('initializeDashboards')
                        dispatch('initializeDashboardsTopGraph')
                    }
                    if (getters.callsReloadNeeded) {
                        dispatch('resetCalls')
                        dispatch('loadCalls')
                        commit('RESET_INITIAL_DASHBOARD_LOADED')
                    }
                }
            }
        },
        setDashboardMainDatesNoUpdate: ({commit, dispatch}, value) => {
            commit('UPDATE_SELECTED_DATES', value)
            dispatch('resetCalls')
        },
        setDashboardMainDates: ({state, commit, dispatch, getters}, value) => {
            let timeDependentPages = [PAGE_NAME_REPORTS, PAGE_NAME_PRODUCTIVITY]
            if (JSON.stringify(value) != JSON.stringify(state.selectedDates) || timeDependentPages.includes(getters.getCurrentPageName)) {
                commit('UPDATE_SELECTED_DATES', value)
                commit('RESET_INITIAL_PRODUCTIVITY_LOADED')
                commit('RESET_INITIAL_DASHBOARD_LOADED')
                commit('RESET_INITIAL_REPORTS_LOADED')
                dispatch('resetCalls')
                dispatch('resetPersonsSales')
                
                if (getters.dashboardReloadNeeded) {
                    dispatch('initializeDashboards')
                    dispatch('loadRefundsCancelsFirst')
                }
                if (getters.productivityReloadNeeded) {
                    dispatch('initializeProductivity')
                }
                if (getters.callsReloadNeeded) {
                    dispatch('loadCalls')
                }
                if (getters.personsSalesReloadNeeded) {
                    dispatch('loadPersonsSales')
                }
                if (getters.reportsReloadNeeded) {
                    dispatch('loadAndInitializeReports')
                }
            }
        },
        setCompareDashboardDates: ({state, commit, dispatch, getters}, value) => {
            if (JSON.stringify(value) != JSON.stringify(state.selectedCompareDate)) {
                commit('UPDATE_SELECTED_COMPARE_DATES', value)
                commit('RESET_INITIAL_DASHBOARD_LOADED')
                commit('RESET_INITIAL_REPORTS_LOADED')
                if (getters.dashboardReloadNeeded) {
                    dispatch('initializeDashboards')
                    dispatch('loadRefundsCancelsSecond')
                }
                if (getters.reportsReloadNeeded) {
                    dispatch('loadAndInitializeReports')
                }
            }
        },
        initializeDashboards({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                    commit("START_DASHBOARD_LOADING")
                    let lowest_date = moment(getters.getDashboardMainDates[0] < getters.getCompareDashboardDates[0] ? getters.getDashboardMainDates[0] : getters.getCompareDashboardDates[0]).startOf('day')
                    let highest_date = moment(getters.getDashboardMainDates[1] > getters.getCompareDashboardDates[1] ? getters.getDashboardMainDates[1] : getters.getCompareDashboardDates[1]).endOf('day')
                    let lowest_dp_index = Math.floor(toDPIndex(lowest_date))
                    let highest_dp_index = Math.ceil(toDPIndex(highest_date))
                    if (getters.getCurrentUserDisplayFrom) {
                        let min_data_point = Math.floor(toDPIndex(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day')))
                        if (lowest_dp_index < min_data_point) {
                            lowest_dp_index = min_data_point
                        }
                        if (highest_dp_index < min_data_point) {
                            highest_dp_index = min_data_point
                        }
                    }


                    var filter = {dp_start: lowest_dp_index, dp_end: highest_dp_index}
                    const query = listDashboardRecordsForCampaigns
                    if (getters.selectedCampaigns.length == 0) {
                        dispatch('calculateMetrics', [])
                        resolve()
                        return
                    }
                    filter['campaign'] = []
                    for (let campaign of getters.selectedCampaigns) {
                        let campaign_record = getters.getCampaignByID(campaign)
                        if (campaign_record) {
                            filter['campaign'].push("'" + campaign_record.name + "'")
                        }
                    }
                    filter['campaign'] = "(" + filter['campaign'].join(",") + ")"
                    filter['agents'] = '1 = 1'
                    if (getters.getCurrentUserAgents.length > 0) {
                        filter['agents'] = "agent in (" + getters.getCurrentUserAgents.map((item) => {
                            const agent = getters.getAgentByID(item)
                            return "'" + agent.email + "'"
                        }).join(',') + ')'
                    }

                    filter['bucket_case'] = getters.getAllDispositionBuckets.reduce((accum, item) => {
                        accum += item.dispositions.reduce((subaccum, subitem) => {
                            if (item.campaigns.length == 0) {
                                subaccum += ` WHEN DISPOSITION = '${subitem.name}'  THEN '${item.name}' `
                            } else {
                                subaccum += ` WHEN DISPOSITION = '${subitem.name}' AND campaign in (${item.campaigns.map((item) => "'" + getters.getCampaignByID(item).name + "'").join(",")})  THEN '${item.name}' `
                            }
                            return subaccum
                        }, "")
                        return accum
                    }, "") + " ELSE 'Other' END"
                    fetchItemsNextTokenGraphQL(query, {
                        ...filter
                    }, 5000).then((items) => {
                        dispatch('calculateMetrics', items)
                        resolve()
                    }).catch((e) => {
                        commit("STOP_DASHBOARD_LOADING")
                        dispatch("processError", e)
                        reject(e)
                    })
                }
            )
        },
        initializeDashboardsTopGraph({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                    commit("START_TOP_GRAPH_LOADING")
                    let today = moment().endOf('day')
                    let month_ago = moment().subtract(30, 'days').startOf('day')


                    let lowest_dp_index = Math.floor(toDPIndex(month_ago))
                    let highest_dp_index = Math.ceil(toDPIndex(today))
                    if (getters.getCurrentUserDisplayFrom) {
                        let min_data_point = Math.floor(toDPIndex(moment.unix(getters.getCurrentUserDisplayFrom).startOf('day')))
                        if (lowest_dp_index < min_data_point) {
                            lowest_dp_index = min_data_point
                        }
                        if (highest_dp_index < min_data_point) {
                            highest_dp_index = min_data_point
                        }
                    }

                    var filter = {dp_start: lowest_dp_index, dp_end: highest_dp_index}
                    let query = listDashboardRecordsForCampaignsTopChart
                    if (getters.selectedCampaigns.length == 0) {
                        dispatch('calculateTopMetrics', [])
                        resolve()
                        return
                    }
                    filter['campaign'] = []
                    for (let campaign of getters.selectedCampaigns) {
                        let campaign_record = getters.getCampaignByID(campaign)
                        if (campaign_record) {
                            filter['campaign'].push("'" + campaign_record.name + "'")
                        }
                    }
                    filter['campaign'] = "(" + filter['campaign'].join(",") + ")"

                    filter['agents'] = '1 = 1'
                    if (getters.getCurrentUserAgents.length > 0) {
                        filter['agents'] = "agent in (" + getters.getCurrentUserAgents.map((item) => {
                            const agent = getters.getAgentByID(item)
                            return "'" + agent.email + "'"
                        }).join(',') + ')'
                    }

                    filter['dateOffset'] = moment().utcOffset() / 60
                    fetchItemsNextTokenGraphQL(query, {
                        ...filter
                    }, 5000).then((items) => {
                        dispatch('calculateTopMetrics', items)
                        resolve()
                    }).catch((e) => {
                        commit('STOP_TOP_GRAPH_LOADING')
                        dispatch("processError", e)
                        reject(e)
                    })

                }
            )
        },
        calculateTopMetrics({commit, getters}, items) {

            let total_calls = 0;
            let total_duration = 0;
            let total_queue_time = 0;
            let xAxisNormal = [];
            let mappingNormal = {}
            let callsToday = 0;
            let lowestStartDate
            let lowest_normal_date
            let highest_normal_date
            let hour
            let last_day
            let current_day

            let today_start_index = toDPIndex(moment().startOf('day'))
            let today_end_index = today_start_index + 23
            let calculateEndDate = moment().format("YYYY-MM-DD")
            let calculateStartDate = moment().subtract(30, 'days').format("YYYY-MM-DD")

            lowestStartDate = moment(calculateStartDate).startOf('day')
            lowest_normal_date = Math.floor(toDPIndex(moment(calculateStartDate).startOf('day')))
            highest_normal_date = Math.floor(toDPIndex(moment(calculateEndDate).endOf('day')))

            last_day = undefined;
            for (hour = lowest_normal_date; hour < highest_normal_date; hour++) {
                mappingNormal[hour] = Math.floor((hour - lowest_normal_date) / 24)
                current_day = lowestStartDate.format("MMM D")
                if (current_day != last_day) {
                    xAxisNormal.push(current_day)
                    last_day = current_day
                }
                lowestStartDate.add(1, "hours")
            }


            let today_series = Array(xAxisNormal.length).fill(0);

            for (let record of items) {
                let campaign = getters.getAllCampaigns.find(campaign => campaign.name == record.campaign)
                let capped_to_5_min = []
                if (campaign !== undefined) {
                    capped_to_5_min = campaign.capped_to_5_min ? campaign.capped_to_5_min : []
                }
                let dp_index = record.day_dp_index + 24
                if (dp_index >= today_start_index && dp_index <= today_end_index) {
                    callsToday += record.COUNT
                }
                if (dp_index >= lowest_normal_date && dp_index <= highest_normal_date) {
                    total_calls += record.COUNT
                    total_duration += record.TOTAL_TIME

                    if (capped_to_5_min.indexOf(moment.unix(dp_index * 60 * 60).format("YYYY-MM-DD")) > -1 && record.QUEUE_WAIT_TIME_5_MIN !== undefined) {
                        total_queue_time += record.QUEUE_WAIT_TIME_5_MIN
                    } else {
                        total_queue_time += record.QUEUE_WAIT_TIME
                    }
                    today_series[mappingNormal[dp_index]] += record.COUNT;
                }
            }

            commit('SET_CALCULATED_DATA_TOP_GRAPH', {
                calls_taken: total_calls,
                minutes_used: total_duration,
                avg_call_time: total_duration / total_calls,
                avg_queue_time: total_queue_time / total_calls,
                last_month_series: today_series,
                xAxisNormal: xAxisNormal,
                calls_today: callsToday
            })
            commit('STOP_TOP_GRAPH_LOADING')
        },
        calculateMetrics({commit, getters}, items) {
            let total_calls = 0;
            let total_duration = 0;
            let total_queue_time = 0;
            let xAxisNormal = [];
            let mappingNormal = {}
            let mappingCompared = {}
            let lowestStartDate
            let lowest_normal_date
            let highest_normal_date
            let lowest_compare_date
            let highest_compare_date
            let lowest_ever_index
            let highest_ever_index
            let normal_offset
            let compare_offset
            let is_hourly
            let hour
            let last_day
            let current_day
            let calculateStartDate = getters.getDashboardMainDates[0]
            let calculateEndDate = getters.getDashboardMainDates[1]
            let calculateCompareStartDate = getters.getCompareDashboardDates[0]
            let calculateCompareEndDate = getters.getCompareDashboardDates[1]
            lowestStartDate = moment(calculateStartDate).startOf('day') < moment(calculateCompareStartDate).startOf('day') ? moment(calculateStartDate).startOf('day') : moment(calculateCompareStartDate).startOf('day')
            lowest_normal_date = Math.floor(toDPIndex(moment(calculateStartDate).startOf('day')))
            highest_normal_date = Math.floor(toDPIndex(moment(calculateEndDate).endOf('day')))
            lowest_compare_date = Math.floor(toDPIndex(moment(calculateCompareStartDate).startOf('day')))
            highest_compare_date = Math.floor(toDPIndex(moment(calculateCompareEndDate).endOf('day')))
            lowest_ever_index = lowest_normal_date > lowest_compare_date ? lowest_compare_date : lowest_normal_date
            highest_ever_index = highest_normal_date > highest_compare_date ? highest_normal_date : highest_compare_date
            normal_offset = lowest_normal_date - lowest_ever_index;
            compare_offset = lowest_compare_date - lowest_ever_index
            is_hourly = false
            if ((calculateStartDate == calculateEndDate) &&
                (calculateCompareStartDate == calculateCompareEndDate)) {
                emptyDashboardsData.xAxisHourly.forEach((acc, index) => {
                    xAxisNormal.push(moment({hour: index}).format('h:mm A'));
                })
                for (hour of emptyDashboardsData.xAxisHourly) {
                    mappingNormal[lowest_normal_date + hour] = hour
                    mappingCompared[lowest_compare_date + hour] = hour
                }
                is_hourly = true;
            } else {
                last_day = undefined;
                for (hour = lowest_ever_index; hour < highest_ever_index; hour++) {
                    mappingNormal[hour] = Math.floor((hour - lowest_normal_date + normal_offset) / 24)
                    mappingCompared[hour] = Math.floor((hour - lowest_compare_date + compare_offset) / 24)
                    current_day = lowestStartDate.format("MMM D")
                    if (current_day != last_day) {
                        xAxisNormal.push(current_day)
                        last_day = current_day
                    }
                    lowestStartDate.add(1, "hours")
                }
            }


            let disposition_series = []
            let today_series = Array(xAxisNormal.length).fill(0);
            let yesterday_series = Array(xAxisNormal.length).fill(0);
            let hourly_series = Array(24).fill(0);
            let total_series = Array(xAxisNormal.length).fill(0);
            let abandons_series = Array(xAxisNormal.length).fill(0);
            let queue1_series = Array(xAxisNormal.length).fill(0);
            let queue2_series = Array(xAxisNormal.length).fill(0);
            let queue3_series = Array(xAxisNormal.length).fill(0);
            let queue4_series = Array(xAxisNormal.length).fill(0);
            let call_duration_series = Array(4).fill(0);
            let queue_time_series = Array(4).fill(0);
            let disposition_records = {}
            let validDispositionBuckets = getters.getAllDispositionBuckets.filter((bucket) => {
                if (bucket.campaigns.length === 0) {
                    return true
                } else {
                    return getters.selectedCampaigns.some((campaign) => bucket.campaigns.includes(campaign))
                }

            })
            for (let disposition_bucket of validDispositionBuckets) {
                disposition_records[disposition_bucket.name] = Array(xAxisNormal.length).fill(0)
            }
            for (let record of items) {
                let campaign = getters.getAllCampaigns.find(campaign => campaign.name == record.campaign)
                let capped_to_5_min = []
                if (campaign !== undefined) {
                    capped_to_5_min = campaign.capped_to_5_min ? campaign.capped_to_5_min : []
                }
                let dp_index = record.dp_index
                if (dp_index >= lowest_normal_date && dp_index <= highest_normal_date) {
                    total_calls += record.COUNT
                    total_duration += record.TOTAL_TIME

                    if (capped_to_5_min.indexOf(moment.unix(dp_index * 60 * 60).format("YYYY-MM-DD")) > -1 && record.QUEUE_WAIT_TIME_5_MIN !== undefined) {
                        total_queue_time += record.QUEUE_WAIT_TIME_5_MIN
                    } else {
                        total_queue_time += record.QUEUE_WAIT_TIME
                    }
                    total_series[mappingNormal[dp_index]] += Math.round(record.TOTAL_TIME / 60);
                    if (is_hourly) {
                        hourly_series[mappingNormal[dp_index]] += record.COUNT;
                    } else {
                        hourly_series[(dp_index + (moment().utcOffset() / 60)) % 24] += record.COUNT;
                    }
                    today_series[mappingNormal[dp_index]] += record.COUNT;
                    abandons_series[mappingNormal[dp_index]] += record.ABANDONED;
                    if (capped_to_5_min.indexOf(moment.unix(dp_index * 60 * 60).format("YYYY-MM-DD")) > -1 && record.QUEUE_WAIT_TIME_5_MIN_1 !== undefined && record.QUEUE_WAIT_TIME_5_MIN_2 !== undefined && record.QUEUE_WAIT_TIME_5_MIN_3 !== undefined && record.QUEUE_WAIT_TIME_5_MIN_4 !== undefined) {
                        queue1_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_5_MIN_1;
                        queue2_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_5_MIN_2;
                        queue3_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_5_MIN_3;
                        queue4_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_5_MIN_4;
                        queue_time_series[0] += record.QUEUE_WAIT_TIME_5_MIN_1;
                        queue_time_series[1] += record.QUEUE_WAIT_TIME_5_MIN_2;
                        queue_time_series[2] += record.QUEUE_WAIT_TIME_5_MIN_3;
                        queue_time_series[3] += record.QUEUE_WAIT_TIME_5_MIN_4;
                    } else {
                        queue1_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_1;
                        queue2_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_2;
                        queue3_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_3;
                        queue4_series[mappingNormal[dp_index]] += record.QUEUE_WAIT_TIME_4;
                        queue_time_series[0] += record.QUEUE_WAIT_TIME_1;
                        queue_time_series[1] += record.QUEUE_WAIT_TIME_2;
                        queue_time_series[2] += record.QUEUE_WAIT_TIME_3;
                        queue_time_series[3] += record.QUEUE_WAIT_TIME_4;
                    }

                    call_duration_series[0] += record.TOTAL_TIME_1;
                    call_duration_series[1] += record.TOTAL_TIME_2;
                    call_duration_series[2] += record.TOTAL_TIME_3;
                    call_duration_series[3] += record.TOTAL_TIME_4;
                    if (record.DISPOSITION_BUCKET != CONST_OTHER_DISPOSITIONS_NAME) {
                        disposition_records[record.DISPOSITION_BUCKET][mappingNormal[dp_index]] += record.COUNT
                    }
                }
                if (dp_index >= lowest_compare_date && dp_index <= highest_compare_date) {
                    yesterday_series[mappingCompared[dp_index]] += record.COUNT;
                }
            }
            for (let key in disposition_records) {
                disposition_series.push({name: key, data: disposition_records[key]})
            }
            let averaged_hourly_volume = 0;
            for (let item of hourly_series) {
                averaged_hourly_volume += item
            }
            averaged_hourly_volume = averaged_hourly_volume / 24;
            commit('SET_CALCULATED_DATA', {
                calls_taken: total_calls,
                minutes_used: total_duration,
                avg_call_time: total_duration / total_calls,
                avg_queue_time: total_queue_time / total_calls,
                disposition_series: Object.freeze(disposition_series),
                today_series: today_series,
                yesterday_series: yesterday_series,
                hourly_series: hourly_series,
                total_series: total_series,
                abandons_series: abandons_series,
                averaged_hourly_volume: averaged_hourly_volume,
                queue1_series: queue1_series,
                queue2_series: queue2_series,
                queue3_series: queue3_series,
                queue4_series: queue4_series,
                call_duration_series: call_duration_series,
                queue_time_series: queue_time_series,
                xAxisNormal: xAxisNormal,
                xAxisHourly: emptyDashboardsData.xAxisHourly,
            })
            commit('STOP_DASHBOARD_LOADING')

            commit('SET_INITIAL_DASHBOARD_LOADED')
        },

    },
    getters: {
        properSeriesName: () => (dates) => {
            let currentWeek = moment(dates[0])
                .isSame(moment()
                    .startOf('week'), 'day') && moment(dates[1])
                .isSame(moment()
                    .endOf('week'), 'day')
            let nextWeek = moment(dates[0])
                .isSame(moment().add(1, 'week')
                    .startOf('week'), 'day') && moment(dates[1])
                .isSame(moment().add(1, 'week')
                    .endOf('week'), 'day')
            let lastWeek = moment(dates[0])
                .isSame(moment().subtract(1, 'week')
                    .startOf('week'), 'day') && moment(dates[1])
                .isSame(moment().subtract(1, 'week')
                    .endOf('week'), 'day')


            let currentMonth = moment(dates[0])
                .isSame(moment()
                    .startOf('month'), 'day') && moment(dates[1])
                .isSame(moment()
                    .endOf('month'), 'day')
            let nextMonth = moment(dates[0])
                .isSame(moment().add(1, 'month')
                    .startOf('month'), 'day') && moment(dates[1])
                .isSame(moment().add(1, 'month')
                    .endOf('month'), 'day')
            let lastMonth = moment(dates[0])
                .isSame(moment().subtract(1, 'month')
                    .startOf('month'), 'day') && moment(dates[1])
                .isSame(moment().subtract(1, 'month')
                    .endOf('month'), 'day')


            let calendar_obj = {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'MMMM Do YYYY',
                lastDay: '[Yesterday]',
                lastWeek: 'MMMM Do YYYY',
                sameElse: 'MMMM Do YYYY'
            }


            if (dates[0] === dates[1]) {
                return moment(dates[0]).calendar(calendar_obj)
            } else {
                if (currentWeek) {
                    return 'Current Week'
                }
                if (nextWeek) {
                    return 'Next Week'
                }
                if (lastWeek) {
                    return 'Last Week'
                }
                if (currentMonth) {
                    return 'Current Month'
                }
                if (nextMonth) {
                    return 'Next Month'
                }
                if (lastMonth) {
                    return 'Last Month'
                }
                return dates.map(item => moment(item).calendar(calendar_obj)).join(' ~ ')
            }
        },
        selectedCampaigns: state => state.selectedCampaigns,
        crmCampaignFilter: (state,getters) => {
            const allCampaigns = getters.getAllCampaigns
            const activeCampaigns = getters.selectedCampaigns
            const activeCampaignsDict = activeCampaigns.reduce((accum, item) => {
                accum[item] = 1
                return accum
            }, {})
            let result = {}
            allCampaigns.forEach((item) => {
                if (!activeCampaignsDict[item.id])
                {
                    if (item.CRMFilter)
                    {
                        const items = item.CRMFilter.split(',').map(el => parseInt(el))
                        result = items.reduce((ac, el) =>
                        {
                            ac[el] = 1
                            return ac
                        }, result)
                    }
                }
            })
            return result
        },
        getDashboardMainDates: state => state.selectedDates,
        getCompareDashboardDates: state => state.selectedCompareDate,
        isCampaignFilterEnabled: (state, getters) => {
            if (getters.getCurrentUserCampaigns.length) {
                return true
            } else {
                return state.selectedCampaigns.length < getters.getAllActiveCampaigns.length
            }
        },
        callsTaken: (state) => state.calculatedDashboardsData.calls_taken,
        callsToday: (state) => state.calculatedDashboardsDataTopGraph.calls_today,
        callsTakenTopGraph: (state) => state.calculatedDashboardsDataTopGraph.calls_taken,
        minutesUsed: (state) => state.calculatedDashboardsData.minutes_used,
        minutesUsedTopGraph: (state) => state.calculatedDashboardsDataTopGraph.minutes_used,
        avgCallTime: (state) => state.calculatedDashboardsData.avg_call_time,
        avgQueueTime: (state) => state.calculatedDashboardsData.avg_queue_time,
        avgQueueTimeTopGraph: (state) => state.calculatedDashboardsDataTopGraph.avg_queue_time,
        topGraphLoadingState: (state) => state.top_graph_loading,
        dispositionsGraphSeries: (state) => {
            return state.calculatedDashboardsData.disposition_series
        },
        todayGraphSeries: (state, getters) => {


            return [
                {
                    name: getters.properSeriesName(state.selectedCompareDate),
                    data: state.calculatedDashboardsData.yesterday_series,
                },
                {
                    name: getters.properSeriesName(state.selectedDates),
                    data: state.calculatedDashboardsData.today_series,
                },
            ]
        },
        hourlyGraphSeries: (state) => {
            return [
                {
                    name: "Calls Per Hour",
                    data: state.calculatedDashboardsData.hourly_series
                }]
        },
        totalGraphSeries: (state) => {
            return [
                {
                    name: "Calls volume in Minutes",
                    data: state.calculatedDashboardsData.total_series,
                },
            ]
        },
        lastMonthGraphSeries: (state) => {
            return [
                {
                    name: '',
                    data: state.calculatedDashboardsDataTopGraph.last_month_series,
                },
            ]
        },
        abandonsGraphSeries: (state) => {
            return [
                {
                    name: "Abandoned Calls",
                    data: state.calculatedDashboardsData.abandons_series,
                },
            ]
        },
        queueTimeSelectedDataPointIndex: (state) => {
            return state.queueTimeSelectedDataPoint
        },
        queueGraphSeries: (state) => {
            let all_data_points = [
                {
                    name: "< 1 min",
                    data: state.calculatedDashboardsData.queue1_series,
                },
                {
                    name: "< 2 min",
                    data: state.calculatedDashboardsData.queue2_series,
                },
                {
                    name: "< 4 min",
                    data: state.calculatedDashboardsData.queue3_series,
                },
                {
                    name: "> 4 min",
                    data: state.calculatedDashboardsData.queue4_series,
                },
            ]
            if (state.queueTimeSelectedDataPoint === 'all') {
                return all_data_points
            } else {
                return [all_data_points[state.queueTimeSelectedDataPoint]]
            }

        },
        callDurationGraphSeries: (state) => {
            return state.calculatedDashboardsData.call_duration_series
        },
        queueTimeGraphSeries: (state) => {
            return state.calculatedDashboardsData.queue_time_series
        },
        lastMonthGraphOptions: (state) => {
            return {
                tooltip: {
                    theme: "dark",
                    fillColors: ["#7460ee"],
                },

                chart: {
                    height: 140,
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    type: 'area',
                    zoom: {
                        enabled: false
                    }
                },
                colors: ["#7460ee"],
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 3,
                    colors: ["#7460ee"],
                    strokeColor: "#7460ee",
                    strokeWidth: 1
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: state.calculatedDashboardsDataTopGraph.xAxisNormal,
                    labels: {
                        show: false,
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },
                    tooltip: {
                        enabled: false
                    }
                }
            }

        },
        dispositionsGraphOptions: (state, getters) => {
            return {
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                },
                chart: {
                    height: 600,
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    horizontal: true,
                    type: 'bar',
                    stacked: true,
                    zoom: {
                        enabled: true
                    },
                    events: {
                        legendClick: function (chartContext, seriesIndex, config) {
                            const {series} = config.config;
                            const selectedSeriesItemName = series[seriesIndex].name;
                            chartContext.legend.legendHelpers.hideAllExcept = hideAllExcept
                            chartContext.legend.legendHelpers.hideAllExcept(selectedSeriesItemName)
                        }
                    }
                },
                legend: {
                    show: true,
                    onItemClick: {
                        toggleDataSeries: false
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: getters.getAllDispositionBucketsColors,
                markers: {
                    size: 15,
                    colors: getters.getAllDispositionBucketsColors,
                    strokeColor: getters.getAllDispositionBucketsColors,
                    strokeWidth: 10
                },
                dataLabels: {
                    position: top,
                    enabled: true,
                    formatter: function (value) {
                        if (value != 0) {
                            return value;
                        }
                    },
                    style: {
                        fontSize: '12px',
                    }
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: state.calculatedDashboardsData.xAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },
                },
            }
        },
        todayGraphOptions: (state) => {
            return {
                chart: {
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: ["#7460ee", "#398bf7"],
                legend: {

                    show: true,
                    onItemClick: {
                        toggleDataSeries: false
                    },
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: state.calculatedDashboardsData.xAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: '10px',
                        },
                        formatter: (value) => {

                            if (typeof value === 'string') {
                                return value
                            } else {
                                return [value[0] ? value[0] : '', value[1] ? value[1] : '']


                            }
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: '10px',
                        },

                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: () => 'Calls Amount',
                        },
                    },
                },
            }
        },
        hourlyGraphOptions: (state) => {
            return {
                annotations: {
                    yaxis: [
                        {
                            y: state.calculatedDashboardsData.averaged_hourly_volume,
                            borderColor: '#ef5350',
                            label: {
                                borderColor: '#ef5350',
                                style: {
                                    color: '#fff',
                                    background: '#ef5350'
                                },
                                text: "Avg. Hourly Calls Volume: " + state.calculatedDashboardsData.averaged_hourly_volume
                            }
                        }
                    ]
                },
                chart: {
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: "#398bf7",
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: state.calculatedDashboardsData.xAxisHourly,
                    labels: {
                        formatter: (value) => {
                            return `${(value % 12) || 12}:00 ${value < 12 ? " AM" : " PM"}`;

                        },
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },


                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                },
            }
        },
        totalGraphOptions: (state, getters) => {
            var average = Math.round(getters.totalGraphSeries[0].data.reduce((accum, item) => accum + item, 0) / getters.totalGraphSeries[0].data.length)
            return {
                annotations: {
                    yaxis: [
                        {
                            y: average,
                            borderColor: '#ef5350',
                            label: {
                                borderColor: '#ef5350',
                                style: {
                                    color: '#fff',
                                    background: '#ef5350'
                                },
                                text: `Avg. Total Minutes: ${average} min`
                            }
                        }
                    ]
                },
                chart: {
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: "#398bf7",
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: state.calculatedDashboardsData.xAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },


                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                },
            }
        },
        abandonsGraphOptions: (state) => {
            return {
                annotations: {
                    yaxis: [
                        {
                            y: 60,
                            borderColor: '#ef5350',
                            label: {
                                borderColor: '#ef5350',
                                style: {
                                    color: '#fff',
                                    background: '#ef5350'
                                },
                                text: `Avg. Abandoned Calls Volume - ${60}`
                            }
                        }
                    ]
                },
                chart: {
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: "#398bf7",
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: state.calculatedDashboardsData.xAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        }
                    }
                }
            }
        },
        queueGraphOptions: (state) => {
            let all_colors = [
                "rgb(116, 90, 242)",
                "rgb(30, 136, 229)",
                "#06d79c",
                "#ef5350",
            ]
            return {

                chart: {
                    height: 410,
                    type: "bar",
                    fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 3,
                    strokeColors: "transparent",
                },


                colors: state.queueTimeSelectedDataPoint === 'all' ? all_colors : all_colors[state.queueTimeSelectedDataPoint],
                legend: {
                    show: true,
                },
                grid: {
                    show: true,
                    strokeDashArray: 0,
                    borderColor: "rgba(0,0,0,0.1)",
                    yaxis: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: state.calculatedDashboardsData.xAxisNormal,
                    labels: {
                        style: {
                            colors: "#a1aab2",
                            fontSize: 10
                        },

                    },


                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#a1aab2",
                        },
                    },
                },
                tooltip: {
                    theme: "dark",
                    fillColors: ["#e9edf2", "#398bf7", "#7460ee"],
                },
            }
        },
        callDurationGraphOptions: () => {
            return {
                chart: {
                    fontFamily: "Montserrat,sans-serif",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70px",
                        },
                    },
                },
                tooltip: {
                    fillSeriesColor: false,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                legend: {
                    show: false,
                },
                labels: ["< 4 min", "< 6 min", "< 8 min", "> 8 min"],
                colors: [
                    "#398bf7",
                    "#563dea",
                    "#06d79c",
                    "#ef5350",
                ],
            }
        },
        queueTimeGraphOptions: () => {
            return {
                chart: {
                    fontFamily: "Montserrat,sans-serif",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                    events: {},
                },
                states: {

                    active: {
                        filter: {
                            type: 'darken',
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: "70px",
                        },
                    },
                },
                tooltip: {
                    fillSeriesColor: false,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                legend: {
                    show: false,
                },
                labels: ["< 1 min", "< 2 min", "< 4 min", "> 4 min"],
                colors: [
                    "rgb(116, 90, 242)",
                    "rgb(30, 136, 229)",
                    "#06d79c",
                    "#ef5350",
                ],
            }

        },
        getSupportedTimezones: state => state.supportedTimezones,
        getCurrentTimezone: state => state.selectedTimeZone,
        getEmptyDashboardsData: state => state.emptyDashboardsData,
        getInitialDashboardLoaded: state => state.initial_dashboard_loaded,
        getOverviewChartMode: state => state.overviewChartMode,
        CancelsByAgentTypeGraphOptions: (state, getters) => {
            return {
                chart: {
                    fontFamily: "Montserrat,sans-serif",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                    events: {},
                },
                states: {

                    active: {
                        filter: {
                            type: 'darken',
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: "70px",
                        },
                    },
                },
                tooltip: {
                    fillSeriesColor: false,
                    y: {
                        formatter: function (value, object) {
                            return `Count: ${value} ($${getters.getCancelsByAgentType[["Agent Call Refunds", "Email Refunds", "Return Refunds", "Alerts", "Other"][object.seriesIndex]].$.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })})`;
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                legend: {
                    show: false,
                },
                labels: ["Agent Call Cancels", "Email Cancels", "Return Cancels", "Alerts", "Other"],
                colors: [
                    "rgb(116, 90, 242)",
                    "rgb(30, 136, 229)",
                    "#06d79c",
                    "#ef5350",
                    "#c1c1c1",
                ],
            }

        },
        CancelsByAgentTypeGraphSeries: (state, getters) => {
            return Object.entries(getters.getCancelsByAgentType).map(item => item[1].count)
        },

        RefundsByAgentTypeGraphOptions: (state, getters) => {
            return {
                chart: {
                    fontFamily: "Montserrat,sans-serif",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                    events: {},
                },
                states: {

                    active: {
                        filter: {
                            type: 'darken',
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            size: "70px",
                        },
                    },
                },
                tooltip: {
                    fillSeriesColor: false,
                    y: {
                        formatter: function (value, object) {
                            return `Count: ${value} ($${getters.getRefundsByAgentType[["Agent Call Refunds", "Email Refunds", "Return Refunds", "Alerts", "Other"][object.seriesIndex]].$.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })})`;
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                legend: {
                    show: false,
                },
                labels: ["Agent Call Refunds", "Email Refunds", "Return Refunds", "Alerts", "Other"],
                colors: [
                    "rgb(116, 90, 242)",
                    "rgb(30, 136, 229)",
                    "#06d79c",
                    "#ef5350",
                    "#c1c1c1",
                ],
            }

        },
        RefundsByAgentTypeGraphSeries: (state, getters) => {
            return Object.entries(getters.getRefundsByAgentType).map(item => item[1].count)
        },
    },
};