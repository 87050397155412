<template>
    <v-app id="dashboard" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
        <preloader v-if="loadingState" :nontransparent="nonTransparent">
        </preloader>
        <router-view/>
    </v-app>

</template>

<script>
    import Preloader from "./components/preloader";
    import {mapGetters} from "vuex";
    import {PAGE_NAME_DASHBOARDS, PAGE_NAME_PRODUCTIVITY} from "./constants";

    export default {
        name: 'App',
        methods: {},
        components: {Preloader},
        computed: {
            ...mapGetters(['loadingState']),
            nonTransparent()
            {
                return (this.$route.name != PAGE_NAME_DASHBOARDS && this.$route.name != PAGE_NAME_PRODUCTIVITY)
            }
        }

    };
</script>
