import {listAgentDashboardRecords, listDashboardRecordsForReports} from "../graphql/queries";
import {
    fetchItemsNextTokenGraphQL,
    BuildProductivityFilter,
    toDPIndex,
    emptyProductivityData,
    remapping,
    hierarchy,
    defaultMoneyItems,
    defaultDistributionItems,
    sumToHierarchy
} from "@/utils/query_all_utils";


const moment = require('moment-timezone')
export default {
    state: {
        agents_for_reports_1: [],
        agents_for_reports_2: [],
        reportsData: {},
        moneyItems: {},
        distributionItems: {},
        hierarchyItems: [],
        initial_reports_loaded: false,
        compare_mode: false,
    },
    mutations: {
        UPDATE_AGENTS_FOR_REPORTS_1: (state, agents) => {
            state.agents_for_reports_1 = Object.freeze(agents)
        },
        UPDATE_AGENTS_FOR_REPORTS_2: (state, agents) => {
            state.agents_for_reports_2 = Object.freeze(agents)
        },
        SET_REPORTS_DATA: (state, data) => {
            state.reportsData = Object.freeze(data)
        },
        SET_MONEY_ITEMS: (state, data) => {
            state.moneyItems = Object.freeze(data)
        },
        SET_DISTRIBUTION_ITEMS: (state, data) => {
            state.distributionItems = Object.freeze(data)
        },
        SET_HIERARCHY_ITEMS: (state, data) => {
            state.hierarchyItems = Object.freeze(data)
        },
        SET_INITIAL_REPORTS_LOADED: (state) => {
            state.initial_reports_loaded = true
        },
        RESET_INITIAL_REPORTS_LOADED: (state) => {
            state.initial_reports_loaded = false
        },
        SET_COMPARE_MODE: (state, value) => {
            state.compare_mode = value
        }
    },
    actions: {
        setCompareMode: ({commit}, value) => {
            commit('SET_COMPARE_MODE', value)
        },
        setAgentsForReports1: ({commit}, agents) => {
            commit('UPDATE_AGENTS_FOR_REPORTS_1', agents)
        },
        updateAgentsForReports1: ({commit, dispatch}, agents) => {
            commit('UPDATE_AGENTS_FOR_REPORTS_1', agents)
            dispatch('loadAndInitializeReports')
        },
        updateAgentsForReports2: ({commit, dispatch}, agents) => {
            commit('UPDATE_AGENTS_FOR_REPORTS_2', agents)
            dispatch('loadAndInitializeReports')
        },
        loadAndInitializeReports: ({commit, dispatch, getters, rootGetters}) => {
            commit('RESET_INITIAL_REPORTS_LOADED')
            Promise.all([
                new Promise((resolve, reject) => {
                    var filter = BuildProductivityFilter(getters.getDashboardMainDates, getters.getCurrentUserDisplayFrom, null, null, null, null)
                    fetchItemsNextTokenGraphQL(listAgentDashboardRecords, {
                        ...filter
                    }, 160).then((items) => {
                        resolve(items)
                    }).catch((e) => {
                        dispatch("processError", e)
                        reject(e)
                    })
                }),
                new Promise((resolve, reject) => {
                    if (getters.getReportsCompareMode) {
                        var filter = BuildProductivityFilter(getters.getCompareDashboardDates, getters.getCurrentUserDisplayFrom, null, null, null, null)
                        fetchItemsNextTokenGraphQL(listAgentDashboardRecords, {
                            ...filter
                        }, 160).then((items) => {
                            resolve(items)
                        }).catch((e) => {
                            dispatch("processError", e)
                            reject(e)
                        })
                    } else {
                        resolve()
                    }
                }),
                new Promise((resolve, reject) => {
                    var filter = BuildProductivityFilter(getters.getDashboardMainDates, getters.getCurrentUserDisplayFrom, null, null, null, {
                        start: getters.getStartTime,
                        end: getters.getEndTime
                    })
                    fetchItemsNextTokenGraphQL(listDashboardRecordsForReports, {
                        ...filter
                    }, 2000).then((items) => {
                        resolve(items)
                    }).catch((e) => {
                        dispatch("processError", e)
                        reject(e)
                    })
                }),
                new Promise((resolve, reject) => {
                    if (getters.getReportsCompareMode) {
                        var filter = BuildProductivityFilter(getters.getCompareDashboardDates, getters.getCurrentUserDisplayFrom, null, null, null, null)
                        fetchItemsNextTokenGraphQL(listDashboardRecordsForReports, {
                            ...filter
                        }, 2000).then((items) => {
                            resolve(items)
                        }).catch((e) => {
                            dispatch("processError", e)
                            reject(e)
                        })
                    } else {
                        resolve()
                    }
                })

            ]).then((results) => {
                var moneyItems = Object.assign({}, defaultMoneyItems)
                var distributionItems = Object.assign({}, defaultDistributionItems)
                var hierarchyItems = [...hierarchy]
                var rbc = hierarchyItems.find((item) => Object.keys(item)[0] == 'Revenue By Campaign')
                rbc[Object.keys(rbc)[0]] = []
                rbc = hierarchyItems.find((item) => Object.keys(item)[0] == 'Unknown Agents')
                rbc[Object.keys(rbc)[0]] = []
                var calculatedData = {}
                var lowest_normal_date = Math.floor(toDPIndex(moment(getters.getDashboardMainDates[0]).startOf('day')))
                var highest_normal_date = Math.floor(toDPIndex(moment(getters.getDashboardMainDates[1]).endOf('day')))
                var lowest_compare_date = Math.floor(toDPIndex(moment(getters.getCompareDashboardDates[0]).startOf('day')))
                var highest_compare_date = Math.floor(toDPIndex(moment(getters.getCompareDashboardDates[1]).endOf('day')))
                for (var key of Object.keys(emptyProductivityData)) {
                    calculatedData[key] = {
                        left: 0,
                        right: 0,
                        left_money: 0,
                        left_pay: 0,
                        right_money: 0,
                        right_pay: 0,
                        left_distribution: 0,
                        right_distribution: 0,
                    }
                }
                calculatedData['Revenue By Campaign'] = {
                    left: 0,
                    right: 0,
                    left_money: 0,
                    left_pay: 0,
                    right_money: 0,
                    right_pay: 0,
                    left_distribution: 0,
                    right_distribution: 0,
                    left_call_count: 0,
                    right_call_count: 0,
                    campaign_group: ''
                }
                calculatedData['Unknown Agents'] = {
                    left: 0,
                    right: 0,
                    left_money: 0,
                    left_pay: 0,
                    right_money: 0,
                    right_pay: 0,
                    left_distribution: 0,
                    right_distribution: 0,
                    left_call_count: 0,
                    right_call_count: 0
                }
                var unknown_agent_keys = {}
                var agents_lookup_left = getters.getAgentsForReports1.reduce((accum, item) => {
                    var agent = getters.getAgentByEmail(item.email.toLowerCase())
                    accum[item.email.toLowerCase()] = agent.is_rate_hourly ? rootGetters.dynamicCurrency(agent.payrate, 'CAD') / 60 : rootGetters.dynamicCurrency(agent.payrate, 'CAD')
                    return accum
                }, {})
                var agents_lookup_right = getters.getAgentsForReports2.reduce((accum, item) => {
                    var agent = getters.getAgentByEmail(item.email.toLowerCase())
                    accum[item.email.toLowerCase()] = agent.is_rate_hourly ? rootGetters.dynamicCurrency(agent.payrate, 'CAD') / 60 : rootGetters.dynamicCurrency(agent.payrate, 'CAD')
                    return accum
                }, {})

                var known_agents = getters.getAllAgents.reduce((accum, item) => {
                    accum[item.email.toLowerCase()] = 1
                    return accum
                }, {})

                for (let p1 of results[0]) {
                    let dp_index = p1.dp_index
                    if (dp_index >= lowest_normal_date && dp_index <= highest_normal_date) {
                        var left_records = JSON.parse(p1.agents)
                        for (let key0 of Object.keys(left_records)) {
                            if (agents_lookup_left[key0] !== undefined) {
                                for (let activity of Object.keys(left_records[key0])) {
                                    let value = left_records[key0][activity]
                                    if (value > 3600)
                                        value = 3600
                                    if (activity in remapping) {
                                        for (let to_remap of remapping[activity]) {
                                            calculatedData[to_remap].left += value
                                            if (moneyItems[to_remap]) {
                                                calculatedData[to_remap].left_money += Math.round(value) * agents_lookup_left[key0]
                                            }
                                        }
                                    } else {
                                        calculatedData["Unclassified"].left += value
                                        calculatedData["Unclassified"].left_money = 0
                                    }
                                }
                            }
                        }
                    }
                }
                if (getters.getReportsCompareMode) {
                    for (let p2 of results[1]) {
                        let dp_index = p2.dp_index
                        if (dp_index >= lowest_compare_date && dp_index <= highest_compare_date) {
                            var right_records = JSON.parse(p2.agents)
                            for (let key1 of Object.keys(right_records)) {
                                if (agents_lookup_right[key1] !== undefined) {
                                    for (let activity of Object.keys(right_records[key1])) {
                                        let value = right_records[key1][activity]
                                        if (value > 3600)
                                            value = 3600
                                        if (activity in remapping) {
                                            for (let to_remap of remapping[activity]) {
                                                calculatedData[to_remap].right += value
                                                if (moneyItems[to_remap]) {
                                                    calculatedData[to_remap].right_money += Math.round(value) * agents_lookup_right[key1]
                                                }
                                            }
                                        } else {
                                            calculatedData["Unclassified"].right += value
                                            calculatedData["Unclassified"].right_money = 0
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                calculatedData['Total Login'].left = sumToHierarchy.reduce((accum, item) => {
                    return accum + calculatedData[item].left
                }, 0)
                calculatedData['Total Login'].right = sumToHierarchy.reduce((accum, item) => {
                    return accum + calculatedData[item].right
                }, 0)
                var campaigns_prices = getters.getAllCampaigns.reduce((accum, item) => {
                    if (item.is_rate_hourly) {
                        accum[item.name] = rootGetters.dynamicCurrency(item.rate, 'USD') / 60
                    } else {
                        accum[item.name] = rootGetters.dynamicCurrency(item.rate, 'USD')
                    }
                    return accum
                }, {})
                var campaigns_distribution = getters.getAllCampaigns.reduce((accum, item) => {
                    if (item.is_rate_hourly) {
                        accum[item.name] = rootGetters.dynamicCurrency(item.distribution, 'USD') / 60
                    } else {
                        accum[item.name] = rootGetters.dynamicCurrency(item.distribution, 'USD')
                    }
                    return accum
                }, {})
                let revenue_by_campaign_to_add = hierarchyItems.find((item) => Object.keys(item)[0] == 'Revenue By Campaign')
                let unknown_agents_to_add = hierarchyItems.find((item) => Object.keys(item)[0] == 'Unknown Agents')

                for (let d1 of results[2]) {
                    const agent_email1 = d1.agent.toLowerCase()
                    if (known_agents[agent_email1]) {
                        if (agents_lookup_left[agent_email1] !== undefined) {
                            let campaignGroup = getters.getCampaignGroupNameByCampaignName(d1.campaign);
                            if (calculatedData[d1.campaign] === undefined) {
                                calculatedData[d1.campaign] = {
                                    left: 0,
                                    right: 0,
                                    left_money: 0,
                                    left_pay: 0,
                                    right_money: 0,
                                    right_pay: 0,
                                    left_distribution: 0,
                                    right_distribution: 0,
                                    left_call_count: 0,
                                    right_call_count: 0,
                                    isGroup: false,
                                    campaignGroup: campaignGroup
                                }
                                moneyItems[d1.campaign] = 1;
                                distributionItems[d1.campaign] = 1;
                                revenue_by_campaign_to_add[Object.keys(revenue_by_campaign_to_add)[0]].push(d1.campaign)

                            }
                            if (calculatedData['_' + campaignGroup] === undefined) {
                                calculatedData['_' + campaignGroup] = {
                                    left: 0,
                                    right: 0,
                                    left_money: 0,
                                    left_pay: 0,
                                    right_money: 0,
                                    right_pay: 0,
                                    left_distribution: 0,
                                    right_distribution: 0,
                                    left_call_count: 0,
                                    right_call_count: 0,
                                    isGroup: true,
                                    campaignGroup: campaignGroup
                                }
                                moneyItems['_' + campaignGroup] = 1;
                                distributionItems['_' + campaignGroup] = 1;
                                revenue_by_campaign_to_add[Object.keys(revenue_by_campaign_to_add)[0]].push('_' + campaignGroup)
                            }
                            const price = campaigns_prices[d1.campaign] || 0
                            const agent_pay = agents_lookup_left[d1.agent] || 0
                            const distributions = campaigns_distribution[d1.campaign] || 0
                            calculatedData[d1.campaign].left += d1.agent_time;
                            calculatedData[d1.campaign].left_call_count += d1.CallCount;
                            calculatedData[d1.campaign].left_money += d1.agent_time * price;
                            calculatedData[d1.campaign].left_pay += d1.agent_time * agent_pay;
                            calculatedData[d1.campaign].left_distribution += d1.agent_time * distributions;
                            calculatedData['_' + campaignGroup].left += d1.agent_time;
                            calculatedData['_' + campaignGroup].left_call_count += d1.CallCount;
                            calculatedData['_' + campaignGroup].left_money += d1.agent_time * price;
                            calculatedData['_' + campaignGroup].left_pay += d1.agent_time * agent_pay;
                            calculatedData['_' + campaignGroup].left_distribution += d1.agent_time * distributions;
                            calculatedData['Revenue By Campaign'].left += d1.agent_time;
                            calculatedData['Revenue By Campaign'].left_pay += d1.agent_time * agent_pay;
                            calculatedData['Revenue By Campaign'].left_call_count += d1.CallCount;
                            calculatedData['Revenue By Campaign'].left_money += d1.agent_time * price;
                            calculatedData['Revenue By Campaign'].left_distribution += d1.agent_time * distributions;
                        }
                    } else {
                        if (getters.getCurrentUserAgents.length == 0) {
                            if (calculatedData[agent_email1] === undefined) {
                                calculatedData[agent_email1] = {
                                    left: 0,
                                    right: 0,
                                    left_money: 0,
                                    left_pay: 0,
                                    right_money: 0,
                                    right_pay: 0,
                                    left_distribution: 0,
                                    right_distribution: 0,
                                    left_call_count: 0,
                                    right_call_count: 0
                                }
                                unknown_agents_to_add[Object.keys(unknown_agents_to_add)[0]].push(agent_email1)
                            }
                            calculatedData[agent_email1].left += d1.agent_time;
                            calculatedData[agent_email1].left_call_count += d1.CallCount;
                            calculatedData['Unknown Agents'].left += d1.agent_time;
                            calculatedData['Unknown Agents'].left_call_count += d1.CallCount;
                            unknown_agent_keys[agent_email1] = 1
                        }
                    }
                }
                if (getters.getReportsCompareMode) {
                    for (let d2 of results[3]) {
                        const agent_email2 = d2.agent.toLowerCase()
                        if (known_agents[agent_email2]) {
                            if (agents_lookup_right[agent_email2] !== undefined) {
                                let campaignGroup2 = getters.getCampaignGroupNameByCampaignName(d2.campaign);
                                if (calculatedData[d2.campaign] === undefined) {
                                    calculatedData[d2.campaign] = {
                                        left: 0,
                                        right: 0,
                                        left_money: 0,
                                        left_pay: 0,
                                        right_money: 0,
                                        right_pay: 0,
                                        left_distribution: 0,
                                        right_distribution: 0,
                                        left_call_count: 0,
                                        right_call_count: 0,
                                        isGroup: false,
                                        campaignGroup: campaignGroup2
                                    }
                                    moneyItems[d2.campaign] = 1;
                                    distributionItems[d2.campaign] = 1;
                                    revenue_by_campaign_to_add[Object.keys(revenue_by_campaign_to_add)[0]].push(d2.campaign)

                                }

                                if (calculatedData['_' + campaignGroup2] === undefined) {
                                    calculatedData['_' + campaignGroup2] = {
                                        left: 0,
                                        right: 0,
                                        left_money: 0,
                                        left_pay: 0,
                                        right_money: 0,
                                        right_pay: 0,
                                        left_distribution: 0,
                                        right_distribution: 0,
                                        left_call_count: 0,
                                        right_call_count: 0,
                                        isGroup: true,
                                        campaignGroup: campaignGroup2
                                    }
                                    moneyItems['_' + campaignGroup2] = 1;
                                    distributionItems['_' + campaignGroup2] = 1;
                                    revenue_by_campaign_to_add[Object.keys(revenue_by_campaign_to_add)[0]].push('_' + campaignGroup2)
                                }

                                const price = campaigns_prices[d2.campaign] || 0
                                const distributions = campaigns_distribution[d2.campaign] || 0
                                const agent_pay = agents_lookup_right[d2.agent] || 0
                                calculatedData[d2.campaign].right += d2.agent_time;
                                calculatedData[d2.campaign].right_money += d2.agent_time * price;
                                calculatedData[d2.campaign].right_pay += d2.agent_time * agent_pay;
                                calculatedData[d2.campaign].right_distribution += d2.agent_time * distributions;
                                calculatedData[d2.campaign].right_call_count += d2.CallCount;
                                calculatedData['_' + campaignGroup2].right += d2.agent_time;
                                calculatedData['_' + campaignGroup2].right_call_count += d2.CallCount;
                                calculatedData['_' + campaignGroup2].right_money += d2.agent_time * price;
                                calculatedData['_' + campaignGroup2].right_pay += d2.agent_time * agent_pay;
                                calculatedData['_' + campaignGroup2].right_distribution += d2.agent_time * distributions;
                                calculatedData['Revenue By Campaign'].right += d2.agent_time;
                                calculatedData['Revenue By Campaign'].right_pay += d2.agent_time * agent_pay;
                                calculatedData['Revenue By Campaign'].right_money += d2.agent_time * price;
                                calculatedData['Revenue By Campaign'].right_distribution += d2.agent_time * distributions;
                                calculatedData['Revenue By Campaign'].right_call_count += d2.CallCount;
                            }
                        } else {
                            if (getters.getCurrentUserAgents.length == 0) {
                                if (calculatedData[agent_email2] === undefined) {
                                    calculatedData[agent_email2] = {
                                        left: 0,
                                        right: 0,
                                        left_money: 0,
                                        left_pay: 0,
                                        right_money: 0,
                                        right_pay: 0,
                                        left_distribution: 0,
                                        right_distribution: 0,
                                        left_call_count: 0,
                                        right_call_count: 0
                                    }
                                    unknown_agents_to_add[Object.keys(unknown_agents_to_add)[0]].push(agent_email2)
                                }
                                calculatedData[agent_email2].right += d2.agent_time;
                                calculatedData[agent_email2].right_call_count += d2.CallCount;
                                calculatedData['Unknown Agents'].right += d2.agent_time;
                                calculatedData['Unknown Agents'].right_call_count += d2.CallCount;
                                unknown_agent_keys[agent_email2] = 1
                            }
                        }
                    }
                }

                var all_keys = {}
                for (let key4 of Object.keys(emptyProductivityData)) {
                    all_keys[key4] = 1
                }
                for (let key5 of Object.keys(moneyItems)) {
                    all_keys[key5] = 1
                }
                for (let key6 of Object.keys(unknown_agent_keys)) {
                    all_keys[key6] = 1
                }
                all_keys['Unknown Agents'] = 1
                for (let key7 of Object.keys(all_keys)) {
                    calculatedData[key7].left = Math.round(calculatedData[key7].left / 60)
                    calculatedData[key7].right = Math.round(calculatedData[key7].right / 60)
                }
                for (let key8 of Object.keys(calculatedData)) {
                    calculatedData[key8].left_money = Math.round(calculatedData[key8].left_money / 60)
                    calculatedData[key8].right_money = Math.round(calculatedData[key8].right_money / 60)
                }
                for (let key9 of Object.keys(distributionItems)) {
                    calculatedData[key9].left_distribution = Math.round(calculatedData[key9].left_distribution / 60)
                    calculatedData[key9].right_distribution = Math.round(calculatedData[key9].right_distribution / 60)
                }
                for (let key10 of Object.keys(distributionItems)) {
                    calculatedData[key10].left_pay = Math.round(calculatedData[key10].left_pay / 60)
                    calculatedData[key10].right_pay = Math.round(calculatedData[key10].right_pay / 60)
                }
                commit('SET_REPORTS_DATA', calculatedData)
                commit('SET_MONEY_ITEMS', moneyItems)
                commit('SET_DISTRIBUTION_ITEMS', distributionItems)
                commit('SET_HIERARCHY_ITEMS', hierarchyItems)
                commit('SET_INITIAL_REPORTS_LOADED')

            })
        },
        setInitialFirstAgents: ({commit, getters}) => {
            commit('UPDATE_AGENTS_FOR_REPORTS_1', getters.getAllActiveAgents)
        },
        setInitialSecondAgents: ({commit, getters}) => {
            commit('UPDATE_AGENTS_FOR_REPORTS_2', getters.getAllActiveAgents)
        }
    },
    getters: {
        getAgentsForReports1: state => state.agents_for_reports_1,
        getAgentsForReports2: state => state.agents_for_reports_2,
        getReportsData: state => state.reportsData,
        hierarchyChildSortItems: (state, getters) => function (item, use_right = false) {
            let name = Object.keys(item)[0]
            let arr = item[name]
            if (name === 'Revenue By Campaign') {
                let groups = {}

                arr.every((item) => {
                    let itemDetails = getters.getReportsData[item]
                    if (itemDetails.isGroup == true) {
                        let itemname = item.substring(1)
                        groups[itemname] = itemDetails
                        groups[itemname].name = item
                        groups[itemname].childs = []
                    }
                    return true
                })

                arr.every((item) => {
                    let itemDetails = getters.getReportsData[item]
                    if (itemDetails.isGroup == false) {
                        groups[itemDetails.campaignGroup].childs.push({...itemDetails, name: item})
                    }
                    return true
                })

                let sortedGroups = Object.values(groups).sort((a, b) => {
                    if (use_right)
                        return b.right_money - a.left_money;
                    return b.left_money - a.left_money
                })

                sortedGroups.every((item) => {
                    item.sortedChilds = item.childs.sort((a, b) => {
                        if (use_right)
                            return b.right_money - a.left_money;
                        return b.left_money - a.left_money
                    })
                    return true
                })

                let final = []
                sortedGroups.every((item) => {
                    final.push(item.name)
                    item.sortedChilds.every((subitem) => {
                        final.push(subitem.name)
                        return true
                    })
                    return true
                })
                return final
            } else if (name === 'Unproductive') {
                return arr.sort((a, b) => {
                    if (use_right)
                        getters.getReportsData[b].right - getters.getReportsData[a].right
                    return getters.getReportsData[b].left - getters.getReportsData[a].left
                });
            }
            else{
                return arr.sort((a, b) => {
                    if (use_right)
                        getters.getReportsData[b].right_money - getters.getReportsData[a].right_money
                    return getters.getReportsData[b].left_money - getters.getReportsData[a].left_money
                });
            }
        },
        getReportsHierarchy: (state) => state.hierarchyItems,
        getMoneyItems: (state) => state.moneyItems,
        getDistributionItems: (state) => state.distributionItems,
        getInitialReportsLoaded: (state) => state.initial_reports_loaded,
        getReportsCompareMode: (state) => state.compare_mode
    },
};
