
import {listHolidays} from "../graphql/queries";
import {createHoliday, updateHoliday, deleteHoliday} from "../graphql/mutations";
import {
    fetchItemsNextTokenGraphQL,
    executeGraphQLMutationCreate,
    executeGraphQLMutationDelete,
    executeGraphQLMutationUpdate
} from "@/utils/query_all_utils";

export default {
    state: {
        holidays: [],
        holidays_by_date: [],
    },
    mutations: {
        SET_HOLIDAYS: (state, items) => {
            state.holidays = Object.freeze(items)
            state.holidays_by_date = items.reduce((accum, item) => {
                accum[item.Holiday_date] = 1
                return accum
            }, {})
        },
    },
    actions: {
        setHolidays: ({commit}, items) => {
            commit('SET_HOLIDAYS', items)
        },
       initializeHolidays: ({dispatch}) => {
            return new Promise((resolve, reject) => {
                fetchItemsNextTokenGraphQL(listHolidays).then((items) => {
                    dispatch('setHolidays', items)
                    resolve()
                }).catch((e) => {
                    dispatch('processError', e)
                    reject(e)
                })
            })
        },
        updateHoliday({dispatch, getters}, item) {
            dispatch('startActionAnimation')
            let existing_holiday = getters.getHolidayByID(item.id)
            let update_graphql = !(existing_holiday.name == item.name && existing_holiday.Holiday_date == item.Holiday_date)
            return new Promise((resolve, reject) => {
                if (update_graphql) {
                    let input_holiday = {
                        id: item.id,
                        name: item.name,
                        Holiday_date: item.Holiday_date,
                    }
                    executeGraphQLMutationUpdate(updateHoliday, {updateHolidayInput: input_holiday}).then(() => {
                        dispatch('initializeHolidays').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                } else {
                    dispatch('endActionAnimation')
                    resolve();
                }
            })
        },
      removeHoliday({dispatch}, id) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    executeGraphQLMutationDelete(deleteHoliday,
                        {id: id}
                    ).then(() => {
                        dispatch('initializeHolidays').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
         addHoliday({dispatch}, holiday) {
            dispatch('startActionAnimation')
            return new Promise((resolve, reject) => {
                    let holiday_input = {
                        ...holiday,
                    }
                    executeGraphQLMutationCreate(createHoliday, {createHolidayInput: holiday_input}).then(() => {
                        dispatch('initializeHolidays').then(() => {
                            dispatch('endActionAnimation')
                            resolve()
                        })
                    }).catch((e) => {
                        dispatch('endActionAnimation')
                        dispatch('processError', e)
                        reject(e)
                    })
                }
            )
        },
    },
    getters: {
        getAllHolidays: state => state.holidays,
        getHolidayByID: state => (id) => state.holidays.find((item) => item.id == id),
        getHolidayByDate: state => (date) => state.holidays_by_date[date]
    },
};
