import {API, graphqlOperation} from 'aws-amplify';
import {listKeysToCustomer} from "../graphql/queries";
import {createKeysToCustomer, updateKeysToCustomer, deleteKeysToCustomer} from "../graphql/mutations";
import {fetchItemsNextTokenGraphQL, executeGraphQLMutationDelete} from "@/utils/query_all_utils";

export default {
  state: {
    keys: [],
  },
  mutations: {
    SET_KEYS: (state, items) => {
      state.keys = Object.freeze(items)
      state.keys_by_date = items.reduce((accum, item) => {
        accum[item.Key_date] = 1
        return accum
      }, {})
    },
  },
  actions: {
    setKeys: ({commit}, keys) => {
      keys.forEach(key => {

        if (key.Campaigns) {
          key.Campaigns = key.Campaigns.split(',')
        } else {
          key.Campaigns = []
        }
      })
      commit('SET_KEYS', keys)
    },
    initializeKeys: ({dispatch}) => {
      return new Promise((resolve, reject) => {
        fetchItemsNextTokenGraphQL(listKeysToCustomer).then((items) => {
          dispatch('setKeys', items.map((item) => ({...item, id: item.KeyField})))
          resolve()
        }).catch((e) => {
          dispatch('processError', e)
          reject(e)
        })
      })
    },
    updateKey({dispatch}, item) {
      dispatch('startActionAnimation')
      return new Promise((resolve, reject) => {
        let input_key = {
          CustomerName: item.CustomerName,
          KeyField: item.KeyField,
          Additional1: item.Additional1 ? item.Additional1 : "",
          Additional2: item.Additional2 ? item.Additional2 : "",
          Additional3: item.Additional3 ? item.Additional3 : "",
          Additional4: item.Additional4 ? item.Additional4 : "",
          Additional5: item.Additional5 ? item.Additional5 : "",
          Additional6: item.Additional6 ? item.Additional6 : "",
          Additional7: item.Additional7 ? item.Additional7 : "",
          Additional8: item.Additional8 ? item.Additional8 : "",
          Additional9: item.Additional9 ? item.Additional9 : "",
          Additional10: item.Additional10 ? item.Additional10 : "",
          Additional11: item.Additional11 ? item.Additional11 : "",
          Additional12: item.Additional12 ? item.Additional12 : "",
          Additional13: item.Additional13 ? item.Additional13 : "",
          Additional14: item.Additional14 ? item.Additional14 : "",
          Additional15: item.Additional15 ? item.Additional15 : "",
          Additional16: item.Additional16 ? item.Additional16 : "",
          Additional17: item.Additional17 ? item.Additional17 : "",
          Additional18: item.Additional18 ? item.Additional18 : "",
          Additional19: item.Additional19 ? item.Additional19 : "",
          Additional20: item.Additional20 ? item.Additional20 : "",
          Campaigns: item.Campaigns ? item.Campaigns.join(",") : "",
          OpenURL: item.OpenURL,
        }
        API.graphql(graphqlOperation(updateKeysToCustomer, {updateKeysToCustomerInput: input_key})).then(() => {
          dispatch('initializeKeys').then(() => {
            dispatch('endActionAnimation')
            resolve()
          })
        }).catch((e) => {
          dispatch('endActionAnimation')
          dispatch('processError', e)
          reject(e)
        })
      })
    },
    removeKey({dispatch}, KeyField) {
      dispatch('startActionAnimation')
      return new Promise((resolve, reject) => {
        executeGraphQLMutationDelete(deleteKeysToCustomer,
          {KeyField: KeyField}
        ).then(() => {
          dispatch('initializeKeys').then(() => {
            dispatch('endActionAnimation')
            resolve()
          })
        }).catch((e) => {
          dispatch('endActionAnimation')
          dispatch('processError', e)
          reject(e)
        })
      })
    },
    addKey({dispatch}, key) {
      dispatch('startActionAnimation')
      return new Promise((resolve, reject) => {
        API.graphql(graphqlOperation(createKeysToCustomer, {
          createKeysToCustomerInput:{
            CustomerName: key.CustomerName, 
            KeyField: key.KeyField,
            Additional1: key.Additional1,
            Additional2: key.Additional2,
            Additional3: key.Additional3,
            Additional4: key.Additional4,
            Additional5: key.Additional5,
            Additional6: key.Additional6,
            Additional7: key.Additional7,
            Additional8: key.Additional8,
            Additional9: key.Additional9,
            Additional10: key.Additional10,
            Additional11: key.Additional11,
            Additional12: key.Additional12,
            Additional13: key.Additional13,
            Additional14: key.Additional14,
            Additional15: key.Additional15,
            Additional16: key.Additional16,
            Additional17: key.Additional17,
            Additional18: key.Additional18,
            Additional19: key.Additional19,
            Additional20: key.Additional20,
            Campaigns: key.Campaigns.join(","),
            OpenURL: key.OpenURL,
          }})).then(() => {
          dispatch('initializeKeys').then(() => {
            dispatch('endActionAnimation')
            resolve()
          })
        }).catch((e) => {
          dispatch('endActionAnimation')
          dispatch('processError', e)
          reject(e)
        })
      })
    },
  },
  getters: {
    getAllKeys: state => state.keys,
    getKeyByKeyField: state => (KeyField) => state.keys.find((item) => item.KeyField == KeyField),
    getKeyByDate: state => (date) => state.keys_by_date[date]
  },
};
